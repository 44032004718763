import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";

import { Link, useParams } from "react-router-dom";

import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import Select from 'react-select';
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from "react-redux";
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import errorimages from "../Asset/download1.png"
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { Bar } from "react-chartjs-2";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import { datezonechange } from "./../Commonfun"
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";


export default function AccessibilitySummary() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [jobidValue, setjobValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [selectedValues, setSelectedValues] = useState();
  const [jobselectedValues, setJobselectedValues] = useState([]);
  const [responsedata, SetResponsedata] = useState([]);
  const [columndata, Setcolumndata] = useState([])
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [responsetypedata, SetResponsetypedata] = useState([]);
  const [responsevaldata, SetResponsevaldata] = useState([]);
  const [accessbilitySummaryPlan, setaccessbilitySummaryPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);


  const navigate = useNavigate();

  const ApiToken = esodata.apiauthkey.value
  let result = ApiToken.split(" ");

  let detailurl = `${process.env.REACT_APP_DOMAIN}accessibility-report-detail/`;



  const apihandleClose = () => {
    setapiOpen(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );



  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=56`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  const checkUserPlan = async () => {
    const accessbilitySummaryP = await Utils("accessibility_summary", ApiToken);
    if (accessbilitySummaryP.status) {
      callUserdataApi();
      // LoadAddFormFilterOptions();
      GetFilterOptions();
      faqdatafun()
      const findVirtualScroller = () => {
        const virtualScrollerElement = document.querySelector(
          ".MuiDataGrid-virtualScroller"
        );
        if (!virtualScrollerElement) {
          setTimeout(findVirtualScroller, 100);
        } else {
          virtualScrollerElement.addEventListener(
            "scroll",
            handleScrollHorizontal
          );
          return () => {
            virtualScrollerElement.removeEventListener(
              "scroll",
              handleScrollHorizontal
            );
          };
        }
      };
      findVirtualScroller();
    }
    setaccessbilitySummaryPlan(accessbilitySummaryP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domaindata`;
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  const handleScrollHorizontal = (() => {
    const currentScrollPos = document.querySelector(
      ".MuiDataGrid-virtualScroller"
    ).scrollLeft;
    const columnsHeaders = document.querySelectorAll(
      // ".MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:first-child)"
      ".MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader:nth-child(-n+3))"
    );
    columnsHeaders.forEach((columnHeader) => {
      columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
    });
  })


  const rows = cardData ?? [];
  const callUserdataApi = async (jobidval) => {
    if (typeof jobidval !== 'undefined')
      var testarrval = [jobidval];
    else
      var testarrval = [];

    setLoading(true);
    setCardData([])
    var passpostapi = {
      report_id: "8",
      user_domain_id: esodata.domaindata.value.header_selected,
      column_ids: [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
      job_ids: testarrval,
      type: "accessibility_summary",
    };
    try {
      const responsedatavalue = await axios
        .post(
          `${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`,
          passpostapi,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (
            error?.response?.status == 599 ||
            error?.response?.status == 400 ||
            error?.response?.status == 500
          ) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error);
          } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate("/errorpages");
          }
          return false;
        });
      if (responsedatavalue?.data?.job_date)
        setjobDate(moment(responsedatavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(responsedatavalue?.data?.job_date).format("MMMM DD,YYYY"))

      if (responsedatavalue?.data) {

        SetResponsedata(responsedatavalue?.data);
        setCardData(responsedatavalue?.data?.table_data)
        setLoading(false);
        setIsCardVisible(false)
        var ColumnsMerge = [];
        const columnsIni = [
          {
            field: "id",
            headerName: "#",
            minWidth: 200,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
          },
          {
            field: "type",
            headerName: "Type",
            minWidth: 550,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
          },
        ]

        responsedatavalue?.data?.table_data.forEach(element => {
          ColumnsMerge.push({

            field: element.job__completed_date,
            headerName: "Value",
            minWidth: 400,
            flex: 1,
            disableClickEventBubbling: true,
            headerAlign: "center",
            align: "center",
          })
        })
        ColumnsMerge = columnsIni.concat(ColumnsMerge)

        Setcolumndata(ColumnsMerge)
        var LoadTableValue = [];
        var responserdatatype = [];
        responsedatavalue?.data?.row_names.map((element, i) => {
          responserdatatype.push(element)
          LoadTableValue.push({
            id: i + 1,
            type: element

          })
        })
        SetResponsetypedata(responserdatatype)
        var responservaldatatype = [];
        LoadTableValue.map((elements, i) => {
          responsedatavalue?.data?.table_data.map((element, j) => {

            if (responsedatavalue?.data?.table_data[j] !== undefined) {
              responservaldatatype.push(responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]])
              LoadTableValue[i][element.job__completed_date] = responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]]
            }
            else
              LoadTableValue[i][element.job__completed_date] = "-"
          })
        })
        SetResponsevaldata(responservaldatatype)
        setCardData(LoadTableValue)
      }
    } catch (err) { }


    setLoading(false);
  };

  const reportNow = async () => {

    var errs = 1;
    let errors = {};

    if (dEngineoption.id == "") {
      errors.dateerror = "All Fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }

    setIsOpen(false);
    setprevdEngineoption(dEngineoption);
    setLoading(true);
    setJobselectedValues(dEngineoption.value)
    callUserdataApi(dEngineoption.value)

  };
  function openModal() {
    if (accessbilitySummaryPlan.status) {
      setfaqData(2)
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);


  }

  const [WcagType, SetWcagType] = useState([])


  const GetFilterOptions = async () => {
    setcompletedate([])
    try {
      const FetchList = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=accessibility2`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (FetchList.data) {
        var Selectedmets = [];
        if (FetchList?.data?.jobs.length > 0) {
          FetchList?.data?.jobs.forEach((element) => {
            Selectedmets.push({ value: element?.id, label: datezonechange(element?.completed_at) });
          });
          SetdEngineoption(Selectedmets[0])
          setprevdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets)
        }
      }



    } catch (err) {
      console.log('err', err)
    }

  }

  const OnsetSelectval = (values) => {
    setSelectval(values.value)

  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('accessibility2')

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    console.log("passpostapis", passpostapis)
    try {
      var passpostapis = {
        report_id: "8",
        user_domain_id: esodata.domaindata.value.header_selected,
        column_ids: [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
        job_ids: jobselectedValues,
        type: "accessibility_summary",
        is_format: "pdf",
        timezone: timezoneval()
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${ApiToken}`,
        },
        body: JSON.stringify(passpostapis)

      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("AccessiblitySummary", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      var passpostapis = {
        report_id: "8",
        user_domain_id: esodata.domaindata.value.header_selected,
        column_ids: [69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79],
        job_ids: jobselectedValues,
        type: "accessibility_summary",
        is_format: "csv",
        timezone: timezoneval()
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${ApiToken}`,
        },
        body: JSON.stringify(passpostapis)

      });
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessiblitySummary", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });

  const preprocessData = (data) => {
    var percentage = (Math.min(...data) / Math.max(...data)) * 100;
    if (percentage < 1) {
      return data.map((value) => {
        var valuePercentage = (value / Math.max(...data)) * 100;
        if (valuePercentage > 0 && valuePercentage < 1) {
          var onePercent = (1 / 100) * Math.max(...data);
          return onePercent; // Ensure minimum bar height for small values
        }
        return value;
      });
    } else {
      return data;
    }
  };

  const barChartData = {
    labels: responsetypedata,
    datasets: [
      {
        label: "Type",
        data: preprocessData(responsevaldata),
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: "#FE6020",
      },
    ],
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`} style={{overflow: 'hidden'}}>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Accessibility Summary</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">

        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Summary</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            {accessbilitySummaryPlan.status &&
              <>
                <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
              </>
            }
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Accessibility Summary</h2>
              <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={openModal} />
              </span>
            </div>
            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <button
              title="Run Report"
              className="runreportnew over_all_accessibility runreportnew"
              onClick={() => {
                if (first.statusDomain) {
                  setcheckD(first.statusDomain);
                } else {
                  SetRunReportPopShow(true)
                }
              }}

              disabled={!accessbilitySummaryPlan.status}
            >
              Run Report Now
            </button>
          </div>
        </div>


        {/* <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Accessibility Summary</h2>
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
              <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                <QuestionMarkIcon />

              </button>
              {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
              {accessbilitySummaryPlan.status &&
                <div>
                  <img src={pdficon} alt="pdf icon" onClick={isDownloading ? null : pdfdown} />
                  <img src={csvicon} alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} />
                </div>
              }
            </div>

          </div>
        </div> */}
        <div className="center_tab accessbility_button_tap">
          <Link to="/accessibility-score" ><button type="button" className={`runreportnews`}  >Score</button></Link>
          <Link to="/accessibility-summary" ><button type="button" className={`runreportnews active`}  >Summary</button></Link>
          <Link to="/accessibility-report" ><button type="button" className={`runreportnews`}  >Report</button></Link>
          <Link to="/accessibility-file" ><button type="button" className={`runreportnews `}  >File Accessibility</button></Link>
        </div>

        {accessbilitySummaryPlan.status ?
          <div>
            {/* <div className="cnt_hdr_blw_wrap">
              <button className="runreportnew" onClick={() => { SetRunReportPopShow(true) }}>
                Run Report Now
              </button>
              <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
                Filter By
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" className="zoom" />
                </span>
              </button>

            </div> */}

            <div className="site_regress_chart_info">
              {/* <p>Site Audit Summary by Issues</p> */}
              <div className="site_Chart_info_inner" >
                <Bar
                  type="bar"
                  width={400} // Set the width of the canvas
                  height={350} // Set the fixed height of the canvas
                  data={barChartData}
                  options={{
                    responsive: false, // Disable responsiveness for a fixed height
                    maintainAspectRatio: false,
                    plugins: {
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: (context) => {
                            // Display the original value in the tooltip
                            return `Value: ${responsevaldata[context.dataIndex]}`;
                          },
                        },
                      },
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Accessibility Summary",
                        color: "#0b2033",
                        font: {
                          size: 18,
                          family: "Poppins",
                        },
                      },
                    },
                    scales: {
                      y: {
                        ticks: {
                          suggestedMin: 0,
                          stacked: true,
                          color: "#0b2033",
                          font: {
                            size: 14,
                            family: "Poppins",
                          },
                          beginAtZero: true,
                        },
                      },
                      x: {
                        ticks: {
                          color: "#0b2033",
                          font: {
                            size: 12,
                            family: "Poppins",
                          },
                          beginAtZero: true,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
              <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                <DataGrid

                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                    footer: () => (
                      <Box className="table-footer-container">
                        <Box className="table-footer-pagination">
                          <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                        </Box>
                        <MSelect
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                          size="small"
                          className="table-footer-select"
                        >
                          <MenuItem value={10}>10 per page</MenuItem>
                          <MenuItem value={30}>30 per page</MenuItem>
                          <MenuItem value={50}>50 per page</MenuItem>
                          <MenuItem value={75}>75 per page</MenuItem>
                          <MenuItem value={100}>100 per page</MenuItem>
                        </MSelect>
                      </Box>
                    ),
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                  columns={columndata}
                  getRowHeight={() => 'auto'}
                  autoHeight={autoheightval ? true : false}
                // initialState={{
                //   pinnedColumns: { left: [''] },
                //   pagination: {
                //     paginationModel: {
                //       pageSize: 10,
                //     },
                //   },
                // }}
                // pageSizeOptions={[10, 30, 50, 75, 100]}
                // loading={loading}
                />
              </div>
            </div>
          </div> : <Freeaudit planStatus={accessbilitySummaryPlan} />
        }




        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter"
        >
          <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">

                <div>

                  <div className="" style={{ marginBottom: '10px' }}>
                    <div>
                      <span>Completed Date</span>
                      <Select
                        value={dEngineoption}
                        options={completedate}
                        placeholder="Select..."
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) => {
                          OnsetSelectval(values)
                          SetdEngineoption(values);
                        }}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                    </div>


                  </div>



                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>



                </div>

              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
              Submit
            </button>
            <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog>

        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

        {!openCommonAlerts && <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              No Data Found
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>}
        {!openCommonAlerts && <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              borderRadius: "18px !important",
              borderColor: "#ff601f !important",
              top: "10px !important",
              // width: '100%',
              // boxShadow:"0px 15px 10px 0px #fff !important"
            },
          }}
        >
          <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>}
        <Dialog
          open={checkD}
          // onClose={() => setOpenCommonAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="domain-verify-alert"
        >
          <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please verify your domain to access these features
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
            <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
          </DialogActions>
        </Dialog>
      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </div>
  );
}
