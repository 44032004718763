import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./spellcheckstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import LinkIcon from '@mui/icons-material/Link';
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
import { datezonechange } from "../Commonfun"
import searchEngine from "../Asset/ESO/search-engine.svg";
import spellcheckersvg from "../Asset/esocardicon/spellchecker.svg"

export default function CodeauditDetail() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [spelldata, setSpelldata] = useState("");
  const [tablecardData, setTableCardData] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDownloading, setIsDownloading] = useState(false);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [chainIcons, setChainIcons] = useState(rankedkeywordsicon);


  const navigate = useNavigate();
  let querystring = useParams();
  let spell_audit_id = querystring.detailid;
  let spell_job_id = querystring.jobid;

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios.get(
      // `${process.env.REACT_APP_API_URL}content/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}`, {
      `${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}`, {
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });
    const date_dataval = datavalue?.data;
    setSpelldata(date_dataval)
    // console.log("date_dataval?.data?.job_date",date_dataval?.job_date)
    if (date_dataval?.job_date)
      setjobDate(moment(date_dataval?.job_date).format("MMMM DD,YYYY HH:mm"))
    else
      setjobDate(moment(date_dataval?.job_date).format("MMMM DD,YYYY"))
    const dataval = datavalue?.data?.table_data[0];
    const table_dataval = datavalue?.data?.table_data[0]?.result;
    setCardData(dataval);


    if (table_dataval) {
      const newData = table_dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      console.log("newdata", newData)
      setTableCardData(newData);
      SetAutoheightval(false)
    }
    else {
      SetAutoheightval(true)

    }


    setLoading(false);

  };
  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row'

    },

    // {
    //   field: "misspelled_word",
    //   headerName: "Misspelled Words",
    //   minWidth: 150,
    //   flex: 1,
    //   headerAlign: 'center',
    //   align: 'center',
    //   description: 'Misspelled words are present in the webpage URL.'
    // },
    // {
    //   field: "Ignore",
    //   headerName: "Ignore",
    //   minWidth: 200,
    //   flex: 1,
    //   headerAlign: "center",
    //   sortable: false,
    //   align: "center",
    //   disableClickEventBubbling: false,
    //   renderCell: (tablecardData) => {
    //     return (

    //       <button id={tablecardData.row.misspelled_word} type="button" class="view-report-button" onClick={ignoreFunction} title={`Clicking the “Ignore” button will ignore this word on this page only. To permanently ignore this word please add it on “Settings -> Spell Checker Settings”.`}
    //       style={{justifyContent:"center"}}>Ignore</button>
    //     );
    //   },
    // },
    // {
    //   field: "misspelled_words",
    //   headerName: "Ignore All",
    //   minWidth: 200,
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    // renderCell: (tablecardData) => {
    //   return (
    //     <button
    //       id={tablecardData.row.misspelled_word}
    //       type="button"
    //       className="view-report-button"
    //       onClick={ignoreallFunction}
    //       title={`Clicking the “Ignore All” button will ignore this word on this report only. To permanently ignore this word please add it on “Settings -> Spell Checker Settings” `}  style={{justifyContent:"center"}}
    //     >
    //       Ignore All
    //     </button>
    //   );
    // },
    // },
    {
      field: "original_sentence",
      headerName: "Existing Sentence",
      minWidth: 450,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: false,
      sortable: true,
      description: 'Suggested words for the misspelled words.'
    },
    {
      field: "highlighted_sentence",
      headerName: "Suggested Sentence",
      minWidth: 450,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: false,
      sortable: true,
      description: 'Suggested words for the misspelled words.',
      renderCell: (tablecardData) => {
        const text = tablecardData.row.highlighted_sentence;
        return (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        );
      },
    },
    {
      field: "modification_type",
      headerName: "Modification Type",
      minWidth: 450,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: false,
      sortable: true,
      description: 'Suggested words for the misspelled words.',
      renderCell: (params) => params.value ?? "N/A",
    },


  ];
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const rows = tablecardData ?? [];
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );
  const ignoreFunction = async (e) => {
    setLoading(true);
    let data_word = e.target.id
    const datavalues = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/spell_check_detail_ignore/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}&word=${data_word}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
    if (datavalues?.status === 200) {
      callUserdataApi();
    }
    setLoading(false);
  }
  const ignoreallFunction = async (e) => {
    setLoading(true);
    let ignoreall_data_word = e.target.id;
    const ignore_datavalues = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/spell_check_detail_ignore_all/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}&word=${ignoreall_data_word}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
    if (ignore_datavalues?.status === 200) {
      callUserdataApi();
    }
    setLoading(false);
  }

  function generateRandom() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {

      // const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=pdf&timezone=${timezoneval()}`, {
      const response = await fetch(`${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SpellcheckerDetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
        // return false;
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {
      // const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=csv&timezone=${timezoneval()}`, {
      const response = await fetch(`${process.env.REACT_APP_API_URL}spell_checker/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SpellcheckerDetail", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=12`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'data_table') || (event.target.classList[0] === 'plagiarisam_missing_waring_wrap_databox')
      || (event.target.classList[0] === 'MuiDataGrid-footerContainer') || (event.target.classList[0] === 'ignore_button_active')


    ) {
      setfaqData(2)
      setToggle(false)
    }
  });

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };

  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spell Checker Detail</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Content</Typography>

            <Link to="/spell-checker">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Spell Checker</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Spell Checker Details</Typography>
          </Breadcrumbs>

          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
            <>
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
            </>
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>

        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div >
              <h2 className="heading_font">Detailed Spell Checker Report </h2>

              <a target="_blank" href={cardData?.link__url} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="spanlink" style={{ flexDirection: "column" }}>
                  {cardData?.link__url}
                </span>
              </a>
              <p className="subheader-timedate">as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
          </div>

        </div>
        <div className="common-card-container" style={{ justifyContent: "center" }}>
          <div className={`common-card-wrapper-style cardactivecontainer`}>
            <div className={`common-card-icon-container`}>
              <div className={`common-card-icons`}>
                <img src={spellcheckersvg} className={`common-card-icon-img`} alt="Ranked Keywords" />
              </div>
            </div>
            <div className={`common-card-subtitle cardcontentactivecontainer`}>
              <span className="common-card-title">Number of Suggested Sentences</span>
              <span className="common-card-value">{cardData?.misspelled_words_count}</span>
            </div>
          </div>

        </div>
        <div className="data_table spell_checker_table " style={{ width: "100%" }}>
          <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
            <DataGrid

              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
                noResultsOverlay: MyCustomNoRowsOverlay,
                footer: () => (
                  <Box className="table-footer-container">
                    <Box className="table-footer-pagination">
                      <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                    </Box>
                    <MSelect
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                      size="small"
                      className="table-footer-select"
                    >
                      <MenuItem value={10}>10 per page</MenuItem>
                      <MenuItem value={30}>30 per page</MenuItem>
                      <MenuItem value={50}>50 per page</MenuItem>
                      <MenuItem value={75}>75 per page</MenuItem>
                      <MenuItem value={100}>100 per page</MenuItem>
                    </MSelect>
                  </Box>
                ),
              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
              columns={columns}
              getRowId={(row) => row.sid}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval ? true : false}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
    </div>
  );
}
