import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import "./plagiarisamstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Breadcrumbs,Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import searchEngine from "../Asset/ESO/search-engine.svg";
import plagiarismscanreport_total_wordson_pagesvg from "../Asset/esocardicon/plagiarism/plagiarismscanreport_total_wordson_page.svg"
import plagiarism_totalmatching_urlsvg from "../Asset/esocardicon/plagiarism/plagiarism_scan_report _total_matching_word.svg"
import plagiarismreport_percentage_matchingsvg from "../Asset/esocardicon/plagiarism/plagiarismreport_percentage_matching.svg"
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
export default function Plagiarisamscandetail() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [initialDateval, SetInitialDateval] = useState([]); 
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
    const [isDownloading, setIsDownloading] = useState(false);
    const [chainIcons, setChainIcons] = useState(rankedkeywordsicon);
  const navigate = useNavigate();
  
  let querystring = useParams();
  let plag_id = querystring.detailid;
  let scan_id = querystring.id;
  const MyCustomNoRowsOverlay = () => (
    <img src="/no-items-found.jpg" alt="no-item" />
  );
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-scan-detail/`;

  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/plagiarism_scan_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}&scan_id=${scan_id}`,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    const dataval = datavalue?.data;


    setCardData(dataval);
    if(datavalue?.data?.job_date) 
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))    
      else
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY")) 
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "#090303";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const [showIframe,SetshowIframe]=useState(false)
  const LoadIframe=(e)=>{
    SetshowIframe(false)
    var Link=e.target.getAttribute('data-link')
    if(Link!==undefined && Link!==null && Link!=='')
    {
      SetshowIframe(true)
    }
    else{
      SetshowIframe(false)
    }



  }
  const pdfdown = async () =>{ 

    setLoading(true);
    setIsDownloading(true);

    try{
   
    const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_scan_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}&scan_id=${scan_id}&formats=pdf&timezone=${timezoneval()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("Plagiarism-scan-detail","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                // Trigger a click on the anchor element to start the download
                link.click();
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setIsDownloading(false);

        setLoading(false);

  }
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=13`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
       
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck')
     ||(event.target.classList[0] === 'plagiarisam_missing_waring_wrap') ||(event.target.classList[0] === 'box_background_wrap') 
     ||(event.target.classList[0] === 'plagiarisam_scan_missing_waring_wrap') ||(event.target.classList[0] === 'plagiarisam_word_Wrap')
     ||(event.target.classList[0] === 'plagiarisam_box_wrap')||(event.target.classList[0] === 'plagiarisam_scan_missing_waring_wrap')


    ) {
      setfaqData(2)
      setToggle(false)   
    }
});

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
         <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Plagiarism Scan Detail</title>
            </Helmet>
    <div className="cnt_audit_outer_wrap plagiarisam-scan-detail">
    <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Content</Typography>
            <Link to="/plagiarism-checker">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Plagarism Checker</Typography>
            </Link>
            <Link to="/plagiarism-checker">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Plagiarism Checker Detail</Typography>
           </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Plagiarism Scan Report</Typography>

          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
              <>
                {/* <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button> */}
                <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
              </>
   
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}

        </div>
      <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
                                <div >
                                <h2 className="heading_font">Plagiarism Checker Scan Report</h2> 
                              
                               
                                </div>
                                {/* <p>as of {jobDate}</p> */}
                            <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
                              
                              </div>
      
     
      </div>

      <div className="cnt_hdr_contents">
        <div className="plagiarisam_box_wrap">
          <div className="box_background_wrap">
            <h3>Scan Report of URL -<a href={cardData?.scan_report?.url}> {cardData?.scan_report?.url}</a></h3>
          </div>
          <div className="common-card-container" style={{justifyContent: "center"}}>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarismscanreport_total_wordson_pagesvg} className={`common-card-icon-img`} alt=" Total Words on Pages" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">  Total Words on Pages</span>
                      <span className="common-card-value">      {cardData?.scan_report?.urlwords}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarism_totalmatching_urlsvg} className={`common-card-icon-img`} alt="  Total Words" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">     Total Matching Words</span>
                      <span className="common-card-value">   {cardData?.scan_report?.wordsmatched}</span>
                    </div>
                  </div>
            
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarismreport_percentage_matchingsvg} className={`common-card-icon-img`} alt="  Total Words" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">  Perentage Matching</span>
                      <span className="common-card-value">        <LiquidFillGauge
                    style={{ margin: "0 auto" }}
                    width={70}
                    height={70}
                    value={cardData?.scan_report?.percentmatched}
                    percent="%"
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: "#FFFFFF",
                    }}
                    waveStyle={{
                      fill: "#FFFFFF",
                    }}
                    textStyle={{
                      fill: color("#0b2033").toString(),
                      fontFamily: "Arial",
                    }}
                    waveTextStyle={{
                      fill: color("#0b2033").toString(),
                      fontFamily: "Arial",
                    }}
                  /></span>
                    </div>
                  </div>
                </div>
          <div className="cnt_hdr_content_data_wrapdata">

            <div className="cnt_hdr_content_data">
              <p>
                Click to try viewing the page directly:{" "}
                <span 
  data-link={cardData?.scan_report?.url} 
  onClick={LoadIframe} 
  style={{ cursor: 'pointer', textDecoration: 'none' }} 
  className="content_span"
>
  <a 
    href={cardData?.scan_report?.url} 
    style={{ color: "#ff1f1f", textDecoration: "none" }}
  >
    {cardData?.scan_report?.url}
  </a>
</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="plagiarism_scan_ifram_row">

        {
          showIframe===true ?  <iframe
          width="1200"
          height="1000"
          src={cardData?.scan_report?.url}
        ></iframe> : null
        }

     

      </div>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
    </div>
  );
}
