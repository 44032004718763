import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import NewLinks from "./NewLinks";
import LostLinks from "./LostLinks";

import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import CommonAlert from "../../../../Commonalert/commonalert";


export default function Linklists(props) {


  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const [buttnval, setButtnval] = useState("1")
  const [menubutval, setMenubutval] = useState("1")
  const [pagename, setPagename] = useState("Site Audit")
  const ApiToken = esodata.apiauthkey.value
  const [validation, setValidation] = useState({
    dateerror: "",
  });



  const menuChange = e => {
    props.SetnewButtonlink(e.target.id)
    props.setSelectval('')
    e.preventDefault();
    setMenubutval(e.target.id)
    setButtnval(e.target.id)
    if (e.target.id == 1)
      setPagename("Site Audit")
    else if (e.target.id == 2)
      setPagename("On Page Crawl")
  };



  const renderSwitch = (params) => {
    switch (params) {
      case "1":
        return <NewLinks RankJob={props.RankJob} FilterButtonTriggered={props.FilterButtonTriggered} SetFilterButtonTriggered={props.SetFilterButtonTriggered} jobDate={props.jobDate} setjobDate={props.setjobDate} closeModal={props.closeModal} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setSelectval={props.setSelectval} />
      case "2":
        return <LostLinks RankJob={props.RankJob} FilterButtonTriggered={props.FilterButtonTriggered} SetFilterButtonTriggered={props.SetFilterButtonTriggered} jobDate={props.jobDate} setjobDate={props.setjobDate} closeModal={props.closeModal} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setSelectval={props.setSelectval} />
    }
  }
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks New/Lost Links</title>
      </Helmet>
      <CommonAlert />

      <div className="cnt_audit_outer_wrap">


        <div className="link_cnt_hdr_blw_wrap googleanlytics">
          <button type="button" id={1} className={`runreportnews ${buttnval === "1" ? 'active' : ""}`} onClick={e => menuChange(e)} >New Link</button>
          <button type="button" id={2} className={`runreportnews ${buttnval === "2" ? 'active' : ""}`} onClick={e => menuChange(e)} > Lost Link</button>
        </div>

        {renderSwitch(menubutval)}





      </div>
    </div>
  );
}
