import React, { useState, useRef, useEffect } from "react";
import "../login/login.css";
import Logo from "../NavBar/Asset/New_Assert/ESO_Logo.png";
import Eso_cnfrm_mail_img from "../login/Asset/On_Board/confirmMail.gif";
import OtpLock from "../../Icons/otp_lock.gif";
import axios from "axios";
import Loader from "../NavBar/CommonLoader";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { SetUserToken } from "../../Store/UserTokenSlice";
import { SetAuthToken } from "../../Store/ApiAuthKey";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function CheckMail() {

    const urlParams = new URLSearchParams(window.location.search);
const verification = urlParams.get('verification');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = localStorage.getItem("verify_register_token");

  const [OtpValue, SetOtpValue] = useState("");
  const [OtpValueErr, SetOtpValueErr] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const VerifyOtpTrigger = async (e) => {
    e.preventDefault();
    SetOtpValueErr("");

    if (OtpValue === "") {
      SetOtpValueErr("Please enter the OTP");
    } else {

        setLoading(true)
      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}accounts/verify_user_email_verify_otp/`,
          {
            otp: OtpValue,
          },
          {
            headers: {
              Authorization: `Token ${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });

        if (Response?.data) {
          localStorage.setItem("verify_register_token", "");

          dispatch(SetUserToken({ eso_user_token: ApiToken }));
          dispatch(SetAuthToken({ eso_auth_token: "Token " + ApiToken }));
          // navigate("/");
          window.location.href=`${process.env.REACT_APP_DOMAIN}Userdata`
        }

    
      } catch (err) {

              setLoading(false)

        if(err?.response?.data?.error)
        {
          setapiOpen(true);
          setapierrorMsg(err?.response?.data?.error)
            SetOtpValueErr(err.response.data.error)
        }
        if(err?.response?.data?.detail)
        {
          setapiOpen(true);
          setapierrorMsg(err?.response?.data?.detail)
            SetOtpValueErr(err.response.data.detail)
        }
        console.log(err);
      }

      setLoading(false)
    }
  };



  const [LoadSuccess, SetLoadSuccess] = useState(false);

  useEffect(() => {
    SetLoadSuccess(true);

    if (LoadSuccess === true)
    {

        var Msg=''
        if(verification==='login')
        {
            Msg='Please verify with the OTP sent to your email address';
        }
        else if (verification==='register')
        {
            Msg='You have successfully registered! Please verify OTP';
        }
          toast.success(Msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

    }
  
 
  }, [LoadSuccess]);

  return (
    <>

    {
        ApiToken!=='' ?   <div>
        {loading === true ? <Loader /> : null}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
  <div className="outer-container"
    style={{
      width: "calc(100% - 60px)",
      marginRight: "60px",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
        <div className="rest_scs_outer_container confirm_sec_outer_container ">
          <div className="rest_scs_ins_container">
            <div className="Eso_cnfrm_mail_outer_wrap">
              <img className="Eso_logo" src={Logo} alt="logo" />
              <img
                className="Eso_rst_img OtpLockNaimationGif"
                src={OtpLock}
                alt="animation gif"
              />
            </div>
            <h1 className="scs_tt">Verify OTP</h1>
            <div className="rest_inst_wrap">
              <p>
                Please enter the OTP you have received in your email address
              </p>
              <form
                onSubmit={VerifyOtpTrigger}
                className="frm_container"
                method="post"
              >
                <div
                  class="form-group email_grp"
                  // style={{ marginBottom: "30px" }}
                >
                  <input
                    type={"text"}
                    onChange={(e) => {
                      SetOtpValue(e.target.value);
                    }}
                    id="otppas"
                    name="newpass"
                    placeholder="Enter your OTP here"
                  />
                  <div className="frm_error">{OtpValueErr}</div>
                </div>

                <button type="submit" className="sbt_btn">
                  Verify Email Address
                </button>
              </form>
            </div>
            <div className="cnfrm">
              <p>
                Haven't received an email? Please check your spam folder or
                contact us at{" "}
                <a href="mailto:contact@elitesiteoptimizer.com">
                  contact@elitesiteoptimizer.com
                </a>
              </p>
              {/* <p><a href="/login">Click here to login</a></p> */}
            </div>
          </div>
        </div>
        </div>
      </div> : null
    }
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
export default CheckMail;
