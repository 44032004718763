import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./codeauditstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import CommonAlert from "../../../Commonalert/commonalert";
import ErrorCountimg from "../Asset/esocardicon/SourceCodeAnalysis/ErrorCount.svg"
import FatalErrorCountimg from "../Asset/esocardicon/SourceCodeAnalysis/FatalErrorCount.svg"
import WarningCountimg from "../Asset/esocardicon/SourceCodeAnalysis/WarningCount.svg"
import { AppContext } from "../../../Context/Context";
export default function CodeauditDetail() {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [jobidData, setjobidData] = useState("");
  const [domainsecid, setDomainsecid] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  let querystring = useParams();
  let code_audit_id = querystring.id;
  let job_audit_id = querystring.jobid;

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const navigate = useNavigate();
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  useEffect(() => {
    callUserdataApi();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}technical/v2/sourcecode_detail_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${job_audit_id}&id=${code_audit_id}`, {

      // `${process.env.REACT_APP_API_URL}technical/sourcecode_detailtable/${code_audit_id}/`,{
      headers: {
        Authorization: `${ApiToken}`,
      },
    }
    ).catch((error) => {
      setLoading(false);
      if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });
    const dataval = datavalue?.data;
    // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
    if (datavalue?.data?.job_date)
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
    else
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
    setCardData(dataval);
  };
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/sourcecode_detail_table/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${code_audit_id}&job_id=${job_audit_id}&formats=pdf&domain_section_id=${domainsecid}&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("SourceCodeDetails", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(true);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/sourcecode_detail_table/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${code_audit_id}&job_id=${job_audit_id}&formats=csv&domain_section_id=${domainsecid}&timezone=${timezoneval()}`, {

        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SourceCodeDetails", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(true);
  }
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap src-detail">

        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Technical</Typography>
            <Link to="/source-code">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Source Code Analysis</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Source Code Detailed Report</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <>
              {/* <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button> */}
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
            </>
          </div>

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <div style={{ display: "flex" }}>
              <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Source Code Detailed Report For</h2>
            </div>
            <a target="_blank" href={cardData?.url} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                  &nbsp; {cardData?.url} 
                  </span>
                </a>
                    <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
        </div>
        <div className="cnt_hdr_content">
          <div className="box_wrap">
            <div>
              <h1> </h1>
              <p></p>
            </div>
            <div className="common-card-container" style={{justifyContent: "center"}}>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={FatalErrorCountimg} className={`common-card-icon-img`} alt="Fatal Error Count" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">Fatal Error Count</span>
                      <span className="common-card-value">{cardData?.fatal_error}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={ErrorCountimg} className={`common-card-icon-img`} alt="Error Count" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">Error Count</span>
                      <span className="common-card-value">{cardData?.error}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={WarningCountimg} className={`common-card-icon-img`} alt="Warning Count" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">Warning Count</span>
                      <span className="common-card-value">{cardData?.warning}</span>
                    </div>
                  </div>

                </div>
           
          </div>
        </div>

        <div className="cnt_hdr_content_table">
          <div className="box_wrap">
            <div
              className="post__content"
              dangerouslySetInnerHTML={{ __html: cardData?.html_data }}
            ></div>
          </div>
        </div>
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
    </div>
  );
}
