import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";

import isURL from 'validator/lib/isURL';
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import DomainAddForm from "./DomainAddForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Breadcrumbs, Typography } from "@mui/material";


export default function UserDomainsecList() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [competitorSettingsPlan, setcompetitorSettingsPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [pagename, setPagename] = useState("Competitor Management")
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [formOpen, setformOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [CamPopTitle, SetCamPopTitle] = useState(null);
  const [onPageError, SetonPageError] = useState("")
  const [showResults, setShowResults] = useState(0)
  const [webauthenticate, Setwebauthenticate] = useState(false)
  const [onUsername, SetonUsername] = useState("")
  const [onPassword, SetonPassword] = useState("")
  const [usernameError, setusernameError] = useState("")
  const [passwordError, setpasswordError] = useState("")
  const [Userformdataid, SetUserformdataid] = useState("")
  const [apisuccessopen, Setapisuccessopen] = useState(false)
  const [apisuccessMsg, setapisuccessMsg] = useState();

  const navigate = useNavigate();
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
    Setapisuccessopen(false)

  };
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = (e) => {
    SetDeleteId(e.target.id)
    setIsOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }
  const usernameVal = e => {
    SetonUsername(e.target.value)
  }
  const passwordval = e => {
    SetonPassword(e.target.value)
  }

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;

  const checkUserPlan = async () => {
    const competitorSettingsP = await Utils("competitor_settings", ApiToken);
    if (competitorSettingsP.status) {
      callUserdataApi();
      faqdatafun()
    }
    setcompetitorSettingsPlan(competitorSettingsP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_competitor_domains/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.table?.competitors) {
        if (datavalue?.data?.table) {
          const newData = datavalue.data.table.competitors.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });

          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }

      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }


    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    SetUserformdataid("")
    SetWebnameError("")
    SetweblinkError("")
    setusernameError("")
    setpasswordError("")
    Setwebauthenticate(0);
    setShowResults(0)
    SetCamPopTitle('Add Competitor Details')
    setformOpen(true)
    SetWebsiteName("");
    SetwebLink("");
    SetonUsername("");
    SetonPassword("");
  }
  const finalreportclose = () => {
    setformOpen(false)
  }
  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {
    // alert(deleteId)
    DeleteCampaignSettings(deleteId);
  };

  const DeleteCampaignSettings = async (id) => {
    console.log("dummay", id)
    setLoading(true);
    setCardData([])
    setIsOpen(false);
    try {
      // var delId=e.target.id
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/delete_competitor/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_id=${id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
    } catch (error) {
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error)
    }
    setLoading(false);
    callUserdataApi();
  };

  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }
  const handleeditClick = async (id) => {
    SetWebnameError("")
    SetweblinkError("")
    setusernameError("")
    setpasswordError("")
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_competitor/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_id=${id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        SetWebsiteName(datavalue?.data?.name)
        SetwebLink(datavalue?.data?.url)
        SetCamPopTitle('Edit Competitor Details')
        Setwebauthenticate(datavalue?.data?.has_http_auth == true ? 1 : 0)
        setShowResults(datavalue?.data?.has_http_auth == true ? 1 : 0)
        SetonUsername(datavalue?.data?.http_username)
        SetonPassword(datavalue?.data?.http_password)
        setformOpen(true)
        SetUserformdataid(datavalue?.data?.id)
      }
    } catch (error) {
      if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    }
  }
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "competitor_name",
      headerName: "Competitor Name",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Website names that have been added for the domain',
    },
    {
      field: "competitor_url",
      headerName: "Competitor URL",
      minWidth: 500,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      description: 'Website Url that have been added for the domain',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        // let firstEightLetters = cardData.row.competitor_url.substring(0, 20);
        // let lastThreeLetters = cardData.row.competitor_url.slice(-5); 

        return (
          <>
            <Tooltip title={cardData?.row?.competitor_url}>
              <div className="prefered_url_external spanlink">
                <a className="table_links" target="_blank" href={cardData.row.competitor_url}>

                  <LinkIcon style={{ fill: "#044792" }} />
                  <span className="spanlink">
                    {cardData?.row?.competitor_url}</span>
                </a>
              </div>

            </Tooltip>
          </>
        );

      },

    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        return (
          // <Link to={"/edit-competitordomain-list/" + cardData.row.id}><button
          //   className="buttonView edit_icon">
          //   <EditIcon />
          // </button></Link>
          <button
            className="buttonView trash_icon"
            //onClick={handleClickOpen}
            onClick={() => handleeditClick(cardData.row.id)}

            id={cardData.row.id}
          >
            <EditIcon />
          </button>

        );
      },

    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <button
            className="buttonView trash_icon"
            //onClick={handleClickOpen}
            onClick={() => handleDeleteClick(cardData.row.id)}

            id={cardData.row.id}
          >
            <DeleteIcon />
          </button>
        );
      },

    },
  ];
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };

  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value == 1 ? setShowResults(1) : setShowResults(0)
    e.target.value == 0 && SetonUsername(""); SetonPassword("");
  }
  const [CallCampaignTable, SetCallCampaignTable] = useState(false)
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer')) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=47`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };
  const finalreportNow = async () => {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    setusernameError("")
    setpasswordError("")
    setLoading(true);
    var data_val = 1;
    if (websiteName == "") {
      SetWebnameError("Please Enter the Competitor Name")
      data_val = 2;
    }

    if (webLink == "") {
      SetweblinkError("Please Enter The Competitor URL Address")
      data_val = 2;
    }
    if (webLink != "") {
      if (!isURL(webLink)) {
        SetweblinkError("Enter Valid Link Address")
        data_val = 2;
      }
    }

    if (webauthenticate == 1) {
      if (onUsername == "") {
        setusernameError("Please Enter User Name")
        data_val = 2;
      }
      if (onPassword == "") {
        setpasswordError("Please Enter Password")
        data_val = 2;
      }
    }
    else {
      setusernameError("")
      setpasswordError("")
      SetonUsername("")
      SetonPassword("")
    }
    if (data_val === 2) {
      setLoading(false);

      return false;
    }
    else {
      try {

        if (Userformdataid == "")
          var domainurl = `${process.env.REACT_APP_API_URL}domain/add_competitor/`
        else
          var domainurl = `${process.env.REACT_APP_API_URL}domain/edit_competitor/`
        setLoading(true);
        var user_domain_id = esodata.domaindata.value.header_selected;
        const response = await axios.post(
          domainurl,
          {
            competitor_id: Userformdataid,
            user_domain_id: user_domain_id,
            competitor_name: websiteName,
            competitor_url: webLink,
            has_http_auth: webauthenticate == 1 ? true : false,
            http_username: onUsername,
            http_password: onPassword,
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            finalreportclose();
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        if (response?.data?.status == "Competitor added successfully" || response?.data?.status == "Domain Competitor updated successfully") {
          finalreportclose();
          // setapiOpen(true);
          // setapierrorMsg(response?.data?.status)

          Setapisuccessopen(true)
          setapisuccessMsg(response?.data?.status)
          setformOpen(false)
          callUserdataApi();
          //  window.location.href=`${process.env.REACT_APP_DOMAIN}competitor-settings`
        }
      } catch (err) {
        console.log(err)
      }
    }

  }

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competitor Management </title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Competitor Management</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> Competitor Management</h2>
            {/* <p>as of {jobDate}</p> */}
          </div>
          <div className="cnt_hdr_top_rgt_wrap">

            {competitorSettingsPlan.status &&
              <button
                title="Add a Competitor"
                className="runreportnew"
                onClick={() => { openModal() }}


              >
                Add a Competitor
              </button>
            }
          </div>
        </div>

        {competitorSettingsPlan.status ?
          <div>
            <div className="data_table brand_list_table campapgin_settings_list_table" style={{ width: "100%" }}>
              <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                <DataGrid

                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                    footer: () => (
                      <Box className="table-footer-container">
                        <Box className="table-footer-pagination">
                          <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                        </Box>
                        <MSelect
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                          size="small"
                          className="table-footer-select"
                        >
                          <MenuItem value={10}>10 per page</MenuItem>
                          <MenuItem value={30}>30 per page</MenuItem>
                          <MenuItem value={50}>50 per page</MenuItem>
                          <MenuItem value={75}>75 per page</MenuItem>
                          <MenuItem value={100}>100 per page</MenuItem>
                        </MSelect>
                      </Box>
                    ),

                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                  columns={columns}
                  pagination
                  // pageSizeOptions={[5, 10, 20]}
                  disableVirtualization
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                // initialState={{
                //   pagination: {
                //     paginationModel: {
                //       pageSize: 10,
                //     },
                //   },
                // }}
                // loading={loading}
                // pageSizeOptions={[10, 30, 50, 75, 100]}
                />
              </div>
            </div>
          </div> : <Freeaudit planStatus={competitorSettingsPlan} />
        }

      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              Are you sure you want to delete this competitor?

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={handleConfirmDelete} style={{ cursor: 'pointer' }} >
            Yes
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>No</button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      <Dialog
        open={apisuccessopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><CheckCircleOutlineIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apisuccessMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {CamPopTitle && formOpen && <Dialog
        open={formOpen}
        onClose={finalreportclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter keyword_edit_form"
      >
        <DialogTitle className="keyword-dialog-header"
        >{CamPopTitle}</DialogTitle>
        <hr />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <form className="frm_container" id="reset_form">
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label for="city">
                        Competitor Name:
                      </label>
                      <input title="This name is used as a alias to mange your domain." type="text" name="websitename" onChange={onwebName} placeholder="Campaign Name" className="campaign-name" defaultValue={websiteName} />
                    </div>
                    <div>
                      <span className="error-value">{webnameError}</span>
                    </div>
                  </div>
                  <div className="keyword-col2-fields">
                    <div>
                      <label for="city">
                        Competitor URL:
                      </label>
                      <input className="campaign-name" type="text" title="URL of your website (eg: www.example.com)" name="websitelink" onChange={onwebLink} placeholder="Enter Url" defaultValue={webLink} />
                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{weblinkError}</span>
                    </div>
                  </div>

                </div>
                <div className="campaigns-addedit-row">
                  <div>
                    <label>Does your website need authentication to access?</label>
                    <div style={{display: "flex"}}>
                      <div className="websitetype_inner">
                        <input type="radio" id="yes" name="webauthenticate" value={1} checked={webauthenticate == 1} onChange={onOptionChange} />
                        <label htmlFor="yes">Yes</label>
                      </div>
                      <div className="websitetype_inner">
                        <input type="radio" id="no" name="webauthenticate" value={0} checked={webauthenticate == 0} onChange={onOptionChange} />
                        <label htmlFor="no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                {showResults == 1 && (
                  <div className="campaigns-addedit-row">
                    <div className="keyword-col1-fields">
                      <div>
                        <label for="city">
                          User Name:
                        </label>
                        <input title="This name is used as a alias to mange your domain." type="text" name="websitename" onChange={usernameVal} placeholder="Enter User Name" className="campaign-name" defaultValue={onUsername} />
                      </div>
                      <div>
                        <span className="error-value">{usernameError}</span>
                      </div>
                    </div>
                    <div className="keyword-col2-fields">
                      <div>
                        <label for="city">
                          Password:
                        </label>
                        <input className="campaign-name" type="text" title="URL of your website (eg: www.example.com)" name="websitelink" onChange={passwordval} placeholder="Enter Password" defaultValue={onPassword} />
                      </div>
                      <div>
                        {" "}
                        <span className="error-value">{passwordError}</span>
                      </div>
                    </div>

                  </div>)}

              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={finalreportNow} style={{ cursor: 'pointer' }} >
            {Userformdataid == "" ? "Submit" : "Update"}
          </button>
          <button style={{ cursor: 'pointer' }} onClick={finalreportclose}>close</button>
        </DialogActions>
      </Dialog>}

    </div>


  );
}
