import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./Logindetail.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import isURL from 'validator/lib/isURL';
import { SetDomainHeader } from "../../../Store/DomainData";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Stepperformhead from "./Stepperformhead";
import { SetUserToken } from "../../../Store/UserTokenSlice";
import { SetAuthToken } from "../../../Store/ApiAuthKey";
import logoimage from '../Asset/New_Assert/ESO_Logo.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';
import Select from "react-select";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validator from 'validator'
import _ from 'lodash';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from "@mui/material/Tooltip";



function Domaindata() {
  const [formState, setFormState] = useState({
    webauthenticate: "",
    webtype: "",
    showResults: false,
    websiteName: "",
    webLink: "",
    onUsername: "",
    onPassword: "",
    errors: {
      webnameError: "",
      weblinkError: "",
      onUserError: "",
      onPasswordError: "",
      onPageError: "",
    },
  });

  const [showPasswordlgn, setShowPasswordlgn] = useState(false);
const usernameRef=useRef(null)
const passwordRef=useRef(null)
  const [loading, setLoading] = useState(false);
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ApiToken = useSelector((state) => state.apiauthkey.value);
  const [isDisabled, setDisabled] = useState(false);
  const [domainusr,setDomainusr]=useState("");
  const [domainpass,setdomainpass]=useState("");
  const websiteoptions = [
    { value: "HTML", label: "HTML", name: "webtype" },
    { value: "JS", label: "JS", name: "webtype" },
    { value: "JS", label: "NOT SURE", name: "webtype" }
  ];


  const webauthenticatestatus = [
    { value: "Yes", label: "Yes", name: "webauthenticate" },
    { value: "No", label: "No", name: "webauthenticate" }
  ]

  const handleClose = () => setApiOpen(false);

  const domainvalidUrl = async (weburl, username = "", password = "") => {
    const { websiteName, webLink, webauthenticate, onUsername, onPassword } = formState;
    var datavar = `${process.env.REACT_APP_API_URL}domain/check_url/?url=${weburl}&http_username=${username}&http_password=${password}`

    const doaminsectiondatavalue = await axios.get(
      datavar,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          errors.weblinkError = error?.response?.data?.error;
          setFormState((prevState) => ({ ...prevState, errors }));
          setDisabled(true)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
    if (doaminsectiondatavalue?.status == 200) {
      setDisabled(false)
    }

  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const debouncedSearch = useCallback(_.debounce((url, term1, term2) => {
    // Your search logic (e.g., API call) goes here
    if (term1 != "")
      domainvalidUrl(url, term1, term2)

  }, 2500), []);
  const handleChange = (e) => {
    const { websiteName, webLink, webauthenticate, onUsername, onPassword } = formState;
    if (e.target) {
      const { name, value } = e.target;
     if(name=="onUsername")
      setDomainusr(e.target.value)
    if(name=="onPassword")
      setdomainpass(passwordRef.current.value)
      let errors = {};
      errors.weblinkError = "";
      errors.onUserError = "";
      errors.onPasswordError = "";
      if (name == "webLink") {
        if (value != "") {
          if (isURL(value)) {
         
            if (webauthenticate.value == "No" && value) {
              domainvalidUrl(value, onUsername, onPassword)
            }
            else if (webauthenticate.value == "Yes" &&  usernameRef.current.value &&  passwordRef.current.value && value) {
              domainvalidUrl(value, usernameRef.current.value,  passwordRef.current.value)
            }
          }
          else {

            errors.weblinkError = "Enter Valid Website Link";
          }
        }
        else {
          errors.weblinkError = "Enter Valid Website Link";
        }
        setFormState((prevState) => ({ ...prevState, errors }));
      }
      if (webauthenticate.value == "Yes") {
        setDisabled(true)
        if (!domainusr) {
          errors.onUserError = "Enter Username";
        }
        if (!onPassword) {
          errors.onPasswordError = "Enter Password";
        }
        if ( usernameRef.current.value &&  passwordRef.current.value &&  usernameRef.current.value.length>3 && passwordRef.current.value.length >3) {
          domainvalidUrl(webLink,  usernameRef.current.value, passwordRef.current.value)
        }
        setFormState((prevState) => ({ ...prevState, errors }));
      }
      localStorage.setItem(e.target.name, e.target.value);
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
        ...(name === "webauthenticate" && {
          showResults: value === "Yes",
          onUsername: value === "No" ? "" : prevState.onUsername,
          onPassword: value === "No" ? "" : prevState.onPassword,
        }),
        errors: { ...prevState.errors, [`${name}Error`]: "" },
      }));

    } else {
      const { name, value } = e;
      if (name == "webauthenticate") {
        if (value == "No") {
          domainvalidUrl(webLink, onUsername, onPassword)
        }
      }
      localStorage.setItem(name, JSON.stringify(e));
      setFormState((prevState) => ({
        ...prevState,
        [name]: e,
        ...(name === "webauthenticate" && {
          showResults: value === "Yes",
          onUsername: value === "No" ? "" : prevState.onUsername,
          onPassword: value === "No" ? "" : prevState.onPassword,
        }),
        errors: { ...prevState.errors, [`${name}Error`]: "" },
      }));

    }



  };

  const validateForm = () => {
    const { websiteName, webLink, webauthenticate, onUsername, onPassword } = formState;
    let errors = {};
    let isValid = true;

    if (!websiteName) {
      errors.webnameError = "Enter Website Name";
      isValid = false;
    }

    if (!webLink) {
      errors.weblinkError = "Enter Link Address";
      isValid = false;
    } else if (!isURL(webLink)) {
      errors.weblinkError = "Enter Valid Link Address";
      isValid = false;
    }

    if (webauthenticate === "Yes") {
      if (!onUsername) {
        errors.onUserError = "Enter Username";
        isValid = false;
      }
      if (!onPassword) {
        errors.onPasswordError = "Enter Password";
        isValid = false;
      }
    }

    setFormState((prevState) => ({ ...prevState, errors }));
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm()) return;
    setLoading(true);

    const { websiteName, webLink, webtype, webauthenticate, onUsername, onPassword } = formState;
    const domainData = {
      domain_name: websiteName,
      domain_url: webLink,
      domain_type: webtype.value,
      domain_has_http: webauthenticate.value === "Yes",
      domain_http_username: onUsername,
      domain_http_password: onPassword,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}domain/add_domain/`, domainData, {
        headers: { Authorization: ApiToken },
      });

      if (response?.data?.status_code === 200) {
        dispatch(SetDomainHeader({ header_selected: response.data.user_domain_id }));
        window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
      }
    } catch (error) {
      const status = error?.response?.status;
      if ([599, 500, 400, 404].includes(status)) {
        setApiErrorMsg(error?.response?.data?.error);
        setApiOpen(true);
        setDisabled(true)
      } else if (error?.code === "ERR_NETWORK") {
        navigate('/errorpages');
      } else {
        setFormState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, onPageError: error.response.data.error },
        }));
        setDisabled(true)
      }
    } finally {
      setLoading(false);
    }
  };

  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {

        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        // localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      // localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };


  const handleTogglePasswordlgn = () => {
    setShowPasswordlgn(!showPasswordlgn);
  };

  useEffect(() => {

    window.close();

    if (localStorage.getItem("websiteName")) {
      setFormState(prevState => ({
        ...prevState,
        websiteName: localStorage.getItem("websiteName")
      }));
    }


    if (localStorage.getItem("webLink")) {
      setFormState(prevState => ({
        ...prevState,
        webLink: localStorage.getItem("webLink")
      }));
    }

    if (localStorage.getItem("webtype")) {
      setFormState(prevState => ({
        ...prevState,
        webtype: JSON.parse(localStorage.getItem("webtype"))
      }));
    }


    if (localStorage.getItem("webauthenticate")) {
      setFormState(prevState => ({
        ...prevState,
        webauthenticate: JSON.parse(localStorage.getItem("webauthenticate")),
        showResults: JSON.parse(localStorage.getItem("webauthenticate")).value === "Yes" ? true : false
      }));



    }

    if (localStorage.getItem("onUsername")) {
      setFormState(prevState => ({
        ...prevState,
        onUsername: localStorage.getItem("onUsername")
      }));
    }


    if (localStorage.getItem("onPassword")) {
      setFormState(prevState => ({
        ...prevState,
        onPassword: localStorage.getItem("onPassword")
      }));
    }


  }, [])


  const { websiteName, webLink, webtype, webauthenticate, showResults, errors } = formState;
  return (
    <>

      <div className="userdata">
        <div className="cnt_hdr_top_rgt_wrap" style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px", background: "#fff", position: "fixed", zIndex: 999 }}>
          {/* Logo at the start */}
          <img
            className="Eso_logo"
            src={logoimage}
            alt="logo"
            style={{ width: "14%", background: "#fff" }}
          />

          {/* Group for the two buttons */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingRight: "20px" }}>
            <button
              onClick={handleToggle}
              className="faq_class"
              title="Frequently Asked Question"
              style={{ padding: "8px 13px 8px 7px" }}
            >
              <QuestionMarkIcon />
            </button>

            {/* FAQ Sidebar conditionally rendered */}
            {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

            <button onClick={TriggerLogout}>
              <span
                className="material-icons"
                title="Logout"
                style={{ marginLeft: '5px', padding: "10px 8px 10px 0px" }}
              >
                <LogoutIcon />
              </span>
            </button>
          </div>
        </div>

        <Stepperformhead setActiveStep={2} />

        <div className="outer-container"
          style={{
            width: "calc(100% - 60px)",
            marginRight: "60px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="userdata_inner">
            <h1>Please Share Your Website Details</h1>
            <p>This Helps Us Customize Your Experience</p>
            <span className="domain-errordata">{errors.onPageError}</span>

            <form className="frm_container">
              <div className="campaigns-addedit-row">
                <div className="keyword-col1-fields">
                  <div>
                    <label htmlFor="websiteName">Website Name:</label>
                    <input
                      id="websiteName"
                      title="This name is used as an alias to manage your domain."
                      type="text"
                      name="websiteName"
                      placeholder="Enter Website Name"
                      className="userdataselectdomain"
                      value={websiteName}
                      onChange={handleChange}
                      style={{ display: "block", marginTop: "5px", textAlign: "left" }}
                    />
                  </div>
                  <div>
                    <span className="domain-errordata">{errors.webnameError}</span>
                  </div>
                </div>
                <div className="keyword-col2-fields">
                  <div>
                    <label htmlFor="webLink">Url :</label>
                    <input
                      id="webLink"
                      type="text"
                      title="URL of your website (eg: www.example.com)"
                      name="webLink"
                      className="userdataselectdomain"
                      placeholder="Enter Website Url"
                      value={webLink}
                      onChange={handleChange}
                      style={{ display: "block", marginTop: "5px", width: "auto" }}
                    />
                  </div>
                  <div>
                    {" "}
                    <span className="domain-errordata">{errors.weblinkError}</span>
                  </div>
                </div>
              </div>
              <div className="campaigns-addedit-row" style={{ placeItems: "end" }}>
                <div className="keyword-col1-fields">
                  <div>
                    <div style={{ display: "flex" }}>
                      <label>Website Type:</label>
                      <Tooltip title="Select the technology used to render your website (HTML or JavaScript). If you are not sure, keep it as “JS”." arrow>
                        <InfoIcon style={{ width: "0.8em", cursor: "pointer" }} />
                      </Tooltip>

                    </div>
                    <Select
                      id="webtype"
                      name="webtype"
                      title="Select the technology used to render your website (HTML or JavaScript). If you are not sure, keep it as “JS”."
                      value={webtype}
                      options={websiteoptions}
                      placeholder="Enter Website Type"
                      onChange={handleChange}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>
                <div className="keyword-col2-fields">
                  <div>
                    <div >
                      <label>Does your website need authentication to access?</label>
                      <Tooltip title="If your website uses HTTP authentication (common for development sites), select 'Yes' otherwise, select 'No'. If you select 'Yes' you will be prompted to enter the username and password further for us to view your website." arrow>
                        <InfoIcon style={{ width: "0.8em", cursor: "pointer" }} />
                      </Tooltip>
                    </div>
                    <Select
                      id="webauthenticate"
                      name="webauthenticate"
                      title="Does your website need HTTP authentication?"
                      value={webauthenticate}
                      onChange={handleChange}
                      options={webauthenticatestatus}
                      placeholder="Enter Yes or No"
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>
              </div>
              {showResults && (
                <>
                  <div className="campaigns-addedit-row">
                    <div className="keyword-col1-fields">
                      <div>
                        <label htmlFor="onUsername">Username:</label>
                        <input
                          type="text"
                          id="onUsername"
                          name="onUsername"
                          title="Username"
                          placeholder="Enter Username"
                          value={formState.onUsername}
                          onChange={handleChange}
                          ref={usernameRef}
                          style={{ display: "block", marginTop: "5px" }}
                        />
                      </div>
                      <div>
                        <span className="domain-errordata">{errors.onUserError}</span>
                      </div>
                    </div>
                    <div className="keyword-col2-fields">
                      <div>
                        <label htmlFor="onPassword">Password:</label>
                        <div className="password-container">
                          <input
                            type={showPasswordlgn ? "text" : "password"}
                            id="onPassword"
                            name="onPassword"
                            title="Password"
                            placeholder="Enter Password"
                            value={formState.onPassword}
                            onChange={handleChange}
                            ref={passwordRef} 
                            style={{ display: "block", marginTop: "5px", paddingRight: "35px" }}  // Add padding to the right for button space
                          />
                          <button
                            type="button"
                            className="togglepwd"
                            onClick={handleTogglePasswordlgn}
                          >
                            {showPasswordlgn ? <FaEye className="FaEy" /> : <FaEyeSlash className="FaEyeSlash" />}
                          </button>
                        </div>
                      </div>
                      <div>
                        {" "}
                        <span className="domain-errordata">{errors.onPasswordError}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <table /*style={{ display: "grid", justifyContent: "center" }} */ >
                <tbody style={{ display: "grid" }}>
                  
                </tbody>
              </table>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/Impadddomain")}
                  sx={{
                    padding: "10px 10px",
                    borderRadius: "10px",
                    // fontWeight: "900",
                    fontSize: "16px",
                    backgroundColor: "#ff601f",
                    color: "white",
                    "&:hover": { backgroundColor: "#ff7d45" },
                  }}
                >
                  <FastRewindIcon />Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  disabled={
                    !formState.websiteName ||
                    !formState.webLink ||
                    !formState.webtype ||
                    (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword)) ||
                    isDisabled
                  }
                  sx={{
                    marginLeft: "50px",
                    padding: "10px 15px",
                    borderRadius: "10px",
                    // fontWeight: "900",
                    fontSize: "16px",
                    backgroundColor:
                      !formState.websiteName || !formState.webLink || !formState.webtype ||
                        (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                        ? "#ccc"
                        : "#ff601f",
                    color:
                      !formState.websiteName || !formState.webLink || !formState.webtype ||
                        (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                        ? "#666"
                        : "white",
                    "&:hover": {
                      backgroundColor:
                        !formState.websiteName || !formState.webLink || !formState.webtype ||
                          (!formState.webauthenticate && (!formState.onUsername || !formState.onPassword))
                          ? "#ccc"
                          : "#ff7d45",
                    },
                  }}
                >
                  Next<FastForwardIcon style={{ marginLeft: "-10px" }} />
                </Button>
              </Box>
            </form>




          </div>
        </div>
        <Dialog open={apiOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{apiErrorMsg}</DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div >
    </>
  );
}

export default Domaindata;
