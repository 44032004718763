import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./DomainLogindetail.css";
import { Link, useParams } from "react-router-dom";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AppContext } from "../../../Context/Context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
function Domaindata(props) {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [onUsername, SetonUsername] = useState("")
  const [onPassword, SetonPassword] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [onUserError, SetonUserError] = useState("")
  const [onPasswordError, SetonPasswordError] = useState("")
  const [onPageError, SetonPageError] = useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const [regionalLevel, setRegionalLevel] = useState(null);
  const [SearchEngine, SetSearchEngine] = useState(null);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [cityValue, setCityValue] = useState(null);
  const [Country, SetCountry] = useState(null);
  const [State, SetState] = useState(null);
  const [City, SetCity] = useState(null);
  const [engineerr, setEngineerr] = useState(null);
  const [countryerr, setCountryerr] = useState(null);
  const [stateerr, setStateerr] = useState(null);
  const [cityerr, setCityerr] = useState(null);


  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const navigate = useNavigate();



  useEffect(() => {
    LoadAddFormFilterOptions();
    getRegionalLevelSetting();
  }, []);

  const getRegionalLevelSetting = async () => {
    setLoading(true);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      console.log('datavalue regional level setting:', datavalue)
      if (datavalue?.data) {
        setRegionalLevel(datavalue.data.keyword_settings);
      }
    } catch (err) {
      setLoading(false);
      console.log('error in getting regional level setting: ' + err);
    }
  };

  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value === "Yes" ? setShowResults(true) : setShowResults(false)
    e.target.value === "No" && SetonUsername(""); SetonPassword("");
  }
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }
  const onWebsiteChange = e => {
    Setwebtype(e.target.value)
  }
  const onWebnameChange = e => {
    SetonUsername(e.target.value)
  }
  const onpasswordChange = e => {
    SetonPassword(e.target.value)
  }
  const finalreportCancel = async () => {
    // window.location.href=`${process.env.REACT_APP_DOMAIN}domain-list`
    props.setAddEditDomainPopup(false);
  }
  const finalreportNow = async () => {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    SetonUserError("")
    SetonPasswordError("")
    setLoading(true);
    var data_val = 1;
    if (websiteName === "") {
      SetWebnameError("Enter Website Name")
      data_val = 2;
    }
    if (webLink === "") {
      SetweblinkError("Enter the Website Link")
      data_val = 2;
    }
    if (webLink !== "") {
      if (!isURL(webLink)) {
        SetweblinkError("Enter Valid Link Address")
        data_val = 2;
      }
    }
    if (webauthenticate === "Yes") {
      if (onUsername === "") {
        SetonUserError("Please enter a http Username")
      }
      if (onPassword === "") {
        SetonPasswordError("Please enter a http password")
      }
    }
    if (!SearchEngine) {
      setEngineerr("Please select search engine");
      data_val = 2;
    }
    if (!countryValue) {
      setCountryerr("Please select country");
      data_val = 2;
    }
    if (!stateValue && (regionalLevel == 'State' || regionalLevel == 'City')) {
      setStateerr("Please select state");
      data_val = 2;
    }
    // if (!cityValue && regionalLevel == 'City') {
    //   setCityerr("Please select city");
    //   data_val = 2;
    // }
    if (data_val === 2) {
      setLoading(false);

      return false;
    }
    else {
      try {
        setLoading(true);
        if (webauthenticate == "Yes")
          var webauthenticates = true;
        else
          var webauthenticates = false;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}domain/add_domain/`,
          {
            domain_name: websiteName,
            domain_url: webLink,
            domain_type: webtype === "NOT SURE" ? "JS" : webtype,
            domain_has_http: webauthenticates,
            domain_http_username: onUsername,
            domain_http_password: onPassword,
            search_engine_id: SearchEngine.value,
            country_id: countryValue.value,
            state_id: stateValue?.value,
            city_id: cityValue?.value

          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        if (response?.data?.status_code == 200) {
          // dispatch(SetDomainHeader({ header_selected: response.data.user_domain_id }))
          //  window.location.href=`${process.env.REACT_APP_DOMAIN}domain-list/domain-ownership/`
          props.setAddEditPopupTitle('domain_ownership');
          props.callUserdataApi();
        }
      } catch (err) {
        setLoading(false);
        console.log('error in create domain' + err)
        SetonPageError(err?.response?.data?.error)
      }
    }

  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const LoadAddFormFilterOptions = async (countryId, searchEngineId) => {
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            if (element.id === searchEngineId) {
              SetSearchEngine({ label: element.name, value: element.id })
            }
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) { }
  };

  const handlestatedataoptions = (e) => {

    // var Countrys= e.target.value;
    setCountryValue(e);

    setStateValue("");
    setCityValue("");
    SetState("");
    SetCity("");

    var Countrys = e.value;
    handlestateoptions(Countrys)
  }

  const handlestateoptions = async (Countrys = "", stateId) => {

    if (Countrys == "" || Countrys == null || !Countrys)
      return false;


    SetCountry(Countrys);
    console.log('Countrys', Countrys);

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Countrys}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
        var stateData = Sections.find((option) => option.value === stateId);
        setStateValue(stateData);
        // SetState("");
      }
    }
  };
  const handleCityoptionsdata = (e) => {
    // var States = e.target.value;
    setStateValue(e);
    SetState("");
    SetCity("");
    var States = e.value;
    handleCityoptions(States)
  }
  const handleCityoptions = async (States = "", cityId) => {
    if (States == "" || States == null || !States) {
      return false;
    }

    SetState(States);

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${States}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
        var cityData = Sections.find((option) => option.value === cityId);
        setCityValue(cityData);
        // SetCity("");
      }
    }
  };

  const setCurrentCityValue = (e) => {
    setCityValue(e);
    SetCity(e.value);
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={`${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div>
        <div>
          {/* <h3 style={{ marginBottom: '50px' }}>Add New Website</h3> */}
          <span className="error-value">{/* onPageError */}</span>

          <form className="frm_container">
            <div className="campaigns-addedit-row">
              <div className="keyword-col1-fields">
                <div>
                  <label>
                    Website Name :
                  </label>
                  <input
                    title="This name is used as an alias to manage your domain."
                    type="text"
                    name="websitename"
                    onChange={onwebName}
                    placeholder=" Enter Website Name"
                  />
                </div>
                <div>
                  <span className="error-value">{webnameError}</span>
                </div>
              </div>
              <div className="keyword-col2-fields">
                <div>
                  {/* <label>
                    Website Link :
                  </label> */}
                  <label>
                    URL :
                  </label>
                  <input
                    type="text"
                    title="URL of your website (eg: www.example.com)"
                    name="websitelink"
                    onChange={onwebLink}
                    placeholder="   Enter Website URL" />
                </div>
                <div>
                  {" "}
                  <span className="error-value">{weblinkError}</span>
                </div>
              </div>
            </div>
            <div className="campaigns-addedit-row" style={{ placeItems: "end" }}>
              <div className="keyword-col1-fields">
                <div>
                  <label>
                    Website Type :
                  </label>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }} >
                    <div /*className="websitetype_inner"*/ title="HTML (HyperText Markup Language) is the standard language used to create the structure of a webpage. It includes elements like headings, paragraphs, links, images, and tables.">
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="HTML"
                        checked={webtype === "HTML"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="html">HTML</label>
                    </div>
                    <div /*className="websitetype_inner"*/ title="JavaScript is a programming language used to add interactivity and dynamic behavior to a website. This can include things like animations, interactive forms, and pop-up windows.">
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="JS"
                        checked={webtype === "JS"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="css">JS</label>
                    </div>

                    <div>
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="NOT SURE"
                        checked={webtype === "NOT SURE"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="css">Not Sure</label>
                    </div>

                  </div>
                </div>
                <div>
                </div>
              </div>
              <div className="keyword-col2-fields">
                <div>
                  <label>
                    Does your website need authentication to access? :
                  </label>
                  <div>
                    <div className="websitetype_inner">
                      <input
                        type="radio"
                        id="yes"
                        name="webauthenticate"
                        value="Yes"
                        checked={webauthenticate === "Yes"}
                        onChange={onOptionChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="websitetype_inner">
                      <input
                        type="radio"
                        id="no"
                        name="webauthenticate"
                        value="No"
                        checked={webauthenticate === "No"}
                        onChange={onOptionChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
            {showResults &&
              <>
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label>
                        Username :
                      </label>
                      <input
                        type="text"
                        id="textField"
                        title="Username"
                        onChange={onWebnameChange}
                      />
                    </div>
                    <div>
                      <span className="error-value">{onUserError}</span>
                    </div>
                  </div>
                  <div className="keyword-col2-fields">
                    <div>
                      <label>
                        Password :
                      </label>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          onChange={onpasswordChange}
                          className="domain-password"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label={showPassword ? 'hide the password' : 'display the password'}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                onMouseUp={handleMouseUpPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      {/* <div  style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      id="textField"
                      title="Password"
                      onChange={onpasswordChange}
                      style={{ 
                        fontSize: '15px', 
                        height: '40px', 
                        paddingRight: '5px' // Leave space for the eye icon
                      }}
                    />
                    <span className="error-value">{onPasswordError}</span>
                    <span className="add_domain_eye"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        zIndex: 2 // Ensures the icon is clickable
                      }}
                    >
                      <FontAwesomeIcon icon={showPassword ?   faEye : faEyeSlash} />
                    </span>
                  </div> */}


                    </div>
                    <div>
                      {" "}
                      <span className="error-value">{onPasswordError}</span>
                    </div>
                  </div>
                </div>
              </>
            }
            <div className="campaigns-addedit-row">
              <div className="keyword-col1-fields">
                <div>
                  <label>
                    Search Engine :
                  </label>
                  <Select
                    name="engine"
                    value={SearchEngine}
                    options={Engineoptions}
                    onChange={(e) => { SetSearchEngine(e) }}
                    placeholder="Select Engine"
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                    className=""
                    classNamePrefix="addkeyword"
                  />
                </div>
                <div>
                  <span className="error-value">{engineerr}</span>
                </div>
              </div>
              <div className="keyword-col2-fields">
                <div>
                  <label>
                    Country :
                  </label>
                  <Select
                    id="yourElementId"
                    name="country"
                    value={countryValue}
                    options={Countryoptions}
                    onChange={(e) => { handlestatedataoptions(e) }}
                    placeholder="Select Country"
                    isSearchable
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                    className=""
                    classNamePrefix="addkeyword"
                  />
                </div>
                <div>
                  {" "}
                  <span className="error-value">{countryerr}</span>
                </div>
              </div>
            </div>
            <div className="campaigns-addedit-row">
              {(regionalLevel == 'State' || regionalLevel == 'City') &&
                <div className="keyword-col1-fields">
                  <div>
                    <label>
                      State :
                    </label>
                    <Select
                      name="state"
                      value={stateValue}
                      options={Stateoptions}
                      onChange={handleCityoptionsdata}
                      placeholder="Select State"
                      isSearchable
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className=""
                      classNamePrefix="addkeyword"
                    />
                  </div>
                  <div>
                    <span className="error-value">{stateerr}</span>
                  </div>
                </div>
              }
              {regionalLevel == 'City' &&
                <div className="keyword-col2-fields">
                  <div>
                    <label>
                      City :
                    </label>
                    <Select
                      name="city"
                      value={cityValue}
                      options={CityOptions}
                      onChange={setCurrentCityValue}
                      placeholder="Select City (Optional)"
                      isSearchable
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                      className=""
                      classNamePrefix="addkeyword"
                    />
                  </div>
                  <div>
                    {" "}
                    <span className="error-value">{cityerr}</span>
                  </div>
                </div>
              }
            </div>
            <div style={{ textAlign: 'center' }}>
              <button
                className="keyword-cancel"
                type="button"
                onClick={finalreportCancel}
              >
                Close
              </button>
              <button
                className="keyword-submit"
                type="button"
                onClick={finalreportNow}
              >
                Submit
              </button>
            </div>
          </form>




          {/* <form className='frm_container add_domain_list'>
              <table className="form-table" align="center" style={{ padding: "20px", width: "90%" }}>
                <tr>
                  <th><label>Website Name:</label></th>
                  <td>
                    <input title="This name is used as an alias to manage your domain." type="text" name="websitename" onChange={onwebName} placeholder="   Enter Website Name" />
                    <span className="error-value">{webnameError}</span>
                  </td>
                </tr>

                <tr>
                  <th><label>Website Link:</label></th>
                  <td>
                    <input type="text" title="URL of your website (eg: www.example.com)" name="websitelink" onChange={onwebLink} placeholder="   Enter Website Link" />
                    <span className="error-value">{weblinkError}</span>
                  </td>
                </tr>

                <tr>
                  <th><label>Website Type:</label></th>
                  <td>
                    <div className="websitetype_inner">
                      <input type="radio" id="html" name="fav_language" value="HTML" checked={webtype === "HTML"} onChange={onWebsiteChange} />
                      <label htmlFor="html">HTML</label>
                    </div>
                    <div className="websitetype_inner">
                      <input type="radio" id="css" name="fav_language" value="JS" checked={webtype === "JS"} onChange={onWebsiteChange} />
                      <label htmlFor="css">JS</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th><label>Does your website need authentication to access?</label></th>
                  <td>
                    <div className="websitetype_inner">
                      <input type="radio" id="yes" name="webauthenticate" value="Yes" checked={webauthenticate === "Yes"} onChange={onOptionChange} />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="websitetype_inner">
                      <input type="radio" id="no" name="webauthenticate" value="No" checked={webauthenticate === "No"} onChange={onOptionChange} />
                      <label htmlFor="no">No</label>
                    </div>
                  </td>
                </tr>

                {showResults && (
                  <>
                    <tr>
                      <th><label htmlFor="textField">Username:</label></th>
                      <td>
                        <input type="text" id="textField" title="Username" onChange={onWebnameChange} />
                        <span className="error-value">{onUserError}</span>
                      </td>
                    </tr>
                    <tr>
                      <th><label htmlFor="textField">Password:</label></th>
                      <td>
      <div  style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
        <input
          type={showPassword ? 'text' : 'password'}
          id="textField"
          title="Password"
          onChange={onpasswordChange}
          style={{ 
            fontSize: '15px', 
            height: '40px', 
            paddingRight: '5px' // Leave space for the eye icon
          }}
        />
        <span className="error-value">{onPasswordError}</span>
        <span className="add_domain_eye"
          onClick={togglePasswordVisibility}
          style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 2 // Ensures the icon is clickable
          }}
        >
          <FontAwesomeIcon icon={showPassword ?   faEye : faEyeSlash} />
        </span>
      </div>
    </td>
                    </tr>
                  </>
                )}
                <tr>
                  <th><label>Search Engine</label></th>
                  <td>
                      <Select
                        name="engine"
                        value={SearchEngine}
                        options={Engineoptions}
                        onChange={(e) => { SetSearchEngine(e) }}
                        placeholder="Select Engine"
                        styles={customDropdownStyles()}
menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    <span className="error-value">{engineerr}</span>
                  </td>
                </tr>
                <tr>
                  <th><label>Country:</label></th>
                  <td>
                  <Select
                        id="yourElementId"
                        name="country"
                        value={countryValue}
                        options={Countryoptions}
                        onChange={(e) => { handlestatedataoptions(e) }}
                        placeholder="Select Country"
                        isSearchable
                        styles={customDropdownStyles()}
menuPortalTarget={document.body}
                        className=""
                        classNamePrefix="addkeyword"
                      />
                    <span className="error-value">{countryerr}</span>
                  </td>
                </tr>
                {(regionalLevel=='State' || regionalLevel=='City') &&
                  <tr>
                  <th><label>State:</label></th>
                  <td>
                  <Select
                    name="state"
                    value={stateValue}
                    options={Stateoptions}
                    onChange={handleCityoptionsdata}
                    placeholder="Select State"
                    isSearchable
                    styles={customDropdownStyles()}
menuPortalTarget={document.body}
                    className=""
                    classNamePrefix="addkeyword"
                  />
                  <span className="error-value">{stateerr}</span>
                  </td>
                </tr>
                }
                {regionalLevel=='City' &&
                  <tr>
                  <th><label>City:</label></th>
                  <td>
                  <Select
                    name="city"
                    value={cityValue}
                    options={CityOptions}
                    onChange={setCurrentCityValue}
                    placeholder="Select City"
                    isSearchable
                    styles={customDropdownStyles()}
menuPortalTarget={document.body}
                    className=""
                    classNamePrefix="addkeyword"
                  />
                  <span className="error-value">{cityerr}</span>
                  </td>
                </tr>
                }
                
              </table>
              <div className="form_next" style={{marginTop: '40px'}}>
                <button className="google_analytics_btn" type="button" onClick={finalreportCancel}>Cancel</button>
                <button className="google_analytics_btn" type="button" onClick={finalreportNow}>Submit</button>
              </div>
            </form> */}
        </div>
      </div>
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </div>

  );
}

export default Domaindata;


