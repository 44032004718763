import React, { useState, useEffect, useContext } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";


const Topactionsetting = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [PriorityOptions, SetPriorityOptions] = useState([])
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [topActionPriorityPlan, settopActionPriorityPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const columns = [

    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,

    },
    {
      field: "category",
      headerName: "Category",
      description: '',
      minWidth: 300,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "action_items",
      headerName: "Action Item",
      description: '',
      minWidth: 400,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies',
      minWidth: 500,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        console.log("defaultvalue", cardData.row)
        var DefaultValue = cardData.row.severity
        var Tag = cardData.row.action_items
        console.log("tag", DefaultValue)
        return (
          <select style={{border:"1px solid #ff601f"}} className="site_audit_settings_priority" onChange={(e) => { ChangeSettings(e, Tag) }}>

            {PriorityOptions.map(obj => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              return (<option key={value} value={value} selected={DefaultValue == value ? true : ''}>{key}</option>)
            })}



          </select>
        )

      }

    },

  ];

  const ChangeSettings = async (e, tag) => {
    setLoading(true)
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/top_action_update_severity/?user_domain_id=${esodata.domaindata.value.header_selected}&action_items=${tag}&new_severity=${e.target.value}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      setLoading(false)

      if (datavalue?.data) {
        GetDefaultSettings()
        console.log('settingssss', datavalue.data)
      }

    } catch (err) {
      setLoading(false)
      console.log(err)
    }



  }


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));



  const GetDefaultSettings = async () => {
    setLoading(true)
    setCardData([])
    try {


      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/top_action_settings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      setLoading(false)
      if (datavalue?.data && datavalue.data?.top_action_settings) {
        var FinalAObj = [];

        datavalue.data.top_action_settings.forEach((element, index) => {
          var Obj = {};
          Obj.sid = index + 1;
          Obj.category = element.fields.category
          Obj.action_items = element.fields.action_items
          Obj.severity = element.fields.severity
          //  Obj.pk=element.pk
          FinalAObj.push(Obj)
        });
        // console.log(FinalAObj)
        // console.log("dummy", datavalue.data.top_action_settings?.fields)
        // const newData =  datavalue.data.top_action_settings?.fields.map((obj, index) => {

        //   return { ...obj, sid: index+1 };
        // });
        console.log("dummy", FinalAObj)
        setCardData(FinalAObj)
        SetAutoheightval(false)
      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }


    } catch (err) {
      setLoading(false)
      console.log(err)
    }




  }

  const GetPriorityOptions = async () => {
    setLoading(true)
    SetPriorityOptions([])
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/top_action_priority_types/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false)

      if (datavalue?.data) {
        console.log("datatop", datavalue.data)
        const outputArray = [];

        for (const key in datavalue.data) {
          if (datavalue.data.hasOwnProperty(key)) {
            outputArray.push({ [key]: datavalue.data[key] });
          }
        }

        SetPriorityOptions(outputArray)

      }

    } catch (err) {
      setLoading(false)
      console.log(err)
    }




  }

  const checkUserPlan = async () => {
    const topActionPriorityP = await Utils("priority_settings", ApiToken);
    if (topActionPriorityP.status) {
      GetDefaultSettings()
      GetPriorityOptions()
      faqdatafun()
    }
    settopActionPriorityPlan(topActionPriorityP);
  };

  useEffect(() => {
    checkUserPlan();
  }, [])

  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer')) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=43`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Top Actions Priority Settings</title>
      </Helmet>
      {
        loading === true ? <Loader /> : null
      }
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Link to="/">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Top Actions Priority Settings
            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
       
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Top Actions Priority Settings</h2>
              {/* <p>as of {jobDate}</p> */}
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
            <Link to="/site-audit-priority" ><button type="button" className={`site_rn_rpt_btn`}  style={{color:"#ff601f !important",width:150,height:37,padding:8}} >Site Audit Priority</button></Link>

               <Link to="/top-action-priority" ><button type="button" className="site_rn_rpt_btn active runreportnew"  style={{ letterSpacing: "unset !important", lineHeight: "unset !important" }}   >Top Action Priority </button></Link>
           
     
         </div>
      
          </div>
         
          <div className="link_cnt_hdr_blw_wrap">
            {/* <Link to="/site-audit-priority" ><button type="button" className={`site_rn_rpt_btn `}  >Site Audit Priority</button></Link>

            <Link to="/top-action-priority" ><button type="button" className={`site_rn_rpt_btn active`}  >Top Action Priority</button></Link> */}

          </div>

          {topActionPriorityPlan.status ?
            <div className="data_table" style={{ width: "100%" }}>
                       <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">

                <DataGrid
                  
                  autoHeight={autoheightval ? true : false}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                    footer: () => (
                      <Box className="table-footer-container">
                        <Box className="table-footer-pagination">
                          <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                        </Box>
                        <MSelect 
                          value={rowsPerPage} 
                          onChange={handleRowsPerPageChange} 
                          size="small"
                          className="table-footer-select"
                        >
                          <MenuItem value={10}>10 per page</MenuItem>
                          <MenuItem value={30}>30 per page</MenuItem>
                          <MenuItem value={50}>50 per page</MenuItem>
                          <MenuItem value={75}>75 per page</MenuItem>
                          <MenuItem value={100}>100 per page</MenuItem>
                        </MSelect>
                      </Box>
                    ),
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                  columns={columns}
                  pagination
                  // pageSizeOptions={[5, 10, 20]}
                  disableVirtualization
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 10,
                  //     },
                  //   },
                  // }}
                  // pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                />
              </div>
            </div> : <Freeaudit planStatus={topActionPriorityPlan} />
          }





        </div>
        {!openCommonAlerts && <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              borderRadius: "18px !important",
              borderColor: "#ff601f !important",
              top: "10px !important",
              // width: '100%',
              // boxShadow:"0px 15px 10px 0px #fff !important"
            },
          }}
        >
          <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>}
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </>
  )
}
export default Topactionsetting