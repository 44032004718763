import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import ReactApexChart from "react-apexcharts";
import useMediaQuery from '@mui/material/useMediaQuery';
import { height } from "@mui/system";
import Loader from "../CommonLoader";
import { datezonechange } from "../Commonfun"
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import Select from 'react-select';
import ApexCharts from "react-apexcharts";

export default function Internallinkchecker(props) {

  const isFirefox = typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().includes("firefox");
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:900px)');
  const isDesktop = useMediaQuery('(min-width:900px)');
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState(null);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;


  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];


  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',

    },
    {
      field: "url",
      headerName: "URL",
      description: 'Internal web page URL of the domain',
      minWidth: 300,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cardData) => {

        let baseUrl = cardData.row.url.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = cardData.row.url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },

    },
    {
      field: "status_code",
      headerName: "Status Code",
      description: 'Codes generated by the server in response to the client request',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',

    },
    {
      field: "is_valid",
      headerName: "Valid/Invalid",
      description: 'It displays whether the obtained link is valid or not',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',

    },
    {
      field: "source",
      headerName: "Source",
      description: 'The URLs from which the internal link is obtained',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (cardData) => {
        return <a href={detailurl + `/${cardData.row.id}` + "/" + `${jobidData}`} style={{ marginRight: '3px' }}>
          <button className="view-report-button" style={{ width: '80px', placeContent: 'center' }}>
            View
            {/* <img
              src={fav_icon}
              className="view_std_img"
              alt="view report icon"
            /> */}
          </button>
        </a>
      },
    },
    {
      field: "outbound",
      headerName: "Outbound",
      description: 'The links that this page is linked to (i.e., links going out from the web page).',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (cardData) => {
        return <a href={Outdetailurl + `/${cardData.row.id}` + "/" + `${jobidData}`} style={{ marginRight: '3px' }}>
          <button className="view-report-button" style={{ width: '80px', placeContent: 'center' }}>
            View
            {/* <img
              src={fav_icon}
              className="view_std_img"
              alt="view report icon"
            /> */}
          </button>
        </a>
      },
    },

  ];

  const rows = cardData ?? [];

  const [InfoLinkState, SetInfoLinkState] = useState(0)
  const [SuccessLinkState, SetSuccessLinkState] = useState(0)
  const [RedirectLinkState, SetRedirectLinkState] = useState(0)
  const [ClientLinkState, SetClientLinkState] = useState(0)
  const [ServerLinkState, SetServerLinkState] = useState(0)
  const [statusdata, Setstatusdata] = useState([{
    "value": "0xx",
    "label": "All"
  }])
  const [selectstatusdata, Setselectstatusdata] = useState({
    "value": "0xx",
    "label": "All"
  });
  const [selectedstatus, Setselectedstatus] = useState({
    "value": "0xx",
    "label": "All"
  });
  const [PieData, SetPieData] = useState([])
  const [dEngineoption, SetdEngineoption] = useState({
    label:
      'Select Date',
    value:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    label:
      'Select Date',
    value:
      'Select Date',
  });

  const colors = ['blue', 'green', 'purple', 'orange', 'red']


  const callUserdataApi = async (jobid = "", statusdata = "", domainsecdata = "") => {
    setLoading(true);
    // console.log("kananndata",props.Domainsectiondata)
    // if (domainsecdata == "")
    //   domainsecdata = props.Domainsectiondata;
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/v2/internal_links_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&status_code_range=${statusdata}&domain_section_id=${domainsecdata}
        `,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      const dataval = datavalue.data.result;
      console.log("dataval", dataval)
      if (dataval && dataval.length > 0) {
        SetAutoheightval(false)
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });

        setCardData(newData);
      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }
      const PieChartValue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/internal_linkchecker_piechart/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&status_code_range=${statusdata}&domain_section_id=${domainsecdata}
        `,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      var ClietErrorLinks = 0;
      var InformationLinks = 0;
      var SuccessfulLinks = 0;
      var RedirectedLiveInternalLinks = 0;
      var ServerErrorLinks = 0;

      if (PieChartValue.data) {
        ClietErrorLinks = PieChartValue.data.client_error_links_4xx;
        InformationLinks = PieChartValue.data.informational_links_1xx;
        SuccessfulLinks = PieChartValue.data.successful_links_2xx;
        RedirectedLiveInternalLinks = PieChartValue.data.redirected_live_internal_links_3xx;
        ServerErrorLinks = PieChartValue.data.server_error_inks_5xx
      }

      SetpieseriesData([])

      if (InformationLinks != 0 || SuccessfulLinks != 0 || RedirectedLiveInternalLinks != 0 || ClietErrorLinks != 0 || ServerErrorLinks != 0)
        SetpieseriesData([InformationLinks, SuccessfulLinks, RedirectedLiveInternalLinks, ClietErrorLinks, ServerErrorLinks])
      SetInfo(InformationLinks)
      SetSuccess(SuccessfulLinks)
      SetRedirect(RedirectedLiveInternalLinks)
      SetClient(ClietErrorLinks)
      SetServer(ServerErrorLinks)
      setjobidData(datavalue.data.job_id)
      props.setjobDate(moment(datavalue.data.job_date).format("MMMM DD,YYYY HH:mm"))
    } catch (err) {
      console.log('err', err)
    }
    setLoading(false);
  };
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setjobidData(selectval.value);
    closeModal();
    callUserdataApi(selectval.value, "", "");
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const CloseModalTrigger = () => {
    props.setIsOpen(false)
    SetdEngineoption(prevdEngineoption);
  }
  const FetchCompetitorList = () => {

    var errs = 1;
    let errors = {};
    props.setIsOpen(false)
    setprevdEngineoption(dEngineoption);
    props.setSelectstatusval(selectstatusdata?.value)
    if (selectval == "" && selectstatusdata != "") {
      callUserdataApi("", selectstatusdata?.value, "");
      // setSelectval('')
      // Setselectstatusdata('')
      Setselectedstatus(selectstatusdata)
    }
    else if (selectval != "" && selectstatusdata == "") {
      callUserdataApi(selectval?.value, "", "");
      // setSelectval('')
      // Setselectstatusdata('')
      Setselectedstatus(selectstatusdata)
    }

    if (selectstatusdata == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
      props.setIsOpen(true)
      return setValidation(errors);
    }
    else {
      callUserdataApi(selectval?.value, selectstatusdata.value, "");
      // setSelectval('')
      // Setselectstatusdata('')
      Setselectedstatus(selectstatusdata)
    }
    if (selectstatusdata == "") {


      errors.dateerror = "This field is required.";
      errs = 2;
      props.setIsOpen(true)
    }
    else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
  }
  const FetchJobDates = async () => {
    try {
      const completevalues = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=link_checker&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      var Selectedmets = [];
      if (completevalues?.data?.jobs.length > 0) {
        completevalues?.data?.jobs.forEach((element) => {
          Selectedmets.push({ value: element?.id, label: datezonechange(element?.completed_at) });
        });
        setcompletedate(Selectedmets);
        setSelectval(Selectedmets[0]);
        SetdEngineoption(Selectedmets[0]);
        setprevdEngineoption(Selectedmets[0]);
      }
      var datatext = [{
        "value": "0xx",
        "label": "All"
      },
      {
        "value": "1xx",
        "label": "Informational links (1xx)"
      },
      {
        "value": "2xx",
        "label": "Success links (2xx)"
      },
      {
        "value": "3xx",
        "label": "Redirected links (3xx)"
      },
      {
        "value": "4xx",
        "label": "Client error links (4xx)"
      },
      {
        "value": "5xx",
        "label": " Server error links (5xx)"
      },
      {
        "value": "6xx",
        "label": "Valid links"
      },
      {
        "value": "7xx",
        "label": "Invalid links"
      },

      ]
      Setstatusdata(datatext)

    } catch (err) {
      console.log('err', err)
    }
  }
  useEffect(() => {
    FetchJobDates()
    LoadAddFormFilterOptions();
  }, [])


  useEffect(() => {


    if (esodata.PageredirectSlice.value == 1) {

      callUserdataApi("", "4xx", "");
    }
    else if (esodata.PageredirectSlice.value == 2) {

      callUserdataApi("", "5xx", "");
    }
    else
      callUserdataApi(selectval?.value, selectstatusdata.value,props.Domainsectiondata);
  }, [props.Domainsectiondata]);


  const [pieseriesData, SetpieseriesData] = useState([])
  const [Info, SetInfo] = useState(0)
  const [Success, SetSuccess] = useState(0)
  const [Redirect, SetRedirect] = useState(0)
  const [Client, SetClient] = useState(0)
  const [Server, SetServer] = useState(0)

  const pieseries = pieseriesData
  // const pieoptions = {
  //   chart: {
  //     width: 300,
  //     type: 'donut',
  //   },

  //   tooltip: {
  //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
  //       return "<div class='custom_pie_tool_tip'>" + w.config.labels[seriesIndex] + "</div>";
  //     }
  //   },

  //   responsive: [{
  //     breakpoint: 480,
  //     options: {
  //       chart: {
  //         width: '65%',
  //         height: '200%' // Adjust the width for mobile screens
  //       },
  //       legend: {
  //         show: true, // Optionally hide the legend for mobile
  //       },
  //       dataLabels: {
  //         enabled: false, // Disable data labels (percentage display)
  //       },
  //     },
  //   }],

  //   dataLabels: {
  //     enabled: false, // Disable percentage labels in the chart
  //   },

  //   plotOptions: {
  //     pie: {
  //       donut: {
  //         labels: {
  //           show: false, // Disable the central percentage label in the donut chart
  //         }
  //       }
  //     }
  //   },

  //   legend: {
  //     position: isMobile ? 'bottom' : 'right',
  //     height: 200,
  //   },

  //   labels: ['Information Links- (1xx) - ' + Info + "%", 'Successful Links- (2xx) - ' + Success + "%", 'Redirected Live Internal Links- (3xx) -' + Redirect + "%", 'Client Error Links- (4xx) -' + Client + "%", 'Server Error Links- (5xx) - ' + Server + "%"]

  // };

  const pieoptions = {
    chart: {
      type: "donut",
      height: 350,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return "<div class='custom_pie_tool_tip'>" + w.config.labels[seriesIndex] + "</div>";
      }
    },
    labels: ['Information Links- (1xx) - ' + Info + "%", 'Successful Links- (2xx) - ' + Success + "%", 'Redirected Live Internal Links- (3xx) -' + Redirect + "%", 'Client Error Links- (4xx) -' + Client + "%", 'Server Error Links- (5xx) - ' + Server + "%"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      position: isMobile || isTablet ? "bottom" : "right",  // Ensures the legend is always at the bottom
      horizontalAlign: "center",
      verticalAlign: "center"
    },
    dataLabels: {
      enabled: false, // Disables the data labels on the donut chart
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",  // Adjust this to control the thickness of the donut
        },
      },
    }
  };

  const SetSectionNameHandle = (passvalue) => {
    // SetDomainsectiondata(passvalue[0]?.value)
    // callUserdataApi("", "", passvalue[0]?.value);
    SetDomainsectiondata(props.Domainsectiondata);
    callUserdataApi("", "", props.Domainsectiondata);
  }
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        if (doaminsectiondatavalue.data.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetDomainsectiondata(Sections[0].value)
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }

  // Define the chart dimensions based on screen size
  const chartSize = {
    width: isMobile ? 200 : isTablet ? 300 : 400,
    height: isMobile ? 200 : isTablet ? 300 : 400,
  };


  console.log("selectval", selectval, "dummypiece", selectstatusdata)

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <>
      {
        loading === true ? <Loader /> : null
      }
      {/* {SectionOptions?.length > 0 &&
        <div className="listdropdown_drop">
          <div>
            <span className="listdropdown_titl">Domain Sectionnn</span>

            <Select
              className="drop-organicsearch"
              name="name"
              onChange={SetSectionNameHandle}
              options={SectionOptions}
              isSearchable="true"
              placeholder="Home Page"
            />

          </div>
        </div>
      } */}
      {console.log("pieoptions ", pieoptions)}
      <div style={{ padding: '0px' }} className="doughnut_intern_link">

        {/* <div className="internal_link_checker_pie"> */}

        {/* <div className="row pie_chart_row">
          <ReactApexChart className="site_regress_chart_info" style={{ width: isMobile ? 'auto' : '45%', justifyItem: "center" }} options={pieoptions} series={pieseries} type="donut" />
        </div> */}

        <div className="pie-chart-outer" style={{ width: "45%" }}>
          {/* <div className="site_Chart_info_inner" > */}
          <div className="pie-chart-inner">
            <ApexCharts options={pieoptions} series={pieseries} type="donut" height={400} />
          </div>
          {/* </div> */}
        </div>

        {/* </div> */}

      </div>
      <div className="data_table internal_link_checker_table" style={{ width: "100%" }}>
        <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
          <DataGrid
            getRowId={(row) => row.sid}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar,
              noResultsOverlay: MyCustomNoRowsOverlay,
              footer: () => (
                <Box className="table-footer-container">
                  <Box className="table-footer-pagination">
                    <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                  </Box>
                  <MSelect
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    size="small"
                    className="table-footer-select"
                  >
                    <MenuItem value={10}>10 per page</MenuItem>
                    <MenuItem value={30}>30 per page</MenuItem>
                    <MenuItem value={50}>50 per page</MenuItem>
                    <MenuItem value={75}>75 per page</MenuItem>
                    <MenuItem value={100}>100 per page</MenuItem>
                  </MSelect>
                </Box>
              ),
            }}
            slotProps={{
              toolbar: {

                showQuickFilter: true,

              },
            }}
            rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
            columns={columns}
            pagination
            // pageSizeOptions={[5, 10, 20]}
            disableVirtualization

            getRowHeight={() => "auto"}
            autoHeight={autoheightval ? true : false}
            // initialState={{
            //   pagination: {
            //     paginationModel: {
            //       pageSize: 10,
            //     },
            //   },
            // }}
            // pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
          // loading={loading}
          />
        </div>
      </div>
      {/* <Dialog
        open={props.modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Choose The Date"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div className="col">
                <div>
                  <span>Completed Date List</span>
                  <Select
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => setSelectval(values)}
                  />
                </div>
              </div>

              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={reportNow}>
            Submit
          </button>
          <button onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog> */}


      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    {/* <Select
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => setSelectval(values)}
                  /> */}
                    <Select
                      value={dEngineoption}
                      placeholder="Select Date"
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        setSelectval(values)
                        props.setJobIdvalue(values?.value)
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>
                <div className="">
                  <div>
                    <span>Status Code</span>
                    <Select
                      value={selectedstatus}
                      placeholder="Select Status Code"
                      options={statusdata}
                      labelField="dataval"
                      valueField="id"
                      isSearchable="true"
                      searchBy="dataval"
                      onChange={(values) => {
                        // setSelectval(values);
                        Setselectedstatus(values)
                        Setselectstatusdata(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={FetchCompetitorList} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* <div class="item_bx_wrap_days"> <Link to={detailurl}><p>View Report</p></Link></div> */}
    </>
  );
}
