import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import Internallinkchecker from "./Internallinkchecker";
import { Outlet, Link } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import Select from 'react-select';

export default function Linkchecklists() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectstatusval, setSelectstatusval] = useState("");
  const [Domainsectiondata, SetDomainsectiondata] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [internalLinkPlan, setinternalLinkPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [jobIdvalue, setJobIdvalue] = React.useState("");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);


  const navigate = useNavigate();
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <img src="/no-items-found.jpg" alt="no-item" />
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  const checkUserPlan = async () => {
    const internalLinkP = await Utils("internal_links", ApiToken);
    if (internalLinkP.status) {
      callUserdataApi();
      faqdatafun();
      LoadAddFormFilterOptions();
    }
    setinternalLinkPlan(internalLinkP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, [selectstatusval]);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domaindata`;
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
        if (doaminsectiondatavalue.data.domain_sections) {
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          // SetDomainsectiondata(Sections[0].value)
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }

  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'The index of the rows',

    },
    {
      field: "url",
      headerName: "URL",
      width: 400,
      disableClickEventBubbling: true,
      headerAlign: 'center',
      align: 'center',
      // renderCell: (cardData) => {
      //   return moment(cardData.row.date_of_request).format("MMM DD,YYYY ");
      // },
    },
    {
      field: "status_code",
      headerName: "Status Code",
      width: 200,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: "is_valid",
      headerName: "Valid/Invalid",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: "",
      headerName: "Source",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (cardData) => {
        return <button className="buttonView">
          <a href="">
            Source
          </a>
        </button>
      },
    },
    {
      field: "",
      headerName: "Outbound",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (cardData) => {
        return <button className="buttonView">
          <a href="">
            Source
          </a>
        </button>
      },
    },

  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/v2/internal_links_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}
        `,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (jobidData == "") {
        const completevalues = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=plagiarism&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        setcompletedate(completevalues?.data?.jobs);
      }
      const dataval = datavalue?.data?.result;
      console.log("kannanlink", datavalue?.data?.job_date)
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
      if (dataval && dataval.length > 0) {
        // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

        setCardData(dataval);
      }

      setLoading(false);
    } catch (err) {
      console.log('err', err)
    }

  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    if (internalLinkPlan.status) {
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('link_checker')
  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/internal_links_table/?user_domain_id=${esodata.domaindata.value.header_selected}&status_code_range=${selectstatusval}&domain_section_id=${Domainsectiondata}&job_id=${jobIdvalue}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Internalchecklist", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {

      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }

    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/v2/internal_links_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${Domainsectiondata}&status_code_range=${selectstatusval}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Internalchecklist", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();


        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'urlcheck')
      || (event.target.classList[0] === 'link_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitle')
      || (event.target.classList[0] === 'cols') || (event.target.classList[0] === 'card_num_class')
      || (event.target.classList[0] === 'content_col') || (event.target.classList[0] === 'link_rn_rpt_btn')
      || (event.target.classList[0] === 'css-b5h07o') || (event.target.classList[0] === 'site_regress_chart_info')
      || (event.target.classList[0] === 'linechartdesign') || (event.target.classList[0] === 'apexcharts-legend-series')
    ) {
      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=22`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue?.value)
  }


  return (
    <>
      {loading === true ? <Loader /> : null}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Link Checker</title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        <div className="cnt_audit_outer_wrap">

          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Internal Link Checker</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {internalLinkPlan.status &&
                <>
                  <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                  <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
                </>
              }
            </div>
            {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Internal Link Checker</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={openModal} />
                </span>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div title="Domain Section">
                {internalLinkPlan.status &&
                  <Select
                    options={SectionOptions}
                    name="name"
                    onChange={SetSectionNameHandle}
                    placeholder="Domain Section"
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                    classNamePrefix="common-domain-dropdown"
                  />
                }
              </div>
              <button
                title="Run Report"
                className="runreportnew over_all_accessibility runreportnew"
                onClick={() => {
                  if (first.statusDomain) {
                    setcheckD(first.statusDomain);
                  } else {
                    SetRunReportPopShow(true)
                  }
                }}

                disabled={!internalLinkPlan.status}
              >
                Run Report Now
              </button>
            </div>
          </div>


          {/* <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Internal Link Checker</h2>
              <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

            </div>
            <div className="cnt_hdr_top_rgt_wrap int-link-check">
              <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                  <QuestionMarkIcon />

                </button>
                {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}
                {internalLinkPlan.status &&
                  <div>
                    <img src={pdficon} alt="pdf icon" onClick={isDownloading ? null : pdfdown} />
                    <img src={csvicon} alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} />
                  </div>
                }
              </div>

            </div>
          </div>
          <div className="cnt_hdr_blw_wrap">
            <button className="runreportnew" onClick={() => { SetRunReportPopShow(true) }} disabled={!internalLinkPlan.status}>
              Run Report Now
            </button>
            <button className="run_rpt_btn ch_date_btn" onClick={openModal} disabled={!internalLinkPlan.status}>
              Filter By
              <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" />
              </span>
            </button>
          </div> */}

          <div className="link_cnt_hdr_blw_wrap">

            <Link to="/internal-link-checker"> <button className="runreportnews  active">
              Internal Link Checker
            </button></Link>

            <Link to="/external-link-checker"> <button className="runreportnews ">
              External Link Checker
            </button></Link>

          </div>


          <div className="site_blw_wrapss domain_link_pagea">
            {/* <div className="cnt_hdr_blw_wrap">
              <button className="runreportnew" onClick={() => { SetRunReportPopShow(true) }} disabled={!internalLinkPlan.status}>
                Run Report Now
              </button>
              <button className="run_rpt_btn ch_date_btn" onClick={openModal} disabled={!internalLinkPlan.status}>
                Filter By
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" />
                </span>
              </button>
            </div>
            {internalLinkPlan.status &&
              <div className="domain-dropdown-section">
                <div title="Domain Section">
                  <span className="listdropdown_titl">Domain Section</span>
                  <Select
                    options={SectionOptions}
                    name="name"
                    onChange={SetSectionNameHandle}
                    placeholder="Domain Section"
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                    classNamePrefix="common-domain-dropdown"
                  />
                </div>
              </div>
            } */}
          </div>




          {internalLinkPlan.status ?
            <Internallinkchecker modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} setjobDate={setjobDate} jobIdvalue={jobIdvalue} setJobIdvalue={setJobIdvalue} selectstatusval={selectstatusval} setSelectstatusval={setSelectstatusval} Domainsectiondata={Domainsectiondata} /> : <Freeaudit planStatus={internalLinkPlan} />
          }

        </div>
        <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
      </div>

      {!openCommonAlerts && <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}

      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      <Dialog
        open={checkD}
        // onClose={() => setOpenCommonAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please verify your domain to access these features
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
