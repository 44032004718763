import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExternalLinkIcon from "../../../Icons/external-link.png";
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';


export default function BacklinksReport(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);


  const navigate = useNavigate()

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];



  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;


  const NavigateLink = (e) => {

    const Link = e.target.getAttribute('data-link')

    window.open(Link, '_blank')

  }
  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "referring_page",
      headerName: "Referring Page/Title",
      minWidth: 600,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "Referring page's URL or referring page's title.",
      renderCell: (cardData) => {
         let firstEightLetters = cardData?.row?.prefered_url;
        // let whoedata = firstEightLetters;

        let baseUrl = cardData?.row?.referring_page.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = cardData?.row?.referring_page.split("/").slice(3).join("/");

        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData?.row?.title}</span>
            <br />

            <Tooltip title={cardData.row.referring_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.referring_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>

            {/* <span className="table_links" onClick={NavigateLink}>{cardData.row.referring_page}<img data-link={cardData.row.referring_page} className="external_link_icon" src={ExternalLinkIcon} /></span> */}
          </div>
        );
      },
    },

    {
      field: "da",
      headerName: "DA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "Domain InLink Rank of the target's root domain.",
    },
    {
      field: "pa",
      headerName: "PA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: "InLink rank of the target URL.",
    },
    {
      field: "anchor",
      headerName: "Anchor/Target Page",
      minWidth: 350,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Link anchor text for text links/Destination page's URL.",
      renderCell: (cardData) => {
        let firstEightLetters = cardData?.row?.prefered_url;
        // let whoedata = firstEightLetters;

        let baseUrl = cardData?.row?.target_page.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = cardData?.row?.target_page.split("/").slice(3).join("/");
        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData.row.anchor}</span>
            <br />
            {/* <span className="table_links" onClick={NavigateLink}>{cardData.row.target_page}<img data-link={cardData.row.target_page} className="external_link_icon" src={ExternalLinkIcon} /></span> */}
            <Tooltip title={cardData.row.target_page}>
              <div className="prefered_url_external02">
                <a target="_blank" href={cardData?.row?.target_page} style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>
            </Tooltip>
          
          </div>
        );
      },
    },
    {
      field: "link_type",
      headerName: "Link Type",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'Type of backlink.',
    },
    {
      field: "nofollow",
      headerName: "Follow/No Follow",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'Number of backlinks marked as nofollow and number of backlinks not marked as nofollow.',
    },
    {
      field: "first_seen",
      headerName: "First Seen",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      description: 'The date when the backlink was added to the index or the date when our crawlers last visited the referring page.',
    },
    {
      field: "last_visited",
      headerName: "Last Visited",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "The date when the backlink was added to the index or the date when our crawlers last visited the referring page.",
    },

  ];

  const rows = cardData ?? [];






  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }







  const ColorObj = [{

    borderColor: "orange",
    backgroundColor: "rgb(255, 235, 219)",
    fill: {
      target: "origin", // Set the fill options
      above: "rgba(255, 235, 219, 0.3)"
    }

  }]


  const LoadData = async (tableid = '') => {
    var JobId = '';
    if (props.RankJob) {
      if (props.RankJob.value) {
        JobId = props.RankJob.value
      }
    }
    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_report/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        props.setjobDate(moment(datavalue.data.job_date).format("MMMM DD,YYYY HH:mm"));
      }

      if (datavalue?.data?.report) {
        // {
        //     SetDomainAuthority(datavalue.data.research_data.domain_authority)
        //     SetTotalBacklink(datavalue.data.research_data.total_backlinks_count)
        //     SetTotalFollowLink(datavalue.data.research_data.total_follow_links)  
        //     SetTotalReferringDomain(datavalue.data.research_data.total_ref_domains)
        //     SetResearchType(datavalue.data.research_data.research_mode)
        //     SetResearchUrl(datavalue.data.research_data.research_url)
        //     SetRefilldate(datavalue.data.research_data.next_refill_date)
        //     SetTotalLimit(datavalue.data.research_data.total_research_limit)
        //     SetAvailableLimit(datavalue.data.research_data.available_research_count)
        // }            
        var TableArray = [];
        TableArray = datavalue.data.report;

        if (TableArray && TableArray.length > 0) {
          const newData = TableArray.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          setCardData(newData);
          SetAutoheightval(false)
        }
        else {
          setCardData("");
          SetAutoheightval(true)
        }
      }

    } catch (err) {


      console.log(err)
    }

    setLoading(false)

  }



  // const AnalyzeReport=async()=>{

  //   setLoading(true)
  //   SetErrorMsg('')

  //   if(AnalyzeUrl==='')
  //   {
  //     SetErrorMsg('Please enter a url')
  //     setLoading(false)
  //     return false
  //   }

  //   SetDomainAuthority('0')
  //   SetTotalBacklink('')
  //   SetTotalFollowLink('') 
  //   SetTotalReferringDomain('')
  //   SetResearchType('')
  //   SetResearchUrl('')
  //   SetRefilldate('')
  //   SetTotalLimit('')
  //   SetAvailableLimit('')
  //   setCardData([])
  //   try{



  //       const datavalue = await axios.get(
  //           `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_report/?user_domain_id=${esodata.domaindata.value.header_selected}`,
  //           {
  //             headers: {
  //               Authorization: `${ApiToken}`,
  //             },
  //           }
  //         );



  //         if(datavalue.data)
  //         {

  //           if(datavalue.data.research_graph)
  //           {
  //               SetDomainAuthority(datavalue.data.research_graph.domain_authority)
  //               SetTotalBacklink(datavalue.data.research_graph.total_backlinks_count)
  //               SetTotalFollowLink(datavalue.data.research_graph.total_follow_links)  
  //               SetTotalReferringDomain(datavalue.data.research_graph.total_ref_domains)
  //               SetResearchType(datavalue.data.research_graph.research_mode)
  //               SetResearchUrl(datavalue.data.research_graph.research_url)
  //               SetRefilldate(datavalue.data.research_graph.next_refill_date)
  //               SetTotalLimit(datavalue.data.research_graph.total_research_limit)
  //               SetAvailableLimit(datavalue.data.research_graph.available_research_count)
  //           }

  //           if(datavalue.data.research_table)
  //           {
  //               console.log('dsssf',datavalue.data.research_table.table)

  //               const FDataval=datavalue.data.research_table.table

  //               if(FDataval)
  //               {
  //                 const newData = FDataval.map((obj, index) => {
  //                   return { ...obj, sid: index+1 };
  //                 });


  //                 setCardData(newData);
  //               } 


  //           }



  //         }

  //         if(datavalue.data.error)
  //         {
  //           SetErrorMsg(datavalue.data.error)
  //         }



  //   }catch(err)
  //   {
  //       console.log('errr',err)
  //   }

  //   setLoading(false)

  // }



  useEffect(() => {
    LoadData()
  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {
      LoadData()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])


  function generateRandom() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Report</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* {loading === true ? <Loader /> : null} */}


      <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
        <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
          <DataGrid
            getRowId={(row) => generateRandom()}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              noResultsOverlay: MyCustomNoRowsOverlay,
              footer: () => (
                <Box className="table-footer-container">
                  <Box className="table-footer-pagination">
                    <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                  </Box>
                  <MSelect
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    size="small"
                    className="table-footer-select"
                  >
                    <MenuItem value={10}>10 per page</MenuItem>
                    <MenuItem value={30}>30 per page</MenuItem>
                    <MenuItem value={50}>50 per page</MenuItem>
                    <MenuItem value={75}>75 per page</MenuItem>
                    <MenuItem value={100}>100 per page</MenuItem>
                  </MSelect>
                </Box>
              ),
            }}
            rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
            columns={columns}
            pagination
            // pageSizeOptions={[5, 10, 20]}
            disableVirtualization
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            autoHeight={autoheightval ? true : false}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 10,
          //     },
          //   },
          // }}
          // pageSizeOptions={[10, 30, 50, 75, 100]}
          // loading={loading}
          />
        </div>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {!openCommonAlerts && <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              borderRadius: "18px !important",
              borderColor: "#ff601f !important",
              top: "10px !important",
              // width: '100%',
              // boxShadow:"0px 15px 10px 0px #fff !important"
            },
          }}
        >
          <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>}

      </div>

    </>
  );
}
