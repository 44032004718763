import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import axios from "axios";
import "./linkcheckerstyle.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { ChartContainer, BarPlot, ChartsTooltip } from "@mui/x-charts";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LinkIcon from '@mui/icons-material/Link';
import GooglePng from "../../../Icons/google.png";
import BingPng from "../../../Icons/bing.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import SquareIcon from '@mui/icons-material/Square';
import { Tooltip } from "@mui/material";
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import CommonAlert from "../../../Commonalert/commonalert";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import testIcon from "../Asset/ESO/testingIcon.svg";
import visibilityicon1 from "../Asset/ESO/visibility1.png";
import visibilityicon from "../Asset/ESO/visibility.png";
import searchEngine from "../Asset/ESO/search-engine.svg";
import First_Page_Ranked_keywordssvg from "../Asset/esocardicon/RankingSummary/First_Page_Ranked_keywords.svg"
import Ranked_Keywordssvg from "../Asset/esocardicon/RankingSummary/Ranked_Keywords.svg"
import Total_keywordssvg from "../Asset/esocardicon/RankingSummary/Total_keywords.svg"
import Visibility_Scoresvg from "../Asset/esocardicon/RankingSummary/Visibility_Score.svg"


export default function Ranklinksummary(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate()
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [activeVisibilityIcon, setActiveVisibilityIcon] = useState(visibilityicon);

  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);


  let querystring = useParams();
  let idval = querystring.idval;

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;

  const columns = [

    {
      field: "sid",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "keyword",
      headerName: "Keywords",
      description: 'The list of keywords is provided in campaign settings.',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "searchvolume",
      headerName: "SV",
      description: "A keyword's total search volume in search results.",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "sv_trend_chart_data",
      headerName: "Sv Trend",
      description: "The last 12 months' search volume is shown visually as a simple small bar graph for the keyword. ",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var sr = cardData.row.sv_trend_chart_data;
        if (sr != null && Array.isArray(sr) && sr.length > 0) {
          var monthval = sr.map((item) => {
            const monthName = new Date(item.year, item.month - 1).toLocaleString("en-US", { month: "long" });
            return `${monthName} ${item.year}`;
          });
          var chartdataval = sr.map((fruit) => fruit.search_volume);

          return (
            <ChartContainer
              width={200}
              height={150}
              series={[{ data: chartdataval, label: "Search Volume", type: "bar" }]}
              xAxis={[{ scaleType: "band", data: monthval }]}
            // interaction={{ axis: "both" }}
            >
              <BarPlot />
              <ChartsTooltip />
            </ChartContainer>
          );
        }
      },
    },
    {
      field: "difficulty",
      headerName: "Diff",
      description: "The value-based display of keyword difficulty for the respective keyword",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "search_engine",
      headerName: "Search Engine",
      description: "List of search engines configured in the campaign settings.",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {


        if (cardData.row.search_engine === 'Bing') {

          return (

            <div className="search_engine_row">
              <img src={BingPng} />{cardData.row.search_engine}

            </div>

          )

        }
        else if (cardData.row.search_engine === 'Google') {

          return (

            <div className="search_engine_row">
              <img src={GooglePng} />{cardData.row.search_engine}
            </div>

          )

        }
        else {

          return (


            cardData.row.search_engine


          )

        }

      }


    },
    {
      field: "country",
      headerName: "Location",
      description: "The list of locations is configured in the campaign settings.",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        return (

          <>
            <div> {cardData.row.country} {cardData.row.state ? `,` : ""}<br />
              {cardData.row.state}{cardData.row.city ? `,` : ""}<br />
              {cardData.row.city ? `${cardData.row.city}` : ""}</div>
          </>

        )

      }
    },
    {
      field: "prefered_url",
      headerName: "Pref.URL",
      description: "URL for which the keyword is ranked. The preferred keyword is set up along with the keyword setup in campaign settings.",
      minWidth: 320,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        var firstEightLetters = "";
        var lstThreeLetters = "";
        var wholedata = "";
        // if (cardData.row.prefered_url.length > 20) {
        //   firstEightLetters = cardData.row.prefered_url.substring(0, 20);
        //   lstThreeLetters = cardData.row.prefered_url.slice(-5);
        //   wholedata = firstEightLetters + '...' + lstThreeLetters;
        // }
        // else {
          firstEightLetters = cardData.row.prefered_url;
          wholedata = firstEightLetters;

          let baseUrl = cardData.row.prefered_url.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = cardData.row.prefered_url.split("/").slice(3).join("/");

        // }
        return (

          <>
            <Tooltip title={cardData.row.prefered_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.prefered_url} style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="spanlink">
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }} >{remaining}</span>
                </a>
              </div>

            </Tooltip>
          </>


        )

      }
    },
    {
      field: "prefered_url_position",
      headerName: "POS",
      description: "The position is the ranking of the keyword it has obtained in a particular search engine. ",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {




        if (cardData.row.prefered_url_position.change === 'Dropped') {

          return (

            <div className="gain_drop_row" style={{ color: '#ffa6a6' }}>
              <ArrowDropDownIcon />
              <span>{cardData.row.prefered_url_position.value}</span>
            </div>

          )

        }

        if (cardData.row.prefered_url_position.change === 'Gained') {

          return (

            <div className="gain_drop_row" style={{ color: '#00ab00' }}>
              <ArrowDropUpSharpIcon />
              <span>{cardData.row.prefered_url_position.value}</span>
            </div>

          )

        }

        if (cardData.row.prefered_url_position.change === 'No Change') {

          return (

            <div className="gain_drop_row no_change_row" style={{ color: 'orange' }}>
              <SquareIcon />

              <span>{cardData.row.prefered_url_position.value}</span>
            </div>

          )

        }







      }




    },
    {
      field: "prev_pref_pos",
      headerName: "Prev.POS",
      description: "The position of the keyword in the previous crawl",
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "View",
      headerName: "View",

      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: "hide_option",
      renderCell: (cardData) => {
        return (
          <a href={`${process.env.REACT_APP_DOMAIN}ranking-summary/detail/${cardData.row.id}/${props.RankJob}`} >
            <button className="view-report-button">
              View Report
              {/* <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              /> */}
            </button>
            {/* <img
              src={fav_icon}
              className="view_td_img"
              alt="view report icon"
            /> */}
          </a>
        );
      },
    },

  ];

  const rows = cardData ?? [];






  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


  const [total_keyword_count, Settotal_keyword_count] = useState(0)
  const [ranked_keyword_count, Setranked_keyword_count] = useState(0)
  const [first_ranked_keyword_count, Setfirst_ranked_keyword_count] = useState(0)
  const [visibility_count, Setvisibility_count] = useState(0)

  const [RankSummaryIntab, SetRankSummaryIntab] = useState(null)

  const SwitchRankIntab = (Id) => {
    // e.classList.toggle("active");


    // const Id = e.target.closest(".cardactivecontainer").getAttribute("data-id");
    if (Id === 'visibility_score') {


      navigate(`/visibility-score-detail?system=${props.RankSystem}&campaign_id=${props.RankCampaign}&search_engine_id=${props.RankEngine}&country_id=${props.RankCountry}&state_id=${props.RankState}&city_id=${props.RankCity}&job_id=${props.RankJob}&devices=${props.RankDevice}`)
    }
    else {
      SetRankSummaryIntab(Id)
      LoadData(Id)

    }



  }


  const LoadData = async (tableid = '') => {

    setLoading(true)
    props.SetRankTypeval(tableid)
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_kpi/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${props.RankJob}&rank_section=rank_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (datavalue?.data) {
        Settotal_keyword_count(datavalue.data.total_keyword_count)
        Setranked_keyword_count(datavalue.data.ranked_keyword_count)
        Setfirst_ranked_keyword_count(datavalue.data.first_page_keyword_count)
        Setvisibility_count(datavalue.data.visibility_score + '%')

      }

    } catch (err) {

      Settotal_keyword_count(0)
      Setranked_keyword_count(0)
      Setfirst_ranked_keyword_count(0)
      Setvisibility_count(0 + '%')
      console.log(err)
    }

    props.setjobDate('')

    try {
      const datavalueTable = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata.domaindata.value.header_selected}&system=${props.RankSystem}&campaign_id=${props.RankCampaign}&search_engine_id=${props.RankEngine}&country_id=${props.RankCountry}&state_id=${props.RankState}&city_id=${props.RankCity}&job_id=${props.RankJob}&rank_type=${tableid}&devices=${props.RankDevice}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      const dataval = datavalueTable?.data?.result;
      console.log("datavalwwe", datavalueTable?.data)
      // props.setjobDate(moment(datavalueTable?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      if (datavalueTable?.data?.job_date)
        props.setjobDate(moment(datavalueTable?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        props.setjobDate(moment(datavalueTable?.data?.job_date).format("MMMM DD,YYYY"))
      if (dataval) {
        console.log("datavalr4e", dataval.length)
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        SetAutoheightval(false)
        setCardData(newData);
        if (dataval.length <= 0) {
          SetAutoheightval(true)
          setCardData("");
        }
        props.SetRankJob(datavalueTable?.data?.job_id)
      }
      else {
        console.log("hiii")
        SetAutoheightval(true)
        setCardData("");
        // props.setjobDate("")
      }
    } catch (err) {
      setCardData([])
      console.log("hiii43")
      toast.error(err?.response?.data?.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setLoading(false)

  }




  useEffect(() => {
    if (idval == 2) {
      SetRankSummaryIntab("ranked_keywords")
      LoadData("ranked_keywords")
    }
    else {
      idval = ""
      LoadData()
    }

  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {
      idval = "";
      LoadData(RankSummaryIntab)
      props.SetFilterButtonTriggered(false)
    }

  }, [props])


  const handleRowClick = (e) => {



    navigate('/ranking-summary/detail/' + e.row.id + '')


  }

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer style={{ display: "flex", justifyContent: "space-between", padding: "8px", alignItems: "end" }}>
        {/* Custom Left Side Content */}
        <div className="missed_below_note_ranking">
        <div className="below_note_ranking">
          *Note  :   N/A is Not Available

        </div>
        <div className="below_note_ranking icon">
          <div className="green">
            <ArrowDropUpSharpIcon />-  Gained
          </div>

          <div className="rose">
            <ArrowDropDownIcon /> -   Dropped
          </div>

          <div className="orange">
            <SquareIcon />-  No Change
          </div>


        </div>
      </div>
        {/* Default Search Box (Quick Filter) */}
        <GridToolbarQuickFilter
          className="datagrid-search"
        />
      </GridToolbarContainer>
    );
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* {loading === true ? <Loader /> : null} */}

      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Rank Checker</title>
        </Helmet>
        <div className="common-card-container">

          <div
            className={`common-card-wrapper-style cardactivecontainer ${RankSummaryIntab === "" ? "active" : ""}`}
            data-id=""
            onClick={() => SwitchRankIntab("")}
          >
            <div className={`common-card-icon-container ${RankSummaryIntab === "" ? "active" : ""}`}>
              <div className={`common-card-icons`}>
                <img src={Total_keywordssvg} className={`common-card-icon-img ${RankSummaryIntab === "" ? "active" : ""}`} alt="Total Keywords" />
              </div>
            </div>
            <div className={`common-card-subtitle ${RankSummaryIntab === "" ? "active" : ""} cardcontentactivecontainer`}>
              <span className="common-card-title">Total Keywords</span>
              <span className="common-card-value">{total_keyword_count}</span>
            </div>
          </div>

          <div
            className={`common-card-wrapper-style cardactivecontainer ${RankSummaryIntab === "ranked_keywords" ? "active" : ""}`}
            data-id="ranked_keywords"
            onClick={() => SwitchRankIntab("ranked_keywords")}
          >
            <div className={`common-card-icon-container ${RankSummaryIntab === "ranked_keywords" ? "active" : ""}`}>
              <div className={`common-card-icons ${RankSummaryIntab === "ranked_keywords" ? "active" : ""}`}>
                <img src={Ranked_Keywordssvg} className={`common-card-icon-img ${RankSummaryIntab === "" ? "active" : ""}`} alt="Ranked Keywords" />
              </div>
            </div>
            <div className={`common-card-subtitle ${RankSummaryIntab === "ranked_keywords" ? "active" : ""} cardcontentactivecontainer`}>
              <span className="common-card-title">Ranked Keywords</span>
              <span className="common-card-value">{ranked_keyword_count}</span>
            </div>
          </div>

          <div
            className={`common-card-wrapper-style cardactivecontainer ${RankSummaryIntab === "first_page_rank" ? "active" : ""}`}
            data-id="first_page_rank"
            onClick={() => SwitchRankIntab("first_page_rank")}
          >
            <div className={`common-card-icon-container ${RankSummaryIntab === "first_page_rank" ? "active" : ""}`}>
              <div className={`common-card-icons ${RankSummaryIntab === "first_page_rank" ? "active" : ""}`}>
                <img src={First_Page_Ranked_keywordssvg} className="common-card-icon-img" alt="First Page Ranked Keywords" />
              </div>
            </div>
            <div className={`common-card-subtitle ${RankSummaryIntab === "first_page_rank" ? "active" : ""} cardcontentactivecontainer`}>
              <span className="common-card-title">First Page Ranked Keywords</span>
              <span className="common-card-value">{first_ranked_keyword_count}</span>
            </div>
          </div>

          <div
            className={`common-card-wrapper-style cardactivecontainer ${RankSummaryIntab === "visibility_score" ? "active" : ""}`}
            data-id="visibility_score"
            onClick={() => SwitchRankIntab("visibility_score")}
          >
            <div className={`common-card-icon-container ${RankSummaryIntab === "visibility_score" ? "active" : ""}`}>
              <div className={`common-card-icons ${RankSummaryIntab === "visibility_score" ? "active" : ""}`}>
                <img src={Visibility_Scoresvg} className="common-card-icon-img" alt="Visibility Score" />
              </div>
            </div>
            <div className={`common-card-subtitle ${RankSummaryIntab === "visibility_score" ? "active" : ""} cardcontentactivecontainer`}>
              <span className="common-card-title">Visibility Score</span>
              <span className="common-card-value">{visibility_count}</span>
            </div>
          </div>

        </div>
      </div>


      <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
        <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
          <DataGrid
            // onRowClick={handleRowClick}
            slots={{

              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: CustomToolbar,
              noResultsOverlay: MyCustomNoRowsOverlay,
              footer: () => (
                <Box className="table-footer-container">
                  <Box className="table-footer-pagination">
                    <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                  </Box>
                  <MSelect
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    size="small"
                    className="table-footer-select"
                  >
                    <MenuItem value={10}>10 per page</MenuItem>
                    <MenuItem value={30}>30 per page</MenuItem>
                    <MenuItem value={50}>50 per page</MenuItem>
                    <MenuItem value={75}>75 per page</MenuItem>
                    <MenuItem value={100}>100 per page</MenuItem>
                  </MSelect>
                </Box>
              ),
            }}
            slotProps={{
              toolbar: {

                showQuickFilter: true,

              },
            }}
            rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
            columns={columns}

            gridRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            autoHeight={autoheightval ? true : false}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableExportSelector
            disableRowSelectionOnClick
          />
        </div>
      </div>

      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
          },
        }}

      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
    </>
  );
}
