import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";

import axios from "axios";
import "./Siteauditstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { timezonechange } from "./../Commonfun";
import { Utils } from "../../../Utils/Utils";
import plagiarismscanreport_total_wordson_pagesvg from "../Asset/esocardicon/plagiarism/plagiarismscanreport_total_wordson_page.svg"
import plagiarism_totalmatching_urlsvg from "../Asset/esocardicon/plagiarism/plagiarism_scan_report _total_matching_word.svg"
import plagiarismreport_percentage_matchingsvg from "../Asset/esocardicon/plagiarism/plagiarismreport_percentage_matching.svg"
export default function Pagehealthdetail() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  const [queryurl, setQueryurl] = useState("");
  const [value, setValue] = useState("1");
  const [rescardData, setRescardData] = useState([]);
  const [jobDate, setjobDate] = useState("");
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [pagehealthPlan, setpagehealthPlan] = useState({ status: true, error: "" });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const ApiToken = esodata.apiauthkey.value
  let querystring = useParams();
  let plag_id = querystring.id;
  let onpageid = querystring.onpageid;
  const apihandleClose = () => {
    setapiOpen(false);
  };
  // const MyCustomNoRowsOverlay = () => (
  //   <img src="/no-items-found.jpg" alt="no-item" />
  // );
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>


  );
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-scan-detail/`;

  useEffect(() => {
    checkUserPlan();
  }, []);

  const checkUserPlan = async () => {
    const pagehealthP = await Utils("page_health", ApiToken);
    if (pagehealthP.status) {
      callUserdataApi();
    }
    setpagehealthPlan(pagehealthP);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}technical/pagehealth/detail/${plag_id}/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}&job_id=${onpageid}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK") {
        navigate('/errorpages')
      }
    });
    const dataval = datavalue?.data;
    setQueryurl(dataval?.url);
    setjobDate(moment(dataval?.job_date).format("MMM DD,YYYY h:mm"));
    setCardData(dataval);
    setSitecardData(dataval?.page_metrics_list);
    setInternalcardData(dataval?.internal_link_list);
    if (dataval?.seo_metrics_list.length > 0) {
      setRescardData(dataval?.seo_metrics_list)
      SetAutoheightval(false)
    }
    else {
      setRescardData("")
      SetAutoheightval(true)
    }
    setLoading(false);
  };

  const rows = rescardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];

  const SeoMetricsArray = [

    {
      key: 'no_index_robot',
      value: 'The web pages must be accessible to Search engines for better SEO results. This metric will check if the web page is accessible to search engines'
    },
    {
      key: 'missing_canonical',
      value: 'Canonical URLs inform search engines that a specific URL represents the source of the content on a web page. This metric will check for a canonical URL or tag on the web page.or tag on the web page'
    },
    {
      key: 'missing_titles',
      value: 'The title tag specifies the web page title. This metric will show the total number of webpages that have a missing title.'
    },
    {
      key: 'duplicate_h1_tags',
      value: 'H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have the same H1 tags.'
    },
    {
      key: 'missing_h1_tags',
      value: 'H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have missing H1 tags.'
    },
    {
      key: 'hreflang',
      value: "Hreflang is an HTML attribute used to specify a web page's language and geographic target. This metric displays the total count of webpages using the hreflang attribute."
    },
    {
      key: 'pagination_prev_or_next',
      value: "This metrics displays the count of Page URLs which have next and previous URLs"
    },
    {
      key: 'duplicate_titles',
      value: "The title tag specifies the web page title. This metric will show the total number of web pages with the same title."
    },
    {
      key: 'duplicate_descriptions',
      value: "The description tag specifies the description of the web page. This metric populates the total number of webpages that have the same description."
    },
    {
      key: 'missing_descriptions',
      value: "The description tag specifies the description of the web page. This metric populates the total number of web pages that have a missing description."
    },
    {
      key: 'missing_alt_tags',
      value: "The ALT tag describes the presence or function of an image on a web page. This metric will show the total count of images on a web page that have missing ALT tags."
    },
    {
      key: 'h1_tags_available',
      value: "H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have an H1 tag. will show the total number of webpages that have the same H1 tags. that have an H1 tag"
    },
    {
      key: 'images_available',
      value: "Images Available	This metric provides the total number of images available in the domain."
    },
    {
      key: 'server_error_5xx_link',
      value: "The 5xx status code means that the server failed to complete a request. This metric displays the count of web pages that return a 5xx status code."
    },
    {
      key: 'broken_links_4xx_link',
      value: "The 4xx status code means that the request for a web page or other resource contains bad syntax. This metric displays the count of web pages that return a 4xx status code."
    }


  ];

  function findObjectByKeyValue(array, key, value) {
    return array.find(obj => obj[key] === value);
  }


  const columns = [
    {
      field: "seo_metrics",
      headerName: "SEO Metrics",
      description: 'On-page SEO elements are present on the website.',
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        var ToolTipTitle = '';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key', cardData.row.seo_metrics);

        if (result !== undefined) {
          ToolTipTitle = result.value
        }



        if (ToolTipTitle != '') {
          return (
            <Tooltip title={ToolTipTitle}>
              <p>{cardData.row.seo_metrics}</p>
            </Tooltip>
          );
        }

        else {

          return (
            <p>{cardData.row.seo_metrics}</p>
          );

        }

      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrences",
      description: 'The total number of specific occurrences present on the website.',
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
  ];

  const column1 = [
    {
      field: "site_metrics",
      headerName: "Page Metrics",
      description: 'Website metrics are present on the website.',
      minWidth: 500,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        var ToolTipTitle = '';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key', cardData.row.site_metrics);

        if (result !== undefined) {
          ToolTipTitle = result.value
        }



        if (ToolTipTitle != '') {
          return (
            <Tooltip title={ToolTipTitle}>
              <p>{cardData.row.site_metrics}</p>
            </Tooltip>
          );
        }

        else {

          return (
            <p>{cardData.row.site_metrics}</p>
          );

        }

      },
    },

    {
      field: "occurences",
      headerName: "Occurrences",
      description: 'The total number of specific occurrences present on the website.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
  ];
  const column2 = [
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description: 'It displays server error links (5xx) and broken links (4xx) on the website.',
      minWidth: 500,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {

        var ToolTipTitle = '';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key', cardData.row.internal_links);

        if (result !== undefined) {
          ToolTipTitle = result.value
        }



        if (ToolTipTitle != '') {
          return (
            <Tooltip title={ToolTipTitle}>
              <p>{cardData.row.internal_links}</p>
            </Tooltip>
          );
        }

        else {

          return (
            <p>{cardData.row.internal_links}</p>
          );

        }

      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrences",
      description: 'The total number of specific occurrences present on the website.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
  ];
  var state = {
    value: totalpercecntwords,
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const textfillColor = "rgb(255,255,255)";
  const fillColor = "#ff601f"; // Set the base color for the circle
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/pagehealth/detail/${plag_id}/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("pageHealthdetail", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(true);
  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/pagehealth/detail/${plag_id}/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=csv&timezone=${timezoneval()}`, {

        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("pageHealthdetail", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();


        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(true);
  }

  const totalPages = Math.ceil(rows.length / rowsPerPage);
  const totalPages1 = Math.ceil(rows1.length / rowsPerPage);
  const totalPages2 = Math.ceil(rows2.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <div className={`over_all_container page-health ${isMenuExpanded ? "blur overflow-hidden" : ""}`} >
      <div className="cnt_audit_outer_wrap">
        {loading === true ? <Loader /> : null}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Page Health Detail </title>
        </Helmet>

        <div className="cnt_audit_outer_wrap">

          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Link to="/page-health/3/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Page Health</Typography>
              </Link>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>View Report</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              {pagehealthPlan.status &&
                <>
                  <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                  <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
                </>
              }
            </div>

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Page Health Checker Detailed Report  </h2> 
               <a target="_blank" href={queryurl} style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="spanlink" style={{flexDirection:"column"}}>
                {queryurl} 
               </span>
               </a>
                 <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <Link to={`/page-health-ignore-metrics/${plag_id}/${onpageid}`}>
                <button
                  className="runreportnew over_all_accessibility runreportnew"
                  style={{ border: "2px solid #fff" }}
                >
                  Ignored Metrics
                </button>
              </Link>

            </div>
          </div>

        </div>

        <div className="cnt_hdr_content">
          <div className="box_wrap page_health">
            <div>
              {/* <h1>Pagehealth Checker Report For</h1> */}
              <p></p>
            </div>
              <div className="common-card-container" style={{justifyContent: "center"}}>
                              <div className={`common-card-wrapper-style cardactivecontainer`}>
                                <div className={`common-card-icon-container`}>
                                  <div className={`common-card-icons`}>
                                    <img src={plagiarismscanreport_total_wordson_pagesvg} className={`common-card-icon-img`} alt=" Total Words on Pages" />
                                  </div>
                                </div>
                                <div className={`common-card-subtitle cardcontentactivecontainer`}>
                                  <span className="common-card-title">  High Impact Issue Occurrences -- {cardData?.high_impact_issue} </span>
                                  <span className="common-card-value">      
                                 <LiquidFillGauge
                                style={{ margin: "0 auto" }}
                                width={50}
                                height={50}
                                value={cardData?.high_impact_percentage}
                                percent="%"
                                textSize={1}
                                textOffsetX={0}
                                textOffsetY={0}
                                riseAnimation
                                waveAnimation
                                waveFrequency={2}
                                waveAmplitude={1}
                                gradient
                                gradientStops={gradientStops}
                                circleStyle={{
                                  fill: "#FFFFFF",
                                }}
                                waveStyle={{
                                  fill: "#FFFFFF",
                                }}
                                textStyle={{
                                  fill: color("#0b2033").toString(),
                                  fontFamily: "Arial",
                                }}
                                waveTextStyle={{
                                  fill: color("#0b2033").toString(),
                                  fontFamily: "Arial",
                                }}
                              />
                                    </span>
                                </div>
                              </div>
                              <div className={`common-card-wrapper-style cardactivecontainer`}>
                                <div className={`common-card-icon-container`}>
                                  <div className={`common-card-icons`}>
                                    <img src={plagiarism_totalmatching_urlsvg} className={`common-card-icon-img`} alt="  Total Words" />
                                  </div>
                                </div>
                                <div className={`common-card-subtitle cardcontentactivecontainer`}>
                                  <span className="common-card-title">     Medium Impact Issue Occurrences -- {cardData?.medium_impact_issue}</span>
                                  <span className="common-card-value">  
            
                                  <LiquidFillGauge
                                style={{ margin: "0 auto" }}
                                width={50}
                                height={50}
                                value={cardData?.medium_impact_percentage}
                                percent="%"
                                textSize={1}
                                textOffsetX={0}
                                textOffsetY={0}
                                riseAnimation
                                waveAnimation
                                waveFrequency={2}
                                waveAmplitude={1}
                                gradient
                                gradientStops={gradientStops}
                                circleStyle={{
                                  fill: "#FFFFFF",
                                }}
                                waveStyle={{
                                  fill: "#FFFFFF",
                                }}
                                textStyle={{
                                  fill: color("#0b2033").toString(),
                                  fontFamily: "Arial",
                                }}
                                waveTextStyle={{
                                  fill: color("#0b2033").toString(),
                                  fontFamily: "Arial",
                                }}
                              />
                                  </span>
                                </div>
                              </div>
                        
                              <div className={`common-card-wrapper-style cardactivecontainer`}>
                                <div className={`common-card-icon-container`}>
                                  <div className={`common-card-icons`}>
                                    <img src={plagiarismreport_percentage_matchingsvg} className={`common-card-icon-img`} alt="  Total Words" />
                                  </div>
                                </div>
                                <div className={`common-card-subtitle cardcontentactivecontainer`}>
                                  <span className="common-card-title"> Low Impact Issue Occurrences -- {cardData?.low_impact_issue} </span>
                                  <span className="common-card-value">        
                                 
                                  <LiquidFillGauge
                                style={{ margin: "0 auto" }}
                                width={50}
                                height={50}
                                value={cardData?.low_impact_percentage}
                                percent="%"
                                textSize={1}
                                textOffsetX={0}
                                textOffsetY={0}
                                riseAnimation
                                waveAnimation
                                waveFrequency={2}
                                waveAmplitude={1}
                                gradient
                                gradientStops={gradientStops}
                                circleStyle={{
                                  fill: "#FFFFFF",
                                }}
                                waveStyle={{
                                  fill: "#FFFFFF",
                                }}
                                textStyle={{
                                  fill: color("#0b2033").toString(),
                                  fontFamily: "Arial",
                                }}
                                waveTextStyle={{
                                  fill: color("#0b2033").toString(),
                                  fontFamily: "Arial",
                                }}
                              />
                              </span>
                                </div>
                              </div>
                            </div>
            
          </div>
        </div>
        <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="SEO Metrics" value="1" />
                <Tab label="Page Metrics" value="2" />
                <Tab label="Internal Links" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
                <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                        <DataGrid
                                      disableColumnFilter
                                      disableColumnSelector
                                      disableDensitySelector
                                      disableRowSelectionOnClick
                                      autoHeight
                                      getRowHeight={() => "auto"}
                                       getRowId={(row) => row.seo_metrics}
                                      // getRowId={(row) =>  generateRandom()}
                                   
                                      slots={{
                                        noRowsOverlay: MyCustomNoRowsOverlay,
                                        toolbar: GridToolbar,
                                        noResultsOverlay: MyCustomNoRowsOverlay,
                                        footer: () => (
                                          <Box className="table-footer-container">
                                            <Box className="table-footer-pagination">
                                              <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                                            </Box>
                                            <MSelect
                                              value={rowsPerPage}
                                              onChange={handleRowsPerPageChange}
                                              size="small"
                                              className="table-footer-select"
                                            >
                                              <MenuItem value={10}>10 per page</MenuItem>
                                              <MenuItem value={30}>30 per page</MenuItem>
                                              <MenuItem value={50}>50 per page</MenuItem>
                                              <MenuItem value={75}>75 per page</MenuItem>
                                              <MenuItem value={100}>100 per page</MenuItem>
                                            </MSelect>
                                          </Box>
                                        ),
                                      }}
                                      slotProps={{
                                        toolbar: {
                  
                                          showQuickFilter: true,
                  
                                        },
                                      }}
                                      rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                                      columns={columns}
                                      pagination
                                     
                                    />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
                <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                  <DataGrid                 
                    autoHeight
                    getRowHeight={() => 'auto'}
                    getRowId={(row) => row.site_metrics}
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      noResultsOverlay: MyCustomNoRowsOverlay,
                      footer: () => (
                        <Box className="table-footer-container">
                          <Box className="table-footer-pagination">
                            <Pagination className="table-pagination" count={totalPages1} page={page} onChange={handlePageChange} />
                          </Box>
                          <MSelect
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            size="small"
                            className="table-footer-select"
                          >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={30}>30 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={75}>75 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                          </MSelect>
                        </Box>
                      ),
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    rows={rows1.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                    columns={column1}
                    pagination
                    // initialState={{
                    //   pagination: {
                    //     paginationModel: {
                    //       pageSize: 5,
                    //     },
                    //   },
                    // }}
                    // pageSizeOptions={[5, 10, 25]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
                <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                  <DataGrid                  
                    autoHeight
                    getRowHeight={() => 'auto'}
                    getRowId={(row) => row.internal_links}
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      noResultsOverlay: MyCustomNoRowsOverlay,
                      footer: () => (
                        <Box className="table-footer-container">
                          <Box className="table-footer-pagination">
                            <Pagination className="table-pagination" count={totalPages2} page={page} onChange={handlePageChange} />
                          </Box>
                          <MSelect
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            size="small"
                            className="table-footer-select"
                          >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={30}>30 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={75}>75 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                          </MSelect>
                        </Box>
                      ),
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    rows={rows2.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                    columns={column2}
                    pagination
                    // initialState={{
                    //   pagination: {
                    //     paginationModel: {
                    //       pageSize: 5,
                    //     },
                    //   },
                    // }}
                    // pageSizeOptions={[5, 10, 25]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                  />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
