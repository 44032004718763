import React, { useState } from "react";
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
import onpageerroricon from "../Asset/ESO/onpageerror.png";
import totalerrorlinkicon from "../Asset/ESO/totalerrorlink.png";
import visibilityicon from "../Asset/ESO/visibility.png";
import onpageerroricon1 from "../Asset/ESO/onpageerror1.png";
import totalerrorlinkicon1 from "../Asset/ESO/totalerrorlink1.png";
import visibilityicon1 from "../Asset/ESO/visibility1.png";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const DashboardCards = (props) => {
  const [chainIcons, setChainIcons] = useState(rankedkeywordsicon);
  const [errorIcon, setErrorIcon] = useState(onpageerroricon);
  const [totalErrorIcons, setTotalErrorIcons] = useState(totalerrorlinkicon);
  const [visibilityIcons, setVisibilityIcons] = useState(visibilityicon);
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const cardContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "11px",
    padding: "20px",
  };

  const cardWrapperStyle = {
    position: "relative",
    width: "calc(50% - 11px)", // Two cards per row on small screens
    maxWidth: "290px", // Ensuring uniform max width
    textAlign: "center",
    boxSizing: "border-box",
  };

  const cardIconStyle = {
    position: "absolute",
    top: "-25px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "65px",
    height: "65px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
    color: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const cardStyle = {
    padding: "50px 20px 20px",
    borderRadius: "8px",
    border: "2px solid #ff6600",
    backgroundColor: "white",
    transition: "0.3s",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const titleStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "5px",
    color: "#0B2033",
  };

  const valueStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#0B2033",
  };

  const responsiveStyle = {
    "@media (max-width: 768px)": {
      cardContainerStyle: {
        flexDirection: "column",
        alignItems: "center",
      },
      cardWrapperStyle: {
        width: "100%", // Full width on mobile
        maxWidth: "100%",
      },
    },
  };
  const OpenRankFilter = () => {
    setIsOpen(true);
  };
  const Navigateto = (e) => {
    var ClassName = e.target.tagName;
    if (ClassName !== "path" || ClassName === "svg") {
      const LinkAttr = e.target
        .closest(".ranking_summary_col_new")
        .getAttribute("data-link");

      navigate(LinkAttr);
    }
  };
  return (
    <div style={{ ...cardContainerStyle, ...responsiveStyle.cardContainerStyle }}>
      {/* High Impact Issues */}
      <div
        style={{ ...cardWrapperStyle, ...responsiveStyle.cardWrapperStyle, marginTop:30,cursor: "pointer" }}
        onMouseEnter={() => setChainIcons(rankedkeywordsicon1)}
        onMouseLeave={() => setChainIcons(rankedkeywordsicon)}
        className="cardactivecontainer"
       
      >
        <div style={cardIconStyle}>
          <img src={chainIcons} style={{ pointerEvents: "none" }} alt="Ranked Keywords" />
        </div>
        <div style={cardStyle} className="cardcontentactivecontainer ranking_summary_col_new"     data-link="/ranking-summary/2"
            onClick={Navigateto}>
          <span style={titleStyle}>Ranked Keywords</span>
          <span style={valueStyle}>{props?.RankedKeywords?props?.RankedKeywords:0}</span>
        </div>
      </div>

      {/* On Page Errors */}
      <div
        style={{ ...cardWrapperStyle, ...responsiveStyle.cardWrapperStyle,marginTop:30 ,cursor: "pointer"}}
        onMouseEnter={() => setErrorIcon(onpageerroricon1)}
        onMouseLeave={() => setErrorIcon(onpageerroricon)}
          className="cardactivecontainer"
      >
        <div style={cardIconStyle}>
          <img src={errorIcon} alt="On Page Errors" />
        </div>
        <div style={cardStyle} className="cardcontentactivecontainer ranking_summary_col_new"  data-link="/onpage-crawl-data/2/1"
            onClick={Navigateto}>
          <span style={titleStyle}>On Page Errors</span>
          <span style={valueStyle}>{props?.OnPageErrors?props?.OnPageErrors:0}</span>
        </div>
      </div>

      {/* Total Internal Links */}
      <div
        style={{ ...cardWrapperStyle, ...responsiveStyle.cardWrapperStyle,marginTop:30 ,cursor: "pointer"}}
        onMouseEnter={() => setTotalErrorIcons(totalerrorlinkicon1)}
        onMouseLeave={() => setTotalErrorIcons(totalerrorlinkicon)}
          className="cardactivecontainer"
      >
        <div style={cardIconStyle}>
          <img src={totalErrorIcons} alt="Total Internal Links" />
        </div>
        <div style={cardStyle} className="cardcontentactivecontainer ranking_summary_col_new"     data-link="/internal-link-checker"
            onClick={Navigateto}>
          <span style={titleStyle}>Total Internal Links</span>
          <span style={valueStyle}>{props?.TotalInternalLinks?props?.TotalInternalLinks:0}</span>
        </div>
      </div>

      {/* Visibility Score */}
      <div
        style={{ ...cardWrapperStyle, ...responsiveStyle.cardWrapperStyle, marginTop:30,cursor: "pointer"}}
        onMouseEnter={() => setVisibilityIcons(visibilityicon1)}
        onMouseLeave={() => setVisibilityIcons(visibilityicon)}
          className="cardactivecontainer"
      >
        <div style={cardIconStyle}>
          <img src={visibilityIcons} alt="Visibility Score" />
        </div>
       
        <div style={cardStyle} className="cardcontentactivecontainer ranking_summary_col_new"  data-link="visibility-score-detail"
            onClick={Navigateto}>
          <span style={titleStyle}>Visibility Score</span>
          
          <span style={valueStyle}>{props.VisibilityScore}</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
