import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Switch, FormControlLabel, Radio } from '@mui/material';

import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";

import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs, Typography } from "@mui/material";
import AddForm from "./AddForm";
import EditForm from "./EditForm";

export default function UserRole() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [selectval, setSelectval] = useState("");

  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [pagename, setPagename] = useState("User Management")
  const [selectedRows, setSelectedRows] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [userListPlan, setuserListPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({ domain_name: '', domain_url: '' });
  const [isAddEditModel, setIsAddEditModel] = useState(false);
  const [submitUser, setSubmitUser] = useState(false);
  const [addEditDialogTitle, setAddEditDialogTitle] = useState('addUser');
  const [editUserId, setEditUserId] = useState(null);

  const [userRole, setUserRole] = useState("");
  // const [userId, setUserId] = useState(null);
  // const [primaryUser, setprimaryUser] = useState(false);

  const navigate = useNavigate();
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = async (e) => {
    SetDeleteId(e.target.id)
    setIsOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;


  const checkUserPlan = async () => {
    const userListP = await Utils("user_management", ApiToken);
    if (userListP.status) {
      callUserdataApi();
      faqdatafun();
      getCurrentUserPermission();
    }
    setuserListPlan(userListP);
  };

  const getCurrentUserPermission = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/user_management_get_current_user/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
      console.log("<<<<<<<<=========datavalue=======>>>>>>>>>", datavalue.data.result.role);
      if (datavalue?.data?.result?.role) {
        // setUserId(datavalue.data.user_id);
        setUserRole(datavalue.data.result.role);
        // setprimaryUser(datavalue.data.result.is_primary_user)
      }

    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }


  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, [submitUser]);


  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domaindata`;
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  console.log("cardData", cardData)

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/user_management_user_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      console.log("kannan", datavalue)
      if (datavalue?.data?.users) {
        if (datavalue?.data?.users) {
          const newData = datavalue.data.users.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });


          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    if (first.statusDomain) {
      setcheckD(first.statusDomain);
    } else {
      // navigate("/add-role");
      setAddEditDialogTitle('addUser');
      setSubmitUser(false);
      setIsAddEditModel(true);
    }
  }


  const reportNow = async () => {
    var err = 1;
    if (websiteName === "") {
      SetWebnameError("Website Name is Required")
      err = 2;
    }
    if (webLink === "") {
      SetweblinkError("Website Link is Required")
      err = 2;
    }
    if (err == 2) {
      return false;
    }
    else {
      setLoading(true)
      await axios.post("", {
        email: websiteName,
        url: webLink,

      })
        .then((response) => {

          //redirect logic
          if (response.data.result == 200) {
            closeModal()
          }
        }).catch((error) => {
          setLoading(false)
          console.log(error)
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
      setLoading(false)
    }

  };
  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const [rowsdata, setRowsdata] = useState(rows);

  const handleSwitchChange = (id) => (event) => {
    statusUpdate(id, event.target.checked)
    const updatedRows = rows.map((row) =>

      row.id === id ? { ...row, is_active: event.target.checked } : row
    );
    setCardData(updatedRows);
    console.log(updatedRows)
  };
  const statusUpdate = async (id, statusval) => {

    if (statusval == true)
      var urldata = `${process.env.REACT_APP_API_URL}settings/user_management_enable_user/?user_id=${id}`;
    else
      var urldata = `${process.env.REACT_APP_API_URL}settings/user_management_disable_user/?user_id=${id}`;
    try {
      const response = await axios.get(
        urldata,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      const datavalue = response.data;
      console.log("response", datavalue)

      // Process datavalue here if needed
    } catch (error) {
      if (error?.response?.status === 599 || error?.response?.status === 400 || error?.response?.status === 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error);
      } else if (error?.code === "ERR_NETWORK" || error?.response?.status === 404) {
        navigate('/errorpages');
      }
    }

  };


  // const handleSwitchChangePrime = (id, sta) => {

  // console.log("id==========================>>>>>>>>>>", id);

  // statusUpdate(id, event.target.checked)
  // const updatedRows = rows.map((row) =>

  //   row.id === id ? { ...row, is_primary_user: sta } : row
  // );
  // setCardData(updatedRows);
  // console.log(updatedRows)
  // };

  const primaryUserAddRemove = async (data) => {
    console.log("data", data);
    try {

      if (!data.is_primary_user && data.role === "Admin") {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}settings/user_management_make_primary_user/?user_id=${data.id}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }).catch((error) => {
            setLoading(false)
            console.log(error)
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK") {
              navigate('/errorpages')
            }
            // console.log("kannan",error)
          });
        console.log("<<<<<<<<=========datavalue=======>>>>>>>>>", datavalue.status);
        if (datavalue.status === 200) {
          // handleSwitchChangePrime(data.id, false)
          callUserdataApi();
        }

      }

    } catch (error) {
      console.log(error);
    }
  }



  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'User Email ID',
      renderCell: (cardData) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          {cardData.row.is_primary_user && (
            <span
              style={{
                position: 'absolute',
                top: 0,
                right: -90,
                backgroundColor: '#ffcc00',
                padding: '2px 8px',
                fontSize: '12px',
                borderRadius: '3px',
                color: 'black'
              }}
            >
              Primary User
            </span>
          )}
          <p>{cardData.row.email}</p>
        </div>
      )


    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      description: 'Role that have been added for the domain',
      disableClickEventBubbling: true,
      // renderCell: (cardData) => {

      //   let firstEightLetters = cardData.row.url.substring(0, 20);
      //   let lastThreeLetters = cardData.row.url.slice(-5); 

      //     return (
      //       <>
      //       <Tooltip title={cardData.row.url}>
      //       <div className="prefered_url_external">
      //           <LinkIcon/>
      //           <a target="_blank" href={cardData.row.url}>{firstEightLetters+'...'+lastThreeLetters}</a>
      //           </div>

      //       </Tooltip>
      //          </>
      //     );

      // },

    },
    {
      field: "is_active",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Indication of account status of user',
      renderCell: (cardData) => (
        <Switch
          checked={cardData.row.is_active}
          disabled={cardData.row.role === "Admin" && cardData.row.is_primary_user ? true : false}
          onChange={handleSwitchChange(cardData.row.id)}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        return (
          // <Link to={"/edit-role/" + cardData.row.id}><button
          //   className="buttonView edit_icon">
          //   <EditIcon />
          // </button></Link>

          <Tooltip title={'Click here to edit the user'}>
              <button
                className="buttonView edit_icon"
                onClick={() => {
                  setAddEditDialogTitle('editUser');
                  setIsAddEditModel(true);
                  setSubmitUser(false);
                  setEditUserId(cardData.row.id);
                }}
              >
                <EditIcon />
              </button>
          </Tooltip>

        );
      }
    }

  ].concat(
    userRole === "Admin"
      ? [
        {
          field: "Make Primary User",
          headerName: "Make Primary User",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          align: "center",
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (cardData) => {
            return cardData.row.role === "Admin" && !cardData.row.is_primary_user /* && cardData.row.id != userId && primaryUser*/ ?
              (<button className="buttonView" onClick={() => { primaryUserAddRemove(cardData.row) }}>
                Make primary User
              </button>) : null
          }
        },
      ]
      : []
  );
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };


  const [CallCampaignTable, SetCallCampaignTable] = useState(false)

  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer')) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=57`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Role Management</title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>User Management</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>

          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> {pagename}</h2>
            {/* <p>as of {jobDate}</p> */}
          </div>
          <div className="cnt_hdr_top_rgt_wrap">

            {userListPlan.status &&
              <button
                title="Add User"
                className="runreportnew"
                onClick={() => { openModal() }}


              >
                Add User
              </button>
            }
          </div>

        </div>

        {userListPlan.status ?
          <div>



            <div className="data_table brand_list_table campapgin_settings_list_table" style={{ width: "100%" }}>
              <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">

                <DataGrid
                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                    footer: () => (
                      <Box className="table-footer-container">
                        <Box className="table-footer-pagination">
                          <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                        </Box>
                        <MSelect
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                          size="small"
                          className="table-footer-select"
                        >
                          <MenuItem value={10}>10 per page</MenuItem>
                          <MenuItem value={30}>30 per page</MenuItem>
                          <MenuItem value={50}>50 per page</MenuItem>
                          <MenuItem value={75}>75 per page</MenuItem>
                          <MenuItem value={100}>100 per page</MenuItem>
                        </MSelect>
                      </Box>
                    ),
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                  columns={columns}
                  pagination
                  // pageSizeOptions={[5, 10, 20]}
                  disableVirtualization

                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 10,
                  //     },
                  //   },
                  // }}
                  // loading={loading}
                  // pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick

                />
              </div>
            </div>
          </div> : <Freeaudit planStatus={userListPlan} />
        }

      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}

      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}

      <Dialog
        open={isAddEditModel}
        onClose={() => setIsAddEditModel(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">{addEditDialogTitle == 'addUser' ? 'Add User' : 'Edit User'}</DialogTitle>
        <DialogContent className="update-user-dialog">
          {addEditDialogTitle == 'addUser' ?
            <AddForm setIsAddEditModel={setIsAddEditModel} setSubmitUser={setSubmitUser} /> :
            <EditForm 
            setIsAddEditModel={setIsAddEditModel} 
            setSubmitUser={setSubmitUser} 
            editUserId={editUserId}
            />
          }
        </DialogContent>
      </Dialog>
      <Dialog
        open={checkD}
        // onClose={() => setOpenCommonAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="domain-verify-alert"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
              <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
              <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
        </DialogActions>
      </Dialog>
    </div>


  );
}
