import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./plagiarisamstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { Tooltip as MuiTooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Description } from "@mui/icons-material";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Breadcrumbs,Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import rankedkeywordsicon from "../Asset/ESO/rankedkeywords.png";
import rankedkeywordsicon1 from "../Asset/ESO/rankedkeywords1.png";
import searchEngine from "../Asset/ESO/search-engine.svg";
import plagiarism_total_wordssvg from "../Asset/esocardicon/plagiarism/plagiarism_total_words.svg"
import plagiarism_totalmatching_urlsvg from "../Asset/esocardicon/plagiarism/plagiarism_totalmatching_url.svg"
import plagiarism_similarity_word_count from "../Asset/esocardicon/plagiarism/plagiarism_similarity_word_count.svg"
import plagiarismsimilarityscore from "../Asset/esocardicon/plagiarism/plagiarismsimilarityscore.svg"


export default function Plagiarisamdetail() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [totalwords, setTotalwords] = useState("");
  const [totalsimilarwords, setTotalsimilarwords] = useState("");
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  const [queryurl, setQueryurl] = useState("");
  const [initialDateval, SetInitialDateval] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rescardData, setRescardData] = useState([]);
  const [jobDate, setjobDate] = useState("");

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [chainIcons, setChainIcons] = useState(rankedkeywordsicon);

  const navigate = useNavigate();
  let querystring = useParams();
  let plag_id = querystring.id;
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-scan-detail/`;

  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/plagiarism_detail_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      const dataval = datavalue?.data;
      setTotalwords(dataval?.plagiarism_data.response?.querywords);
      setTotalsimilarwords(dataval?.plagiarism_data?.response?.allwordsmatched);
      setTotalpercentwords(dataval?.plagiarism_data?.response?.allpercentmatched);

      setQueryurl(dataval?.plagiarism_data?.response?.query);
      // setjobDate(moment(datavalue.data.job_date).format("MMM DD,YYYY  HH:mm"));
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
      setCardData(dataval);
      if (dataval?.plagiarism_data?.response?.result && dataval?.plagiarism_data?.response?.result?.length > 0) {

        setRescardData(dataval?.plagiarism_data?.response?.result);
        SetAutoheightval(false)

      }
      else {
        setRescardData("");
        SetAutoheightval(true)
      }


    } catch (err) {
      console.log(err)
    }


    setLoading(false);
  };
  const rows = rescardData ?? [];
  const columns = [
    {
      field: "url",
      headerName: "URL",
      description: 'A URL is a web address that specifies the location of a resource online.',
      minWidth: 950,
      flex: 1,

      headerAlign: 'center',
      align: 'center',
      renderCell: (rescardData) => {
        let firstEightLetters = "";
        let lastThreeLetters = "";
        let wholedata = "";
         firstEightLetters = rescardData.row.url.substring(0, 100);
        wholedata = firstEightLetters;

        
        let baseUrl = rescardData?.row?.url.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = rescardData?.row?.url.split("/").slice(3).join("/");
        return (
          <>
 <Tooltip title={rescardData?.row?.url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={rescardData?.row?.url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink">{remaining}</span>
                </a>
              </div>

            </Tooltip>
            <br />
            <span className="full_title"> {rescardData.row.title} </span> <br />
            <div className="full_title_inr_txt">{rescardData.row.textsnippet}</div>
        
{/*             
            <MuiTooltip title={rescardData.row.url}>
              <div className="prefered_url_external center">
                <LinkIcon />
                <a target="_blank" href={rescardData.row.url}>{firstEightLetters + '...' + lastThreeLetters}</a>
              </div>

            </MuiTooltip> */}

          </>
        );
      },
    },
    {
      field: "urlwords",
      headerName: "Total Words On Page",
      description: 'The total word count on the page.',
      minWidth: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (rescardData) => {
        return rescardData.row.urlwords ? rescardData.row.urlwords : "N/A";
      },
    },
    {
      field: "wordsmatched",
      headerName: "Total Matched Words",
      description: 'The total number of matching words found.',
      minWidth: 230,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (rescardData) => {
        return rescardData.row.wordsmatched
          ? rescardData.row.wordsmatched
          : "N/A";
      },
    },
    {
      field: "percentmatched",
      headerName: "Percentage of Matched Words",
      description: 'Percentage of words matched.',
      minWidth: 250,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (rescardData) => {
        return rescardData.row.percentmatched
          ? rescardData.row.percentmatched
          : "N/A";
      },
    },
    {
      field: "Report",
      headerName: "Report",
      description: 'Report',
      minWidth: 250,
      flex: 1,
      sortable: false,
      headerClassName: 'hide_option',
      headerAlign: 'center',
      align: 'center',
      renderCell: (rescardData) => {
        return rescardData.row.wordsmatched != 0 &&
          rescardData.row.wordsmatched != "N/A" ? (
            <a href={detailurl + `${plag_id}` + "/" + `${rescardData.row.index}`}>
            <button className="view-report-button">
              View Report
            </button>
          </a>
        ) : (
          "N/A"
        );
      },
    },
  ];

  var state = {
    value: totalpercecntwords,
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#ff601f";
  const textfillColor = "#0B2033";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const pdfdown = async () => {
    console.log('pdfdown');
    setIsDownloading(true);
    setLoading(true);
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_detail_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      console.log('response', response);
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        //link.download = 'Plagarismdetail.pdf';
        var filedata = await Commonfun("Plagiarism-detail", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setIsDownloading(false);
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_detail_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Plagiarism-detail", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Failed to fetch PDF:', response.status, response.statusText);
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setIsDownloadingcsv(false);
    setLoading(false);
  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=13`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });;
      if (doaminsectiondatavalue.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'urlcheck')
      || (event.target.classList[0] === 'plagiarisam_missing_waring_wrap')


    ) {
      setfaqData(2)
      setToggle(false)
    }
  });

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
           <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plagiarism Detail</title>
        </Helmet>
      <div className="cnt_audit_outer_wrap plagiarisamdetail_otr_wrap">
   
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Content</Typography>
            <Link to="/plagiarism-checker">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Plagarism Checker</Typography>
            </Link>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Plagiarism Checker Detail</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
              <>
                <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
              </>
   
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
       
             <div className="cnt_hdr_top_lft_wrap">
                        <div >
                        <h2 className="heading_font">Plagiarism Checker Detailed Report</h2> 
                        <div className="prefered_url_external02">
                          <a target="_blank" href={cardData?.request_url} style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className="spanlink">
                            &nbsp; {cardData?.request_url} 
                            </span>
                          </a>
                        </div>
                       
                        </div>
                        {/* <p>as of {jobDate}</p> */}
                                                      <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
                      </div>
       
        </div>
        <div className="common-card-container" style={{justifyContent: "center"}}>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={searchEngine} className={`common-card-icon-img`} alt=" Date of Scan" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title"> Date of Scan</span>
                      <span className="common-card-value">  {timezonechange(jobDate)}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarism_total_wordssvg} className={`common-card-icon-img`} alt="  Total Words" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">  Total Words</span>
                      <span className="common-card-value">   {totalwords}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarism_totalmatching_urlsvg} className={`common-card-icon-img`} alt="  Total Words" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">  Total Matching URLs</span>
                      <span className="common-card-value">  {cardData.total_matching_url}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarism_similarity_word_count} className={`common-card-icon-img`} alt="  Total Words" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">   Total Similarity Word Count</span>
                      <span className="common-card-value">   {cardData?.total_matching_url}</span>
                    </div>
                  </div>
                  <div className={`common-card-wrapper-style cardactivecontainer`}>
                    <div className={`common-card-icon-container`}>
                      <div className={`common-card-icons`}>
                        <img src={plagiarismsimilarityscore} className={`common-card-icon-img`} alt="  Total Words" />
                      </div>
                    </div>
                    <div className={`common-card-subtitle cardcontentactivecontainer`}>
                      <span className="common-card-title">  Similarity Score</span>
                      <span className="common-card-value">        <LiquidFillGauge
                    style={{ margin: "0 auto" }}
                    width={70}
                    height={70}
                    value={state.value}
                    percent="%"
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: "#FFFFFF",
                    }}
                    waveStyle={{
                      fill: "#FFFFFF",
                    }}
                    textStyle={{
                      fill: color("#0b2033").toString(),
                      fontFamily: "Arial",
                    }}
                    waveTextStyle={{
                      fill: color("#0b2033").toString(),
                      fontFamily: "Arial",
                    }}
                  /></span>
                    </div>
                  </div>
                </div>
  
        <div className="data_table plagirisam_detail" style={{ width: "100%" }}>
        <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
        <DataGrid
              getRowId={(row) => row.index}
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                toolbar: GridToolbar,
                noResultsOverlay: MyCustomNoRowsOverlay,
                footer: () => (
                  <Box className="table-footer-container">
                    <Box className="table-footer-pagination">
                      <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                    </Box>
                    <MSelect 
                      value={rowsPerPage} 
                      onChange={handleRowsPerPageChange} 
                      size="small"
                      className="table-footer-select"
                    >
                      <MenuItem value={10}>10 per page</MenuItem>
                      <MenuItem value={30}>30 per page</MenuItem>
                      <MenuItem value={50}>50 per page</MenuItem>
                      <MenuItem value={75}>75 per page</MenuItem>
                      <MenuItem value={100}>100 per page</MenuItem>
                    </MSelect>
                  </Box>
                ),

              }}
              slotProps={{
                toolbar: {

                  showQuickFilter: true,

                },
              }}
              rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
              columns={columns}
              pagination
              
              getRowHeight={() => "auto"}
              autoHeight={autoheightval ? true : false}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: 10,
              //     },
              //   },
              // }}
              // pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
            // loading={loading}
            />
          </div>
          <Dialog
            open={apiopen}
            onClose={apihandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {apierrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={apihandleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
