

export const customDropdownStyles = () => {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgb(255, 96, 31)" : "white", // Change hover color
            color: state.isFocused ? "white" : "black", // Change text color when hovered
        }),
        control: (base) => {
            const isMobile = typeof window !== "undefined" && window.innerWidth < 500; // Avoid SSR issues
            return {
                ...base,
                borderColor: "rgb(255, 96, 31)", // Set a fixed border color
                padding: "8px", // Add padding
                "&:hover": {
                    borderColor: "rgb(255, 96, 31)", // Prevent color change on hover
                },
                boxShadow: "none",
                height: "37px",
                minHeight: "37px",
                alignContent: "center",
                // width: "269px",
                width: isMobile ? "200px" : "269px",
                fontSize: isMobile ? "smaller" : "13px"
            }
        },
        input: (base) => ({
            ...base,
            textAlign: 'left', // Ensure left-aligned text
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: "rgb(255, 96, 31)", // Optional: Set color for dropdown arrow
            "&:hover": {
                color: "rgb(255, 96, 31)", // Prevent hover effect on arrow
            },
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "150px",
            zIndex: 9999,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Ensure it's above MUI Dialog
        })
    };
};

export const customMultiDropdownStyles = () => {
    return {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "rgb(255, 96, 31)" : "white", // Change hover color
            color: state.isFocused ? "white" : "black", // Change text color when hovered
        }),
        control: (base) => {
            const isMobile = typeof window !== "undefined" && window.innerWidth < 500; // Avoid SSR issues
            return {
                ...base,
                borderColor: "rgb(255, 96, 31)", // Set a fixed border color
                // padding: "8px", // Add padding
                "&:hover": {
                    borderColor: "rgb(255, 96, 31)", // Prevent color change on hover
                },
                boxShadow: "none",
                height: "37px",
                minHeight: '37px',
                alignContent: "center",
                // width: "269px"
                width: isMobile ? "200px" : "269px",
                fontSize: isMobile ? "smaller" : "15px"
            }
        },
        input: (base) => ({
            ...base,
            textAlign: 'left', // Ensure left-aligned text
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: "rgb(255, 96, 31)", // Optional: Set color for dropdown arrow
            "&:hover": {
                color: "rgb(255, 96, 31)", // Prevent hover effect on arrow
            },
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "150px",
            zIndex: 9999,
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Ensure it's above MUI Dialog
        })
    }
};