import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import Select from 'react-select';
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";

import { Outlet, Link } from "react-router-dom";
import BacklinksTrend from "./BacklinksTrend";
import BackLinksSummary from "./BacklinksSummary";
import BacklinksResearch from "./BacklinksResearch";
import Linklists from "./New_link/Linklists";
import LinkedDomainList from "./LinkedDomainList";
import BacklinksReport from "./BacklinksReport";
import RunReportNow from "../RunReportNow";
import { ToastContainer, toast } from 'react-toastify';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { useNavigate } from 'react-router-dom';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import { datezonechange } from "./../Commonfun"
import Loader from "../CommonLoader";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';

export default function BackLinksIndex() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState({ label: '', value: '' });
  const [campaign, Setcampaign] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [selectjobid, setselectjobid] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [System, SetSystem] = useState('');
  const [Device, SetDevice] = useState('')
  const [backlinkurl, SetBacklinkurl] = useState('')
  const [newButtonlink, SetnewButtonlink] = useState('1')
  const esodata = useSelector((state) => state);
  const [SubmitTriggered, SetSubmitTriggered] = useState(false)
  const ApiToken = esodata.apiauthkey.value
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [CurrentMenu, SetCurrentMenu] = useState('backlinks_summary')
  const [CurrentMenuName, SetCurrentMenuName] = useState('Backlinks Summary')
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [blSummaryPlan, setblSummaryPlan] = useState({ status: true, error: "" });
  const [blResearchPlan, setblResearchPlan] = useState({ status: true, error: "" });
  const [blNewLostPlan, setblNewLostPlan] = useState({ status: true, error: "" });
  const [blLinkedDomainPlan, setblLinkedDomainPlan] = useState({ status: true, error: "" });
  const [blTrendPlan, setblTrendPlan] = useState({ status: true, error: "" });
  const [blReportsPlan, setblReportsPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const navigate = useNavigate();

  const SwitchMenu = (e) => {

    const Id = e.target.getAttribute('data-id')
    const Name = e.target.getAttribute('data-name')

    SetRankCampaign('')
    SetRankJob('')
    SetRankEngine('')
    SetRankCountry('')
    SetRankState('')
    SetRankCity('')
    SetRankSystem('')
    SetRankDevice('')
    // setSelectval('')
    SetCurrentMenu(Id)
    SetCurrentMenuName(Name)
    // SetdEngineoption({
    //   label:
    //     'Select Date',
    //     value:
    //     'Select Date',
    // });


    faqdatafun(Id)


  }


  const apihandleClose = () => {
    setapiOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    if (value) {
      SetCountry(value.value);
      try {
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${value.value}&city_id=${City}&filter_type=state`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        );
        if (statedatavalue?.data) {
          if (statedatavalue?.data?.states) {
            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({
                label: element.search_engine_locale__city__state__state_name,
                value: element.search_engine_locale__city__state__id,
              });
            });
            SetStateoptions(Sections);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    if (value) {
      SetState(value);

      const citydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${value.value}&filter_type=city`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if (citydatavalue?.data) {
        if (citydatavalue?.data?.cities) {
          var Sections = [];
          citydatavalue.data.cities.forEach((element) => {
            Sections.push({
              label: element.search_engine_locale__city__city_name,
              value: element.search_engine_locale__city__id,
            });
          });
          SetCityOptions(Sections);
        }
      }
    }
  };

  const [FilterButtonTriggered, SetFilterButtonTriggered] = useState(false)

  const finalreportNow = async () => {
    setLoading(true);
    SetErrorMsg("");
    if (
      selectjobid !== "" ||
      campaign !== "" ||
      SearchEngine !== "" ||
      Country !== "" ||
      State.value !== "" ||
      City.value !== "" ||
      System !== '' ||
      Device !== ''

    ) {


      SetRankCampaign(campaign)

      SetRankJob(selectjobid)
      SetRankEngine(SearchEngine)
      SetRankCountry(Country)

      if (State.value) {
        SetRankState(State.value)
      }
      if (City.value) {
        SetRankCity(City.value)
      }


      SetRankSystem(System)
      SetRankDevice(Device)
      SetFilterButtonTriggered(true)


    } else {
      setLoading(false);
      return SetErrorMsg("Please Select the All Fields !");
    }
    closeModal();
    setLoading(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });


  const [RankCampaign, SetRankCampaign] = useState('')
  const [RankJob, SetRankJob] = useState('')
  const [RankEngine, SetRankEngine] = useState('')
  const [RankCountry, SetRankCountry] = useState('')
  const [RankState, SetRankState] = useState('')
  const [RankCity, SetRankCity] = useState('')
  const [RankSystem, SetRankSystem] = useState('')
  const [RankDevice, SetRankDevice] = useState('')






  const LoadAddFormFilterOptions = async (jobid = "") => {
    setjobidData([]);
    SetCountryoptions([]);
    SetEngineoptions([]);
    Setcampaignoption([])
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=backlinks`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (campaigndatavalue?.data?.jobs) {
        if (campaigndatavalue?.data?.jobs) {
          var campaignSections = [];
          campaigndatavalue.data.jobs.forEach((element) => {
            campaignSections.push({ label: datezonechange(element.completed_at), value: element.id });
          });
          if (campaignSections.length > 0) {
            SetdEngineoption(campaignSections[0]);
            SetPrevdEngineoption(campaignSections[0]);
            setcompletedate(campaignSections);
            setjobDate(campaignSections[0]?.value())
          }

        }
      }
    } catch (err) { }




  };

  const checkUserPlan = async () => {
    const blSummaryP = await Utils("backlinks_summary", ApiToken);
    const blResearchP = await Utils("backlinks_research", ApiToken);
    const blNewLostP = await Utils("backlinks_new_lost", ApiToken);
    const blLinkedDomainP = await Utils("backlinks_linked_domain", ApiToken);
    const blTrendP = await Utils("backlinks_trend", ApiToken);
    const blReportsP = await Utils("backlinks_reports", ApiToken);
    setblSummaryPlan(blSummaryP);
    setblResearchPlan(blResearchP);
    setblNewLostPlan(blNewLostP);
    setblLinkedDomainPlan(blLinkedDomainP);
    setblTrendPlan(blTrendP);
    setblReportsPlan(blReportsP);
  };

  const arr = [];

  useEffect(() => {
    checkUserPlan();
    LoadAddFormFilterOptions()
    var buttonval = CurrentMenu;
    faqdatafun(buttonval)
  }, []);

  useEffect(() => {

  }, [backlinkurl, newButtonlink]);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal(triggerClose = false) {
    if (triggerClose) {
      SetdEngineoption(prevdEngineoption);
      setSelectval(prevdEngineoption);
    }
    setIsOpen(false);
  }

  const FilterFunction = () => {
    SetFilterButtonTriggered(true);
    SetPrevdEngineoption(dEngineoption);
  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('backlinks')

  const [dEngineoption, SetdEngineoption] = useState({
    label:
      'Select Date',
    value:
      'Select Date',
  });
  const [prevdEngineoption, SetPrevdEngineoption] = useState({
    label:
      'Select Date',
    value:
      'Select Date',
  });

  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    if (CurrentMenu === 'backlinks_summary') {
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=pdf&timezone=${timezoneval()}`;
      var dummyname = "Backlinks Summary"
    }
    else if (CurrentMenu === "backlinks_report") {
      var dummyname = "Backlinks Report"
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_report/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=pdf&timezone=${timezoneval()}`;
    }
    else if (CurrentMenu === "backlinks_trend") {
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&formats=pdf&timezone=${timezoneval()}`;
      var dummyname = "Backlinks Trends"

    }
    else if (CurrentMenu === "linked_domains") {
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/linked_domain_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&formats=pdf&timezone=${timezoneval()}`;
      var dummyname = "Linked Domains"
    }

    else if (CurrentMenu === "backlinks_research") {
      if (backlinkurl == "") {
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=pdf&timezone=${timezoneval()}`;
        var dummyname = "Backlinks Research"
      }
      else {
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=pdf&request_url=${backlinkurl}&timezone=${timezoneval()}`
        var dummyname = "Backlinks Research"
      }

    }
    else if (CurrentMenu === "new_lost_links") {

      if (newButtonlink == 1) {
        var dummyname = "NewLink"

        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_new_lost/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&link_type=new&is_format=pdf&timezone=${timezoneval()}`;
      }
      else {
        var dummyname = "LostLink"
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_new_lost/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&link_type=lost&is_format=pdf&timezone=${timezoneval()}`;
      }
    }

    try {
      const response = await fetch(backdataurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun(dummyname, "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;

        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    if (CurrentMenu === 'backlinks_summary')
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=csv&timezone=${timezoneval()}`;
    else if (CurrentMenu === "backlinks_report")
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_report/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=csv&timezone=${timezoneval()}`;
    else if (CurrentMenu === "backlinks_trend")
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_trend_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&formats=csv&timezone=${timezoneval()}`;
    else if (CurrentMenu === "linked_domains")
      var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/linked_domain_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&formats=csv&timezone=${timezoneval()}`;
    else if (CurrentMenu === "backlinks_research") {
      if (backlinkurl == "")
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=csv&timezone=${timezoneval()}`;
      else
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&is_format=csv&request_url=${backlinkurl}&timezone=${timezoneval()}`
    }
    else if (CurrentMenu === "new_lost_links") {
      if (newButtonlink == 1)
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_new_lost/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&link_type=new&is_format=csv&timezone=${timezoneval()}`;
      else
        var backdataurl = `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_new_lost/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectval?.value}&link_type=lost&is_format=csv&timezone=${timezoneval()}`;
    }
    try {
      const response = await fetch(backdataurl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'backLink.csv';

        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  // const e = document.getElementById('root');
  // e.addEventListener('click', function handleClick(event) {
  //   console.log(event.target.classList[0])
  //   if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
  //   (event.target.classList[0] === 'nav_key_txt') || 
  //   (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
  //   ||(event.target.classList[0] === 'react-dropdown-select-content') 
  //   ||(event.target.classList[0] === 'react-dropdown-select-item') 
  //   || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
  //   || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
  //    || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
  //    || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
  //    || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
  //    ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
  //    || (event.target.classList[0] === 'run_rpt_btn')  || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
  //    || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') ||  (event.target.classList[0] === 'link_rn_rpt_btn')
  //    || (event.target.classList[0] === 'drop-organicsearch') ||  (event.target.classList[0] === 'link_rn_rpt_btn')

  //   ) {

  //     setfaqData(2)
  //     setToggle(false)   
  //   }
  // });
  const faqdatafun = async (menubutval = "") => {

    if (menubutval === 'backlinks_summary')
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=25`
    else if (menubutval === "backlinks_report")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=30`
    else if (menubutval === "backlinks_trend")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=29`
    else if (menubutval === "linked_domains")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=28`
    else if (menubutval === "backlinks_research")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=26`
    else if (menubutval === "new_lost_links")
      var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=27`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const freeAuditPlanPdfCsv = () => {

    const planStatusMap = [
      { plan: blSummaryPlan, currentMenu: 'backlinks_summary' },
      { plan: blResearchPlan, currentMenu: 'backlinks_research' },
      { plan: blNewLostPlan, currentMenu: 'new_lost_links' },
      { plan: blLinkedDomainPlan, currentMenu: 'linked_domains' },
      { plan: blTrendPlan, currentMenu: 'backlinks_trend' },
      { plan: blReportsPlan, currentMenu: 'backlinks_report' }
    ]

    return planStatusMap.map((menuPlan, index) => {
      return (
        <>
          {(CurrentMenu === menuPlan.currentMenu && menuPlan.plan.status) && (
            <div key={index} className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />
              </button>
              <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
              <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
            </div>
          )}
        </>
      );
    });
  }

  const freeAuditPlanFilter = () => {
    console.log('freeAuditPlanFilter')

    const planStatusMap = [
      { plan: blSummaryPlan, currentMenu: 'backlinks_summary' },
      { plan: blResearchPlan, currentMenu: 'backlinks_research' },
      { plan: blNewLostPlan, currentMenu: 'new_lost_links' },
      { plan: blLinkedDomainPlan, currentMenu: 'linked_domains' },
      { plan: blTrendPlan, currentMenu: 'backlinks_trend' },
      { plan: blReportsPlan, currentMenu: 'backlinks_report' }
    ]

    return planStatusMap.map((menuPlan, index) => {
      return (
        <>
          {(CurrentMenu === menuPlan.currentMenu) && (
            <>
              {/* <div className="cnt_hdr_blw_wrap"> */}
              <button className="runreportnew" style={{ marginLeft: "auto" }} onClick={() => { SetRunReportPopShow(true) }} disabled={!menuPlan.plan.status}>
                Run Report Now
              </button>
              {/* <button className="run_rpt_btn ch_date_btn" onClick={openModal} disabled={!menuPlan.plan.status}>
                  Filter By
                  <span className="_btn_icon_calender">
                    <img src={calendarpng} alt="calender logo" />
                  </span>
                </button> */}
              {/* </div> */}
            </>
          )}
        </>
      );
    });
  }

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      {loading === true ? <Loader /> : null}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_audit_outer_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Backlinks</Typography>

            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>{CurrentMenuName}</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            {freeAuditPlanPdfCsv()}
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f", display: 'flex' }} >{CurrentMenuName} {CurrentMenu === "backlinks_summary" && <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" onClick={blSummaryPlan.status ? openModal : null} />
            </span>}
              {CurrentMenu === "backlinks_research" && <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={blResearchPlan.status ? openModal : null} />
              </span>}
              {CurrentMenu === "new_lost_links" && <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={blNewLostPlan.status ? openModal : null} />
              </span>}
              {CurrentMenu === "linked_domains" && <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={blLinkedDomainPlan.status ? openModal : null} />
              </span>}
              {CurrentMenu === "backlinks_trend" && <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={blTrendPlan.status ? openModal : null} />
              </span>}
              {CurrentMenu === "backlinks_report" && <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" onClick={blReportsPlan.status ? openModal : null} />
              </span>}
            </h2>
                <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
          </div>
          {freeAuditPlanFilter()}
        </div>

        <div className="link_cnt_hdr_blw_wrap googleanlytics" style={{marginBottom: "15px"}}>

          <button data-id="backlinks_summary" className={CurrentMenu === 'backlinks_summary' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Backlinks Summary'>
            Backlinks Summary
          </button>

          <button data-id="backlinks_research" className={CurrentMenu === 'backlinks_research' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Backlinks Research' >
            Backlinks Research
          </button>
          <button data-id="new_lost_links" className={CurrentMenu === 'new_lost_links' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='New/Lost Links'>
            New/Lost Links
          </button>
          <button data-id="linked_domains" className={CurrentMenu === 'linked_domains' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Linked Domains'>
            Linked Domains
          </button>

          <button data-id="backlinks_trend" className={CurrentMenu === 'backlinks_trend' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Backlinks Trend'>
            Backlinks Trend
          </button>
          <button data-id="backlinks_report" className={CurrentMenu === 'backlinks_report' ? 'runreportnews active' : 'runreportnews'} onClick={SwitchMenu} data-name='Backlinks Report'>
            Backlinks Reports
          </button>


        </div>


        {
          CurrentMenu === 'backlinks_trend' ? (blTrendPlan.status ? <BacklinksTrend RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} /> : <Freeaudit planStatus={blTrendPlan} />) : null
        }

        {

          CurrentMenu === 'backlinks_summary' ? (blSummaryPlan.status ? <BackLinksSummary RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} /> : <Freeaudit planStatus={blSummaryPlan} />) : null
        }

        {

          CurrentMenu === 'backlinks_research' ? (blResearchPlan.status ? <BacklinksResearch RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} backlinkurl={backlinkurl} SetBacklinkurl={SetBacklinkurl} /> : <Freeaudit planStatus={blResearchPlan} />) : null

        }

        {

          CurrentMenu === 'new_lost_links' ? (blNewLostPlan.status ? <Linklists RankJob={selectval} setSelectval={setSelectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} SetnewButtonlink={SetnewButtonlink} newButtonlink={newButtonlink} /> : <Freeaudit planStatus={blNewLostPlan} />) : null

        }

        {

          CurrentMenu === 'linked_domains' ? (blLinkedDomainPlan.status ? <LinkedDomainList RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} /> : <Freeaudit planStatus={blLinkedDomainPlan} />) : null

        }
        {

          CurrentMenu === 'backlinks_report' ? (blReportsPlan.status ? <BacklinksReport RankJob={selectval} FilterButtonTriggered={FilterButtonTriggered} SetFilterButtonTriggered={SetFilterButtonTriggered} jobDate={jobDate} setjobDate={setjobDate} closeModal={closeModal} /> : <Freeaudit planStatus={blReportsPlan} />) : null

        }






        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />





        <Dialog
          open={modalIsOpen}
          onClose={() => { closeModal(true) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter"
        >
          <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">

                <div>

                  <div className="">
                    <div>
                      <span>Completed Date</span>
                      <Select
                        value={dEngineoption}
                        options={completedate}
                        placeholder="Select..."
                        onChange={(values) => {
                          setSelectval(values)
                          SetdEngineoption(values);
                        }}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                    </div>


                  </div>

                  <div className="col">
                    <span className="text-error">{validation.dateerror}</span>
                  </div>
                </div>

              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button className="form_submit_btn" onClick={FilterFunction} style={{ cursor: 'pointer' }} >
              Submit
            </button>
            <button style={{ cursor: 'pointer' }} onClick={() => { closeModal(true) }}>close</button>
          </DialogActions>
        </Dialog>






      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}

    </div>
  );
}
