import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Line } from "react-chartjs-2";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


export default function Pageindexlists() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagename, setPagename] = useState("Page Index Report")
  const [indexurl, setIndexurl] = useState();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [LabelData, SetLabelData] = useState([])
  const [jobDate, setjobDate] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [pageIndexReportPlan, setpageIndexReportPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const [esoindexData, SetesoindexData] = useState([])
  const [googleindexData, SetgoogleindexData] = useState([])
  const [sitemapData, SetsitemapData] = useState([])
  const [fourxxData, SetfourxxData] = useState([])
  const [fivexxData, SetfivexxData] = useState([])
  const [NoindexData, SetNoindexData] = useState([])
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);


  const navigate = useNavigate();
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );




  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;

  const checkUserPlan = async () => {
    const pageIndexReportP = await Utils("page_index", ApiToken);
    if (pageIndexReportP.status) {
      callUserdataApi();
      faqdatafun()
      LoadData()
    }
    setpageIndexReportPlan(pageIndexReportP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);

  const rows = cardData ?? [];
  const LoadData = async (tableid = '') => {
    setLoading(true)
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&chart_data=True`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      var ResultData = [];

      if (datavalue.data) {
        if (datavalue.data.chart_data) {
          ResultData = datavalue.data.chart_data
        }
      }

      const labelsIn = ResultData.map(entry => entry.date.toString());
      SetLabelData(labelsIn)
      const esoindexDataIn = ResultData.map(entry => entry.eso_indexed_pages);
      SetesoindexData(esoindexDataIn)
      const googleindexDataIn = ResultData.map(entry => entry.google_indexed_pages);
      SetgoogleindexData(googleindexDataIn)
      const sitemapDataIn = ResultData.map(entry => entry.Sitemap_pages_count);
      SetsitemapData(sitemapDataIn)
      const fourxxDataIn = ResultData.map(entry => entry.fourxx_pages_count);
      SetfourxxData(fourxxDataIn)
      const fivexxDataIn = ResultData.map(entry => entry.fivexx_pages_count);
      SetfivexxData(fivexxDataIn)
      const NoindexDataIn = ResultData.map(entry => entry.Noindex_pages_count);
      SetNoindexData(NoindexDataIn)
    } catch (err) {

      SetLabelData([])
      SetesoindexData([])
      SetgoogleindexData([])
      SetsitemapData([])
      SetfourxxData([])
      SetfivexxData([])
      SetNoindexData([])
      console.log(err)
    }



    setLoading(false)

  }
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"));
      if (datavalue?.data?.result) {
        if (datavalue?.data?.result) {
          const newData = datavalue?.data?.result.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          setIndexurl(datavalue?.data?.domain_url)
          // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

          if (newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
          }
          else {
            SetAutoheightval(true)
            setCardData("");
          }
        }
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };


  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "header",
      headerName: "Description",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Website names that have been added for the domain',
    },
    {
      field: "count",
      headerName: "Count",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description: 'Website Url that have been added for the domain',
      disableClickEventBubbling: true,
    },

  ];



  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer')) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=53`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };

  const chartData = {
    labels: LabelData,
    datasets: [
      {
        label: "Pages crawled by ESO",
        data: esoindexData,
        borderColor: "orange",
        //backgroundColor: "rgb(255, 235, 219)",
        // fill: {
        //   target: "origin", // Set the fill options
        //   above: "rgba(255, 235, 219, 0.3)"
        // }
      },
      {
        label: "Pages indexed by Google",
        data: googleindexData,
        borderColor: "Yellow",
        //backgroundColor: "rgb(255, 242, 128)",
        // fill: {
        //   target: "origin", // Set the fill options
        //   above: "rgba(255, 242, 128, 0.3)"
        // }
      },
      {
        label: "Pages in the Sitemap",
        data: sitemapData,
        borderColor: "#00E1FF",
        // backgroundColor: "rgb(0, 225, 255)",
        // fill: {
        //   target: "origin", // Set the fill options
        //   above: "rgba(0, 225, 255, 0.3)"
        // }
      },
      {
        label: "Pages with 4xx error",
        data: fourxxData,
        borderColor: "green",
        //  backgroundColor: "rgb(0, 255, 51)",
        // fill: {
        //   target: "origin", // Set the fill options
        //   above: "rgba(0, 255, 51, 0.3)"
        // }
      },
      {
        label: "Pages with 5xx error",
        data: fivexxData,
        borderColor: "red",
        // backgroundColor: "rgb(255, 0, 0)",
        // fill: {
        //   target: "origin", // Set the fill options
        //   above: "rgba(0, 255, 51, 0.3)"
        // }
      },
      {
        label: "Pages with noindex tag",
        data: NoindexData,
        borderColor: "blue",
        // backgroundColor: "rgb(0,0,255)",
        // fill: {
        //   target: "origin", // Set the fill options
        //   above: "rgba(0, 255, 51, 0.3)"
        // }
      },
    ]
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category', // Use 'category' for string x-axis values
        position: 'bottom',
        ticks: {
          color: '#0b2033'
        },

      },
      y: {

        ticks: {
          color: '#0b2033'
        },

      },

    },
    plugins: {
      legend: {
        display: false

      }
    }
  };
  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(

        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `${ApiToken}`,
          },
        }
      );
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Pageindex", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // console.error(
        //   "Failed to fetch PDF:",
        //   response.status,
        //   response.statusText
        // );
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloading(false);

  };
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&timezone=${timezoneval()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/csv",
            Authorization: `${ApiToken}`,
          },
        }
      );
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("Pageindex", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
        // console.error(
        //   "Failed to fetch PDF:",
        //   response.status,
        //   response.statusText
        // );
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error("Error during PDF download:", error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <>
      {loading === true ? <Loader /> : null}
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Page Index Report</title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        <div className="cnt_audit_outer_wrap">

          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Page Index Report</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {pageIndexReportPlan.status &&
                <>
                  <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                  <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>{pagename}</h2>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
          </div>

          {/* <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font"> {pagename} </h2>
              <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                  <QuestionMarkIcon />
                </button>
                {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
                {pageIndexReportPlan.status &&
                  <div>
                    <img src={pdficon} alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" />
                    <img src={csvicon} alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download" />
                  </div>
                }
              </div>
            </div>
          </div> */}



          {pageIndexReportPlan.status ?
            <div>
              <div className="site_regress_chart_info">
                {/* <p>Site Audit Summary by Issues</p> */}
                <div className="site_Chart_info_inner">


                  <Line className="linechartdesign" data={chartData} options={chartOptions} />
                  <p>Page Index Report</p>

                  <div className="rounded-dot-row">
                    <div className="rounded-dot-col">
                      <div class="rounded-dot orange"></div><div>Pages crawled By ESO</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot yellow"></div><div>Pages Indexed By Google</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot blue"></div><div>Pages In The Sitemap</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot green"></div><div>Pages With 4xx Error</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot red" style={{ "background-color": "red" }}></div><div>Pages With 5xx Error</div>
                    </div>
                    <div className="rounded-dot-col">
                      <div class="rounded-dot red" style={{ "background-color": "blue" }}></div><div>Pages With Noindex Tag</div>
                    </div>
                  </div>


                </div>

              </div>
              <div className="data_table analysis_summary_table analysed_table_Data over_all_accessibility" style={{ width: "100%" }}>
              <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                  <DataGrid

                    getRowId={(row) => row.sid}
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      noResultsOverlay: MyCustomNoRowsOverlay,
                      footer: () => (
                        <Box className="table-footer-container">
                          <Box className="table-footer-pagination">
                            <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                          </Box>
                          <MSelect
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            size="small"
                            className="table-footer-select"
                          >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={30}>30 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={75}>75 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                          </MSelect>
                        </Box>
                      ),
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                    columns={columns}
                    pagination
                    // pageSizeOptions={[5, 10, 20]}
                    disableVirtualization

                    getRowHeight={() => "auto"}
                    autoHeight={autoheightval ? true : false}
                    // initialState={{
                    //   pagination: {
                    //     paginationModel: {
                    //       pageSize: 10,
                    //     },
                    //   },
                    // }}
                    // loading={loading}
                    // pageSizeOptions={[10, 30, 50, 75, 100]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                  />
                </div>
              </div>
            </div> : <Freeaudit planStatus={pageIndexReportPlan} />
          }

          {!openCommonAlerts && <Dialog
            open={apiopen}
            onClose={apihandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                borderRadius: "18px !important",
                borderColor: "#ff601f !important",
                top: "10px !important",
                // width: '100%',
                // boxShadow:"0px 15px 10px 0px #fff !important"
              },
            }}
          >
            <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <DialogContentText id="alert-dialog-slide-description">
                {apierrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
              <Button onClick={apihandleClose}>Close</Button>
            </DialogActions>
          </Dialog>}

        </div>

      </div>

    </>
  );
}
