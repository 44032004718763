import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import { AppContext } from "../../../Context/Context";


function Addcampaigndata (){
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);
  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)  
  const [websiteName,SetWebsiteName] =useState("")
  const [webLink,SetwebLink] =useState("")
  const [onUsername,SetonUsername] =useState("")
  const [onPassword,SetonPassword] =useState("")
  const [webnameError,SetWebnameError]=useState("")
  const [weblinkError,SetweblinkError]=useState("")
  const [onUserError,SetonUserError]=useState("")
  const [onPasswordError,SetonPasswordError]=useState("")
  const [onPageError,SetonPageError]=useState("")
  const [getdataval,SetgetDataval]=useState("")

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const navigate = useNavigate();
  let querystring = useParams();
  let uid = querystring.id;

  useEffect(()=>{
    SetgetDataval(uid)
    if(uid)
    callUserdataApi(uid)
  },[])
  const callUserdataApi = (uid) => {
    // const datavalue = await axios.get(
    //   `${process.env.REACT_APP_API_URL}keywords/campaign_data/?campaign_id=${Id}&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
    //   {
    //     headers: {
    //       Authorization: `${ApiToken}`,
    //     },
    //   }
    // );

    // if(datavalue.data)
    // {
    //   SetEditCampaignData(datavalue.data)
    // }
  }
  const onwebName = e =>{
    SetWebsiteName(e.target.value)
  }
  const cancelreportNow = async () =>{
    navigate("/keywords")
  }
  const finalreportNow = async () =>{
    SetWebnameError("")
    setLoading(true);
    var data_val=1;
    if(websiteName===""){
      SetWebnameError("Enter Campaign Name")
      data_val=2;
    }
    if(data_val===2){
    setLoading(false);
      return false;
    }
    else{
      try{
    setLoading(true);
        const response=await axios.get(
       `${process.env.REACT_APP_API_URL}keywords/add_new_campaign/?user_domain_id=${esodata.domaindata.value.header_selected}&campaign_name=${websiteName}`,
          {
                headers: {
                  Authorization: `${ApiToken}`,
                },
              }       
     )
     if(response.data.message=="Campaign created successfully.")
     {
       window.location.href=`${process.env.REACT_APP_DOMAIN}keywords`
     }
   }catch(err){
    setLoading(false);
    SetonPageError(err.response.data.error)
   }
    }

  }
    return (
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="userdatas">
        {/* <h1>Domain Data</h1> */}
        <div className="userdatas_inner">
        <p>Add Campaign Data...</p>    
        <span className="error-value">{onPageError}</span>    

        <form className='frm_container'>
        <div className="form-group user_country websitename">
          <label>Campaign Name:</label>
          <input title="This name is used as a alias to mange your domain." type="text" name="websitename" onChange={onwebName}/>
          
          <span className="error-value">{webnameError}</span> 
        
        </div>
        
        
         <div className="keyword-dialog-buttons">      
            <button className="keyword-cancel" type="button" onClick={cancelreportNow}>Close</button>
            <button className="keyword-submit" type="button" onClick={finalreportNow}>Submit</button>
         </div>   
        </form>
        </div>
      </div>   
      </div>

    );
}

export default Addcampaigndata;


