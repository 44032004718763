import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import Select from 'react-select';
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AppleIcon from "@mui/icons-material/Apple";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { ChartContainer, BarPlot } from "@mui/x-charts";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { Switch } from "react-router-dom";
import InprogressIcon from "../../../Icons/work-in-progress-unscreen.gif";
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
// import CommonAlert from "../../Commonalert/commonalert";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Outlet, Link } from "react-router-dom";

export default function Standardreport() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchopen, setsearchopen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [ShowInProgress, SetShowInProgress] = useState(false);
  const [InProgressMsg, SetInProgressMsg] = useState("Report is Loading....");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);

  const [locationval, setlocationval] = useState([
    { value: 'rankingSummary', label: 'Ranking Summary' },
    { value: 'competitorRankings', label: 'Competitor Rankings' },
    { value: 'missedOpportunities', label: 'Missed Opportunities' },
    { value: 'analysisSummary', label: 'Analysis Summary' },
    { value: 'spellChecker', label: 'Spell Checker' },
    { value: 'siteAnalysis', label: 'Site Analysis - Site Audit' },
    { value: 'schemaAnalysis', label: 'Schema Analysis' },
    { value: 'performance', label: 'Performance' },
    { value: 'sourceCodeAnalysis', label: 'Source Code Analysis' },
    { value: 'accessibilityReport', label: 'Accessibility Report' },
    { value: 'internalLinks', label: 'Links - Internal Links' },
    { value: 'externalLinks', label: 'Links - External Links' },
    { value: 'pageIndexReport', label: 'Page Index Report' },
    { value: 'backlinksSummary', label: 'Backlinks Summary' },
    { value: 'analyticsReport', label: 'Analytics Report' }
  ]);



  const [campaignval, setcampaignval] = useState("");
  const [sectionval, setsectionval] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [location_id, setLocation_id] = useState([]);
  const [selectcamptval, setSelectcamptval] = useState([]);
  const [selectsectionval, setSelectsectionval] = useState([]);
  const [locationvaldata, setlocationvaldata] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const [searchselectedRows, setsearchselectedRows] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2");
  const [initialDateval, SetInitialDateval] = useState([]);
  const [autoheightval, SetAutoheightval] = useState(true)

  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });



  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  // useEffect(() => {
  //   var Sections = [];
  //   locationval.forEach((element) => {
  //     Sections.push({ label: element.key, value: element.value });
  //   });
  //   console.log(Sections)
  //   setlocationval(Sections);
  // })
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );
  const SetSectionNameHandle = (passvalue) => {
    setSelectval(passvalue)
    // setSelectval(passvalue[0]?.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setsearchopen(false)
    setOpen(false);
  };
  const AnalyzeReport = async () => {
    console.log('AnalyzeReport', selectval)
    SetErrorMsg("")
    SetShowInProgress(true);
    if (selectval == []) {
      SetErrorMsg("Please select a report");
      SetShowInProgress(false);
    }
    else {
      var dataval = ""
      if (selectval?.label == 'Ranking Summary') {

        dataval = `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&formats=pdf&timezone=${timezoneval()}`
      }

      else if (selectval?.label == "Competitor Rankings")
        dataval =
          `${process.env.REACT_APP_API_URL}keywords/v2/competitor_ranking_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Missed Opportunities")
        dataval =
          `${process.env.REACT_APP_API_URL}keywords/v2/missed_opportunity_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Analysis Summary")
        dataval =
          `${process.env.REACT_APP_API_URL}content/v2/analysis_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Spell Checker")
        dataval =
          `${process.env.REACT_APP_API_URL}content/v2/spell_check/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Site Analysis - Site Audit")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/siteaudit/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Schema Analysis")
        dataval =
          `${process.env.REACT_APP_API_URL}schema/schema_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Performance")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/v2/performance_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Source Code Analysis")

        dataval = `${process.env.REACT_APP_API_URL}technical/v2/sourcecode_summary_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`

      else if (selectval?.label == "Accessibility Report")
        dataval = `${process.env.REACT_APP_API_URL}technical/accessibility2_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Links - Internal Links")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/v2/internal_links_table/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Links - External Links")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/external_links/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Page Index Report")
        dataval =
          `${process.env.REACT_APP_API_URL}technical/linkchecker_five_metrics/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Backlinks Summary")
        dataval =
          `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf&timezone=${timezoneval()}`
      else if (selectval?.label == "Analytics Report")
        dataval =
          `${process.env.REACT_APP_API_URL}googleanalytics/google_organic_search_traffic/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=landingPagePlusQueryString&selected_metrics=organicGoogleSearchClicks&formats=pdf&timezone=${timezoneval()}`
      try {

        const datavalerr = await fetch(
          dataval,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

        if (datavalerr.ok) {
          // Convert the response to a blob
          setSelectval("")
          const blob = await datavalerr.blob();

          // Create a temporary anchor element
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var filedata = await Commonfun(selectval?.label, "pdf", ApiToken, esodata.domaindata.value.header_selected);
          // return false;
          link.download = filedata;

          // Trigger a click on the anchor element to start the download
          link.click();

          // Remove the anchor element
          window.URL.revokeObjectURL(link.href);
        } else {
          SetShowInProgress(false);
          // toast.error("No Data", {
          //   position: "top-center",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          // });
          console.log("kkanan", datavalerr.status)

          if (datavalerr?.status == 599 || datavalerr?.status == 500 || datavalerr?.status == 400 || datavalerr?.status == 404) {
            datavalerr.json().then(data => {
              setapiOpen(true);
              setapierrorMsg(data?.error)
              console.log('Resolved object:', data.error);
            })


          }
          else if (datavalerr?.status == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          console.error(
            "Failed to fetch PDF:",
            datavalerr.status,
            datavalerr.statusText
          );
        }

        SetShowInProgress(false);
      } catch (err) {
        console.log(err)
      }
    }
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });



  const apihandleClose = () => {
    setapiOpen(false);
  };


  const campaignreportNow = async () => {
    setsearchselectedRows(selectedRows)
    setfaqData(2)
    setToggle(false)
    if (selectedRows.length > 0) setIsOpen(true);
    else setsearchopen(true);
  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectcamptval.length <= 0 || selectsectionval.length <= 0) {
      errors.dateerror = "This field is required.";
      errs = 2;
      setLoading(false);
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      setValidation(errors);
      return false;
    }
    else {

      var keywordselectdata = searchselectedRows.map(
        (dataselectval) => dataselectval.keyword
      );
      setLoading(false);
    }
  };

  function closeModal() {
    setIsOpen(false);
  }




  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')) {
      setfaqData(2)
      setToggle(false)
    }
  });



  const freeAudit = async () => {

    const check = await Utils("standard_report", ApiToken);
    console.log("check1========>>>>>>>>>", check);
    // check.status = true;
    setfreesiteaudit(check);

  }


  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=59`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }


  useEffect(() => {

    freeAudit();
    faqdatafun();

  }, [])

  console.log("freesiteaudit====>>>>>", freesiteaudit)


  return (
    <div className={`over_all_container search_prnt ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Key Volume List </title>
      </Helmet>
      {
        loading === true ? <Loader /> : null
      }
      <div className="cnt_audit_outer_wrap search_key_wrap searchvolume_key_wrap">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Standard Report

            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font"> Standard Report</h2>

          </div>

          <Helmet>
            <meta charSet="utf-8" />
            <title> Standard Report </title>
          </Helmet>
        </div>


        {ShowInProgress == false && freesiteaudit.status ?
          <div className="plagarism_analyzer_row_standard">
            <div className="input_col search_location_with_tool">

              <label className="reportName">Report:</label>


              <div className="dummyselect">
                {/* <Select
          options={locationval}
          labelField="value"
          valueField="key"
          onChange={(values) => SetSectionNameHandle(values)}
          placeholder="Select Report"
        /> */}
                <Select
                  name="report"
                  options={locationval}
                  onChange={(e) => { SetSectionNameHandle(e) }}
                  placeholder="Select Report"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  className="standardreport_dropdown"
                  classNamePrefix="standardreport_dropdown"
                />
                <span style={{ color: "red" }}>{ErrorMsg}</span>
              </div>

            </div>
            <div className="button_col">
              <button className="runreportnew" onClick={AnalyzeReport}>
                Download
              </button>
            </div>

          </div> : <Freeaudit planStatus={freesiteaudit} />}



        <div className="error_plagarism_report msg_errormsg">

        </div>
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />

      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      {ShowInProgress == true ? (
        <div className="show_in_progress_brand_mention">
          <div className="wrapperAlert">
            <div className="contentAlert">
              <div className="topHalf">
                <p>
                  <img src={InprogressIcon} />
                </p>
                <h1>{InProgressMsg}</h1>

                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>
      ) : null}
    </div>
  );
}
