import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ESOIcon from "./Asset/New_Assert/ESO_ICON.png";
import ESOLogo from "./Asset/New_Assert/ESO_Logo.png";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect ,useContext, useRef } from "react";
import { faChevronRight, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../Context/Context";

import axios from "axios";
import { SetUserToken } from "../../Store/UserTokenSlice";
import { SetAuthToken } from "../../Store/ApiAuthKey"; 
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const location = useLocation();


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [isKeywordsHovered, setIsKeywordsHovered] = useState(false);
  const [isContentHovered, setIsContentHovered] = useState(false);
  const [isTechnicalHovered, setIsTechnicalHovered] = useState(false);
  const [isGoogleHovered, setIsGoogleHovered] = useState(false);
  const [isSettingsHovered, setisSettingsHovered] = useState(false);
  const [isreportHovered, setisReportHovered] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");

  useEffect(() => {
    console.log("window.innerWidth " , window.innerWidth)
    const sideMenuOuterWrap = document.querySelector(".side_menu_outer_wrap");
    const sideNavIconsList = document.querySelectorAll(".side_nav_icons");
    const sideNavTxtList = document.querySelectorAll(".side_nav_txt");
    const sideNavIcon = document.querySelectorAll(".Siteicon");
    const sideNavimage = document.querySelectorAll(".Siteimage");
    const navHoverCntWrapList = document.querySelectorAll(
      "span.nav_hover_cnt_wrap"
    );
    const dasKeyTechBackBrandList = document.querySelectorAll(
      ".das_outer_wrap, .key_tech_wrap, .back_brand_wrap"
    );

    let delayTimer; // Variable to store the timer ID

    sideMenuOuterWrap.addEventListener("mouseenter", function () {
      clearTimeout(delayTimer); // Clear any existing timer

      delayTimer = setTimeout(() => {
        sideNavTxtList.forEach((element, index) => {
          // element.style.transition = `opacity 3s ease ${index * 0.0}s`;
          setTimeout(() => {
            element.style.opacity = "1";
          }, 2);
          element.style.display = "block";
        });
        navHoverCntWrapList.forEach((element, index) => {
          // element.style.transition = `opacity 3s ease ${index * 0.0}s`;
          setTimeout(() => {
            element.style.opacity = "0";
          }, 2);
          element.style.display = "none";
        });
        sideNavIconsList.forEach((element) => {
          element.classList.add("nav_orange_icons");
        });
        dasKeyTechBackBrandList.forEach((element) => {
          element.style.width = "100%";
        });
        sideNavIcon.forEach((element) => {
          element.classList.add("hideme");
        });
        sideNavimage.forEach((element) => {
          element.classList.remove("Hide_meimg");
        });
      }, 300); // Adjust the delay time (in milliseconds) as needed
    });

    sideMenuOuterWrap.addEventListener("mouseleave", function () {
      clearTimeout(delayTimer); // Clear any existing timer

      delayTimer = setTimeout(() => {
        sideNavTxtList.forEach((element, index) => {
          // element.style.transition = `opacity 0.1ms ease ${index * 0.0}s`;
          setTimeout(() => {
            element.style.opacity = "0";
          }, 2);
          element.style.display = "none";
        });
        navHoverCntWrapList.forEach((element, index) => {
          // element.style.transition = `opacity 0.1ms ease ${index * 0.0}s`;
          setTimeout(() => {
            element.style.opacity = "1";
          }, 2);
          element.style.display = "block";
        });
        sideNavIconsList.forEach((element) => {
          element.classList.remove("nav_orange_icons");
        });
        dasKeyTechBackBrandList.forEach((element) => {
          element.style.width = "100%";
        });
        sideNavIcon.forEach((element) => {
          element.classList.remove("hideme");
        });
        sideNavimage.forEach((element) => {
          element.classList.add("Hide_meimg");
        });
      }, 300); // Adjust the delay time (in milliseconds) as needed
    });

    // Cleanup: Clear the timer when the component unmounts
    return () => {
      clearTimeout(delayTimer);
    };
  }, []);

  const mobileSideNavOut = useRef(null);

  const handleClickOutside = (event) => {
    if (mobileSideNavOut.current && !mobileSideNavOut.current.contains(event.target)) {
      setIsMenuExpanded(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    
    if (mediaQuery.matches) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (mediaQuery.matches) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, []);

  useEffect(() => {
    const selectedSec = sessionStorage.getItem('selectedSection');
    if(selectedSec){
      setSelectedSection(selectedSec);
      toggleSection(selectedSec, true);
    } else {
      sessionStorage.setItem('selectedSection', 'dashboard');
    }
  }, [])


  // const APP_KEY = 'myAppActiveTabs';

  // const decrementTabs = () => {
  //   const activeTabs = parseInt(localStorage.getItem(APP_KEY) || '0', 10);
  //   if (activeTabs > 0) {
  //       localStorage.setItem(APP_KEY, activeTabs - 1);
  //   }

  //   // Delay calling the function if it's the last tab
  //   if (activeTabs < 1) {
  //     TriggerLogout();
  //   }
  // };

  // const onPageReload = () => {
  //     console.log("Page reloaded!");
  //     if(document.visibilityState !== "hidden"){
  //       decrementTabs();
  //     }
  // };

  // const onTabClose = () => {
  //     console.log("Tab closed!");
  //     const activeTabs = parseInt(localStorage.getItem(APP_KEY) || '0', 10);
  //     if(activeTabs <= 1) {
  //       TriggerLogout();
  //     }
  //     decrementTabs();
  // };
  
  // let isReload = false;
  // let itsLoaded = false;
  // useEffect(() => {

  //   if(!itsLoaded){
  //     const activeTabs = parseInt(localStorage.getItem(APP_KEY) || '0', 10);
  //     localStorage.setItem(APP_KEY, activeTabs + 1);
  //     itsLoaded = true;
  //   }

  //   const handleBeforeUnload = () => {
  //     setTimeout(() => {
  //       isReload = true; // Assume reload initially
  //       // decrementTabs();
  //     }, 1000)
  //   };
  
  //   const handleUnload = () => {
  //       if (isReload) {
  //           onPageReload();
  //       } else {
  //           onTabClose();
  //       }
  //   };
  
  //   const handleVisibilityChange = () => {
  //       if (document.visibilityState === "hidden") {
  //           isReload = false; // Assume close when visibility changes
  //       }
  //   };

  //   // Add event listeners
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("unload", handleUnload);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   // Cleanup on component unmount
  //   return () => {
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //       window.removeEventListener("unload", handleUnload);
  //       document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  const dispatch = useDispatch();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.usertoken.value.eso_user_token;

  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {
        
        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };



  const NavigateTo = (e) => {
    navigate(e.target.getAttribute("data-link"));
  };


  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);  
  const toggleMenu = () => {
    setIsMenuExpanded(!isMenuExpanded);
};
    const closeMenu = (currentSection) => {
      // setIsMenuExpanded(false);  
      setSelectedSection(currentSection);
      toggleMenu();
      sessionStorage.setItem('selectedSection', currentSection);
    };

    const toggleSection = (sectionSelected, toggle) => {
      const newState = {
        keywords: false,
        content: false,
        technical: false,
        googleAnalytics: false,
        report: false,
        settings: false
      };
      newState[sectionSelected] = toggle;

      setIsKeywordsHovered(newState.keywords);
      setIsContentHovered(newState.content);
      setIsTechnicalHovered(newState.technical);
      setIsGoogleHovered(newState.googleAnalytics);
      setisReportHovered(newState.report);
      setisSettingsHovered(newState.settings);
    }
  //   useEffect(() => {
  //     if (isMenuExpanded) {
  //         document.over_all_container.style.overflow = 'hidden'; // Disable scrolling
  //     } else {
  //         document.body.style.overflow = 'unset'; // Enable scrolling
  //     }
  // }, [isMenuExpanded]);
    // document.addEventListener("DOMContentLoaded", () => {
    //   const hamburger = document.querySelector("#hamburger");
    //   const menu = document.querySelector("#menu");
    //   const body = document.querySelector("body");
    
    //   // Check if the elements are found in the DOM
    //   if (hamburger && menu && body) {
    //     hamburger.addEventListener("click", () => {
    //       hamburger.classList.toggle("active");
    //       menu.classList.toggle("open");
    //       body.classList.toggle("fixed");
    //       body.classList.toggle("blur");
    //     });
    //   }
    // });
  
  return (
    
    <>
    
    <div ref={mobileSideNavOut} id="menu"  className={`side_menu_outer_wrap  ${isMenuExpanded ? "expanded" : ""}`}>
   
   <div className={`${isMenuExpanded ? "transform" : ""}`}>
    <button
    id="hamburger"
      className="menu_toggle_btn"
      onClick={toggleMenu}
      aria-label="Toggle navigation"
      aria-expanded={isMenuExpanded}
    >
      <FontAwesomeIcon icon={isMenuExpanded ? faBars : faBars} />
    </button>
    </div>
    {/* Side Menu Inner Wrap */}
    <div className={`side_menu_inner_wrap side-nav ${isMenuExpanded ? "expanded overflow-auto" : ""}`}>
                <div class="Logo_wrap">
          <img src={ESOIcon} alt="ESO Logo" class="Siteicon"></img>
          <img src={ESOLogo} alt="ESO Logo" class="Siteimage Hide_meimg"></img>
        </div>
        <div className="das_outer_wrap">
          <span className={`nav_das nav_out_wrap ${selectedSection=='dashboard' ? "nav-sections" : ""}`}>
            <span className="nav_das_icon side_nav_icons"></span>
            <NavLink
              className="nav_das_txt side_nav_txt nav_txt_otr_wrap"
              to="/"
              onClick={() => {
                closeMenu('dashboard')
                toggleSection('singleMenu', false)
              }}
            >
              <span>Dashboard</span>
            </NavLink>
          </span>
        </div>
        <div className="key_tech_wrap">
          <span className="nav_hover_cnt_wrap">On-page</span>
          <span
            className={`nav_key nav_out_wrap ${
              isKeywordsHovered ? "hovered" : ""
            }`
          }
            // onMouseEnter={() => setIsKeywordsHovered(true)}
            // onMouseLeave={() => setIsKeywordsHovered(false)}
          >
              <span className="nav_key_icon side_nav_icons"></span>
            <span className="nav_txt_outer_wrap">
              <span className="nav_key_txt side_nav_txt nav_txt_otr_wrap"
                onClick={() => toggleSection('keywords', !isKeywordsHovered)}>
                <span className={selectedSection=='keywords' ? "nav-sections" : ""}>Keywords&nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="nav_key_icon"
                /></span>
              </span>
              <span
                className={`sub_nav_key_wrap ${
                  isKeywordsHovered ? "visible" : "hidden"
                }`}
              >
                <NavLink
                  className="side_nav_txt sub_nav_key_txt"
                  to="/keywords"
                  onClick={() => closeMenu('keywords')}
                >
                  Targeted Keywords
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_key_txt"
                  to="/ranking-summary"
                  onClick={() => closeMenu('keywords')}
                >
                  Ranking Summary
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_key_txt"
                  to="/searchkey-volume"
                  onClick={() => closeMenu('keywords')}
                >
                  Search Volume
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_key_txt"
                  to="/competitor-ranking"
                  onClick={() => closeMenu('keywords')}
                >
                  Competitor Rankings
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_key_txt"
                  to="/missed-opportunity"
                  onClick={() => closeMenu('keywords')}
                >
                  Missed Opportunities
                </NavLink>
              </span>
            </span>
          </span>

          <span
            className={`nav_cntt nav_out_wrap ${
              isContentHovered ? "hovered" : ""
            }`}
            // onMouseEnter={() => setIsContentHovered(true)}
            // onMouseLeave={() => setIsContentHovered(false)}
          >
            <span className="nav_cntt_icon side_nav_icons"></span>
            <span className="nav_txt_outer_wrap">
              <span className="nav_cntt_txt side_nav_txt nav_txt_otr_wrap"
                onClick={() => toggleSection('content', !isContentHovered)}>
                <span className={selectedSection=='content' ? "nav-sections" : ""}>Content&nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="nav_cntt_icon"
                /></span>
              </span>
              <span
                className={`sub_nav_cntt_wrap ${
                  isContentHovered ? "visible" : "hidden"
                }`}
              >
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/analysis-summary"
                  onClick={() => closeMenu('content')}
                >
                  Analysis Summary
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/spell-checker"
                  onClick={() => closeMenu('content')}
                >
                  Spell Checker
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/plagiarism-checker"
                  onClick={() => closeMenu('content')}
                >
                  Plagiarism Checker
                </NavLink>
              </span>
            </span>
          </span>

          <span
            className={`nav_tech nav_out_wrap ${
              isTechnicalHovered ? "hovered" : ""
            }`}
            // onMouseEnter={() => setIsTechnicalHovered(true)}
            // onMouseLeave={() => setIsTechnicalHovered(false)}
          >
            <span className="nav_tech_icon side_nav_icons"></span>
            <span className="nav_txt_outer_wrap">
              <span className="nav_tech_txt side_nav_txt nav_txt_otr_wrap"
                onClick={() => toggleSection('technical', !isTechnicalHovered)}>
                <span className={selectedSection=='technical' ? "nav-sections" : ""}>Technical&nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="nav_tech_icon"
                /></span>
              </span>
              <span
                className={`sub_nav_tech_wrap ${
                  isTechnicalHovered ? "visible" : "hidden"
                }`}
              >
                <NavLink
                  className={ location.pathname === "/site-audit/1/" || location.pathname === "/onpage-crawl-data/2/1" || location.pathname === "/page-health/3/" || location.pathname === "/competitor-analysis/4/" ? "side_nav_txt sub_nav_tech_txt active" :"side_nav_txt sub_nav_tech_txt"}
                  to="/site-audit/1"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Site Analysis
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_tech_txt"
                  to="/schema-analysis"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Schema Analysis
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_tech_txt"
                  to="/performance"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Performance
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_tech_txt"
                  to="/source-code"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Source Code Analysis
                </NavLink>
                <NavLink
                  className={ location.pathname === "/accessibility-score" || location.pathname === "/accessibility-summary" || location.pathname === "/accessibility-report" || location.pathname === "/accessibility-file" ? "side_nav_txt sub_nav_tech_txt active":"side_nav_txt sub_nav_tech_txt"}
                  to="/accessibility-score"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                 Accessibility Reports
                </NavLink>
                {/* <NavLink
                  className="side_nav_txt sub_nav_tech_txt"
                  to="/accessbility-report-list"
                  exact
                >
                  Accessibility Report
                </NavLink> */}
                <NavLink
                  className={ location.pathname === "/internal-link-checker" || location.pathname === "/external-link-checker" ? "side_nav_txt sub_nav_tech_txt active" : "side_nav_txt sub_nav_tech_txt"}
                  to="/internal-link-checker"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Links
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_tech_txt"
                  to="/regression-test"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Regression Test
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_tech_txt"
                  to="/page-index-report"
                  exact
                  onClick={() => closeMenu('technical')}
                >
                  Page Index Report
                </NavLink>
              </span>
            </span>
          </span>
        </div>
        <div className="back_brand_wrap">
          <span className="nav_hover_cnt_wrap">off-page</span>
          <span className="nav_balink nav_out_wrap">
            <span className="nav_balink_icon side_nav_icons"></span>
            <NavLink
              className="nav_balink_txt side_nav_txt nav_txt_otr_wrap"
              to="/backlinks"
              onClick={() => {
                closeMenu('backlinks')
                toggleSection('singleMenu', false)
              }}
            >
              backlinks
            </NavLink>
          </span>
          {/* <span className="nav_scl_mdl nav_out_wrap">
            <span className="nav_scl_mdl_icon side_nav_icons"></span>
            <NavLink
              className="nav_scl_mdl_txt side_nav_txt nav_txt_otr_wrap"
              to="/social"
            >
              social media
            </NavLink>
          </span> */}
          <span className="nav_bran_rev nav_out_wrap">
            <span className="nav_bran_rev_icon side_nav_icons"></span>
            <NavLink
              className="nav_bran_rev_txt side_nav_txt nav_txt_otr_wrap"
              to="/brand-mentions"
              onClick={() => {
                closeMenu('brandMentions')
                toggleSection('singleMenu', false)
              }}
            >
             Brand Mentions
            </NavLink>
          </span>
        </div>
        <div className="google_set_wrap">
          <span
            className={`nav_cntt nav_out_wrap ${
              isGoogleHovered ? "hovered" : ""
            }`}
            // onMouseEnter={() => setIsGoogleHovered(true)}
            // onMouseLeave={() => setIsGoogleHovered(false)}
          >
            <span className="nav_cntt_icon side_nav_icons"></span>
            <span className="nav_txt_outer_wrap">
              <span className="nav_cntt_txt side_nav_txt nav_txt_otr_wrap"
                onClick={() => toggleSection('googleAnalytics', !isGoogleHovered)}>
                <span className={selectedSection=='googleAnalytics' ? "nav-sections" : ""}>Google analytics&nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="nav_cntt_icon"
                /></span>
              </span>
              <span
                className={`sub_nav_cntt_wrap ${
                  isGoogleHovered ? "visible" : "hidden"
                }`}
              >
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/organic-search-traffic"
                  onClick={() => closeMenu('googleAnalytics')}
                >
                  Analytics Report
                </NavLink>

                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/google-console/brand-vs-non-brand"
                  onClick={() => closeMenu('googleAnalytics')}
                >
                  Brand Vs Non Brand
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/google-console/top-performing-pages"
                  onClick={() => closeMenu('googleAnalytics')}
                >
                  Top Performing Pages
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/google-console/search-queries"
                  onClick={() => closeMenu('googleAnalytics')}
                >
                  {" "}
                  Search Queries
                </NavLink>
              </span>
            </span>
          </span>
          <span className="nav_act_itm nav_out_wrap">
            <span className="nav_act_itm_icon side_nav_icons"></span>
            <NavLink
              className="nav_act_itm_txt side_nav_txt nav_txt_otr_wrap"
              to="/top-action-item"
              onClick={() => {
                closeMenu('topActionItems')
                toggleSection('singleMenu', false)
              }}
            >
              Top action items
            </NavLink>
          </span>
          <span className="nav_site_gen nav_out_wrap">
            <span className="nav_site_gen_icon side_nav_icons"></span>
            <NavLink
              className="nav_site_gen_txt side_nav_txt nav_txt_otr_wrap"
              to="/sitemap-generator"
              onClick={() => {
                closeMenu('sitemapGenerator')
                toggleSection('singleMenu', false)
              }}
            >
              sitemap generator
            </NavLink>
          </span>

          <span
            className={`nav_cntt nav_out_wrap ${
              isreportHovered ? "hovered" : ""
            }`}
            // onMouseEnter={() => setisReportHovered(true)}
            // onMouseLeave={() => setisReportHovered(false)}
          >
            <span className="nav_cntt_icon side_nav_icons"></span>
            <span className="nav_txt_outer_wrap">
              <span className="nav_site_gen_txt side_nav_txt nav_txt_otr_wrap"
                onClick={() => toggleSection('report', !isreportHovered)}>
                <span className={selectedSection=='report' ? "nav-sections" : ""}>Report&nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="nav_cntt_icon"
                /></span>
              </span>
              <span
                className={`sub_nav_cntt_wrap ${
                  isreportHovered ? "visible" : "hidden"
                }`}
              >
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/Custom-Report"
                  onClick={() => closeMenu('report')}
                >
                  Custom Report
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/standard-Report"
                  onClick={() => closeMenu('report')}
                >    
                 Standard Report
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/report-history"
                  onClick={() => closeMenu('report')}
                >
                  Report History
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/error-report"
                  onClick={() => closeMenu('report')}
                >
                  Consolidated Error
                </NavLink>
              </span>
            </span>
          </span>
        
      

          <span
            className={`nav_cntt nav_out_wrap ${
              isSettingsHovered ? "hovered" : ""
            }`}
            // onMouseEnter={() => setisSettingsHovered(true)}
            // onMouseLeave={() => setisSettingsHovered(false)}
          >
            <span className="nav_set_icon side_nav_icons"></span>
            <span className="nav_txt_outer_wrap">
              <span className="nav_cntt_txt side_nav_txt nav_txt_otr_wrap"
                onClick={() => toggleSection('settings', !isSettingsHovered)}>
                <span className={selectedSection=='settings' ? "nav-sections" : ""}>Settings&nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="nav_cntt_icon"
                /></span>
              </span>
              <span
                className={`sub_nav_cntt_wrap ${
                  isSettingsHovered ? "visible" : "hidden"
                }`}
              >
                 <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/user-list"
                  onClick={() => closeMenu('settings')}
                >
                  User Management
                </NavLink>
                <NavLink
                  className={location.pathname === "/site-audit-priority" || location.pathname === "/top-action-priority" ? "side_nav_txt sub_nav_tech_txt active":"side_nav_txt sub_nav_cntt_txt"}
                  exact
                  to="/site-audit-priority"
                  onClick={() => closeMenu('settings')}
                >
                  Priority Settings
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/domain-list"
                  onClick={() => closeMenu('settings')}
                >
                  Domain Management
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/domain-section-list"
                  onClick={() => closeMenu('settings')}
                >
                  Domain Section
                </NavLink>
                <NavLink
                  className={ location.pathname === "/user-experience-setting" || location.pathname === "/page-level-setting" ? "side_nav_txt sub_nav_tech_txt active" : "side_nav_txt sub_nav_cntt_txt"}
                  exact
                  to="/user-experience-setting"
                  onClick={() => closeMenu('settings')}
                >
                Performance settings
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/competitor-settings"
                  onClick={() => closeMenu('settings')}
                >
                Competitor settings
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/spell-checker-settings"
                  onClick={() => closeMenu('settings')}
                >
                Spell checker settings
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/regional-level-settings"
                  onClick={() => closeMenu('settings')}
                >
                Regional Level Settings
                </NavLink>
                <NavLink
                  className="side_nav_txt sub_nav_cntt_txt"
                  exact
                  to="/link-checker-settings"
                  onClick={() => closeMenu('settings')}
                >
                link checker settings
                </NavLink>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>

    </>
  );
}
