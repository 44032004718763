import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Select from 'react-select';
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";
import "./../style.css";

import { Link, useParams } from "react-router-dom";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import ReactSelect from 'react-select';
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { Breadcrumbs, Typography } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { datezonechange } from "./../Commonfun"

import { AppContext } from "../../../Context/Context";
import zIndex from "@mui/material/styles/zIndex";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


export default function AccessibilityList() {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [jobidValue, setjobValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [accessbilityScorePlan, setaccessbilityScorePlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState([]);
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([])
  const [Domainsectiondata, SetDomainsectiondata] = useState([])
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [accessbilityModel, setaccessbilityModel] = useState(false);
  const [contentType, setcontentType] = useState([]);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);

  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value
  let detailurl = `${process.env.REACT_APP_DOMAIN}accessibility-score-detail/`;

  const contentTypeOptions = [
    { label: 'WCAG', value: 'wcagtype' },
    { label: 'Section type 508', value: '508Type' },
    { label: 'ADA', value: 'adaType' }
  ]





  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );



  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=21`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }

  const checkUserPlan = async () => {
    const accessbilityScoreP = await Utils("accessibility_score", ApiToken);
    if (accessbilityScoreP.status) {
      GetFilterOptions();
      LoadAddFormFilterOptions();
      faqdatafun()
    }
    setaccessbilityScorePlan(accessbilityScoreP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domaindata`;
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  const columns = [

    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },

    {
      field: "link__url",
      headerName: "URL",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: '',
      disableClickEventBubbling: true,
      description: "URL of the web page",
      renderCell: (cardData) => {

        let baseUrl = cardData.row.link__url.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = cardData.row.link__url.split("/").slice(3).join("/");

        return (
          <>
            <Tooltip title={cardData.row.link__url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.link__url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" style={{ color: "#044792" }} >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink" style={{ color: "#044792", wordWrap: "break-word", wordBreak: "break-word" }}>{remaining}</span>
                </a>
              </div>
            </Tooltip>
          </>
        );

      },
    },
    {
      field: "warning_count",
      headerName: "Warning Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Total number of non critical issues.'

    },
    {
      field: "error_count",
      headerName: "Error Count",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      description: 'Total number of critical issues.'

    },
    {
      field: "view",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {


        return (
          <a href={detailurl + `${cardData.row.id}` + "/" + jobidValue}>
            <button className="view-report-button">
              View Report
              {/* <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              /> */}
            </button>
          </a>
        );
      },
    },

  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "", SelectedWcagType = "", domainsecdata = "") => {
    setLoading(true);
    if (domainsecdata == "")
      domainsecdata = Domainsectiondata;
    if (jobid == "") {
      jobid = selectval;
    }

    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/accessibility_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}&wcag_type=WCAG2${SelectedWcagType}&domain_section_id=${domainsecdata}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      const dataval = datavalue?.data?.table_data;
      console.log("dataval", dataval)
      if (datavalue?.data?.job_date)
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      else
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"))
      // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

      if (dataval && dataval.length > 0) {
        SetAutoheightval(false)
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });


        setCardData(newData);
        setjobValue(datavalue?.data?.job_id)

      }
      else {
        setCardData("")
        SetAutoheightval(true)
      }
    }
    catch (err) {
      console.log('err', err)
    }


    setLoading(false);
  };

  const reportNow = async () => {
    console.log("kananaccess", selectval)
    var errs = 1;
    let errors = {};

    if (selectval == "" || SelectedWcagType == '') {
      errors.dateerror = "All Fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }

    setIsOpen(false);
    setprevdEngineoption(dEngineoption);
    setLoading(true);


    var domainsec = "";
    callUserdataApi("", SelectedWcagType, domainsec = "")

  };
  function openModal() {
    if (accessbilityScorePlan.status) {
      setfaqData(2)
      setIsOpen(true);
    }
  }
  function closeModal() {
    setIsOpen(false);
    SetdEngineoption(prevdEngineoption);


  }

  const [WcagType, SetWcagType] = useState([])
  const [SelectedWcagType, SetSelectedWcagType] = useState('AA')
  const [DefaultSelectedWcagType, SetDefaultSelectedWcagType] = useState([])
  const GetFilterOptions = async () => {
   
    setcompletedate([])
    try {
      const FetchList = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=accessibility`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (FetchList?.data) {
        var Selectedmets = [];
        if (FetchList?.data?.jobs.length > 0) {
          FetchList?.data?.jobs.forEach((element) => {
            Selectedmets.push({ value: element?.id, label: datezonechange(element?.completed_at) });
          });
          SetdEngineoption(Selectedmets[0])
          setprevdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets)
          console.log("avane", FetchList?.data?.jobs)
          setSelectval(Selectedmets[0]?.value)
          callUserdataApi(Selectedmets[0]?.value, 'AA', '');
        }
        else{
          setapiOpen(true);
          setapierrorMsg("No report available")
      }
      }
     

      var WcagTypeArray = [];
      WcagTypeArray.push({ label: 'A', value: 'A' })
      WcagTypeArray.push({ label: 'AA', value: 'AA' })
      WcagTypeArray.push({ label: 'AAA', value: 'AAA' })

      SetWcagType(WcagTypeArray)
      SetDefaultSelectedWcagType(WcagTypeArray[1])

    } catch (err) {
      console.log('err', err)
    }

  }

  const OnsetSelectval = (values) => {
    setSelectval(values.value)

  }
  const OnSetSelectedWcagType = (values) => {
    SetDefaultSelectedWcagType(values)
    SetSelectedWcagType(values?.value)
    var domainsec = "";
    callUserdataApi("", values?.value, domainsec = "")
  }
  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('accessibility')

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });


  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const pdfdown = async () => {
    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&wcag_type=WCAG2${SelectedWcagType}&domain_section_id=${Domainsectiondata}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityList", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;

        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}technical/accessibility_summarydata/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobidValue}&wcag_type=WCAG2${SelectedWcagType}&domain_section_id=${Domainsectiondata}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("AccessibilityList", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;

        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {

          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.domain_sections)
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          // SetDomainsectiondata(Sections[0].value);
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  }
  const SetSectionNameHandle = (passvalue) => {
    SetDomainsectiondata(passvalue.value)
    callUserdataApi("", SelectedWcagType, passvalue.value);
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };

  return (
    <>
      {loading === true ? <Loader /> : null}

      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Accessibility Score</title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Technical</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Accessibility Score Analysis</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />
              </button>
              {accessbilityScorePlan.status &&
                <>
                  <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                  <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
                </>
              }
            </div>
            {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <div style={{ display: "flex" }}>
                <h2 className="heading_font" style={{ letterSpacing: "0.8px", fontWeight: 900, color: "#ff601f" }}>Accessibility Score Analysis</h2>
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" onClick={openModal} />
                </span>
              </div>
              <p style={{ fontSize: "14px", letterSpacing: "0.8px", color: "#ff601fc2" }}>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 700 }}>  {timezoneval()} </span></p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              {accessbilityScorePlan.status &&
                <>
                  <Select
                    classNamePrefix="access-score-dropdown"
                    options={WcagType}
                    placeholder="Select..."
                    value={DefaultSelectedWcagType}
                    onChange={(values) => OnSetSelectedWcagType(values)}
                    isSearchable="true"
                    styles={customDropdownStyles()}
                    menuPortalTarget={document.body}
                  />
                  {SectionOptions?.length > 0 && (
                    <div title="Domain Section">
                      <Select
                        options={SectionOptions}
                        name="name"
                        onChange={SetSectionNameHandle}
                        placeholder="Domain Section"
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                        classNamePrefix="common-domain-dropdown"
                      />
                    </div>
                  )}
                </>
              }
              <button
                title="Run Report"
                className="runreportnew over_all_accessibility runreportnew"
                onClick={() => {
                  if (first.statusDomain) {
                    setcheckD(first.statusDomain);
                  } else {
                    SetRunReportPopShow(true);
                  }
                }}

                disabled={!accessbilityScorePlan.status}
              >
                Run Report Now
              </button>
            </div>
          </div>

          <div className="center_tab accessbility_button_tap">
            <Link to="/accessibility-score" ><button type="button" className={`runreportnews active`}  >Score</button></Link>
            <Link to="/accessibility-summary" ><button type="button" className={`runreportnews`}  >Summary</button></Link>

            <Link to="/accessibility-report" ><button type="button" className={`runreportnews`}  >Report</button></Link>
            <Link to="/accessibility-file" ><button type="button" className={`runreportnews`}  >File Accessibility</button></Link>
          </div>
          {accessbilityScorePlan.status ?
            <div>
              <div className="data_table accessbility_score_table" style={{ width: "100%" }}>
                <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                  <DataGrid
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      noResultsOverlay: MyCustomNoRowsOverlay,
                      footer: () => (
                        <Box className="table-footer-container">
                          <Box className="table-footer-pagination">
                            <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                          </Box>
                          <MSelect
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            size="small"
                            className="table-footer-select"
                          >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={30}>30 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={75}>75 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                          </MSelect>
                        </Box>
                      ),
                    }}
                    slotProps={{
                      toolbar: {

                        showQuickFilter: true,

                      },
                    }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                    rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                    columns={columns}
                    pagination
                    // pageSizeOptions={[5, 10, 20]}
                    disableVirtualization
                    getRowHeight={() => 'auto'}
                    autoHeight={autoheightval ? true : false}
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 10,
                  //     },
                  //   },
                  // }}
                  // pageSizeOptions={[10, 30, 50, 75, 100]}
                  //loading={loading}
                  />
                </div>
              </div>
            </div> : <Freeaudit planStatus={accessbilityScorePlan} />
          }




          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="competitor_test_filter"
          >
            <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="cnt_hdr_blw_wrap row">

                  <div>

                    <div className="" style={{ marginBottom: '10px' }}>
                      <div>
                        <span>Completed Date </span>
                        <Select
                          value={dEngineoption}
                          options={completedate}
                          placeholder="Select..."
                          labelField="completed_at"
                          valueField="id"
                          onChange={(values) => {
                            OnsetSelectval(values)
                            SetdEngineoption(values);
                          }}
                          styles={customDropdownStyles()}
                          menuPortalTarget={document.body}
                        />
                      </div>


                    </div>



                    <div className="col">
                      <span className="text-error">{validation.dateerror}</span>
                    </div>



                  </div>

                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
                Submit
              </button>
              <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
            </DialogActions>
          </Dialog>



          {!openCommonAlerts && <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                No Data Found
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>}
          {!openCommonAlerts && <Dialog
            open={apiopen}
            onClose={apihandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                borderRadius: "18px !important",
                borderColor: "#ff601f !important",
                top: "10px !important",
                // width: '100%',
                // boxShadow:"0px 15px 10px 0px #fff !important"
              },
            }}

          >
            <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} />
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <DialogContentText id="alert-dialog-slide-description">
                {apierrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
              <Button onClick={apihandleClose}>Close</Button>
            </DialogActions>
          </Dialog>}
          {/* open accessbility score model  */}
          <Dialog
            open={accessbilityModel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableScrollLock
          >
            <DialogTitle className="keyword-dialog-header">{"Accessibility Guildlines Type"}</DialogTitle>
            <DialogContent>
              <ReactSelect
                name="contentType"
                value={contentType == '' ? null : contentType}
                options={contentTypeOptions}
                onChange={(e) => {
                  setcontentType(e);
                  setaccessbilityModel(false);
                }}
                placeholder="Select Type"
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
                className="accessibility_type"
                classNamePrefix="accessibility_type"
                style={{ width: 'inherit' }}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={checkD}
            // onClose={() => setOpenCommonAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="domain-verify-alert"
          >
            <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Please verify your domain to access these features
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
              <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
            </DialogActions>
          </Dialog>
        </div>
        <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
      </div>
    </>
  );
}
