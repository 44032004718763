import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";

function Domainownership(props) {

  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [apisuccessopen, setapisuccessopen] = React.useState(false);
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  // let querystring = useParams();
  let domain_id = ""

  if (props.selectedRows)
    domain_id = props.selectedRows;
  else
    domain_id = esodata.domaindata.value.header_selected;


  useEffect(() => {
    callUserdataApi();
  }, []);
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const apisuccesshandleClose = () => {
    setapisuccessopen(false);
    navigate('/domain-google_analytics/')
  }
  const nextButtonClick = async () => {

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/ownership_token_verify/?user_domain_id=${domain_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data?.status_code == 200) {
        console.log("errorvafsdfsl")
        setapisuccessopen(true);
        setapierrorMsg(datavalue?.data?.message)
      }

    } catch (err) {
      if (err.response.data.status_code === 408) {

        setapiOpen(true);
        setapierrorMsg(err?.response?.data?.error)
        // toast.error(err.response.data.error, {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });

        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 2000);


      }
      else {

        // toast.error(err.response.data.error, {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        setapiOpen(true);
        setapierrorMsg(err?.response?.data?.error)

      }

    }



  }
  const skipButtonclick = () => {
    // navigate('/domain-list')
    props.setAddEditDomainPopup(false);
  }
  const handleCopyClick = () => {
    copy(text);
    toast.success('Successfully copied to clipboard', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const domain_owber = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/ownership_token_generate/?user_domain_id=${domain_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setText(domain_owber?.data?.domain_verification_token)

    } catch (err) { }
    setLoading(false);
  };
  return (
    <div className={`${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="">
        {/* <div className="cnt_hdr_top_rgt_wrap" style={{display:"flex" , justifyContent:"flex-end"}}>
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} style={{padding:"20px"}} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question" style={{padding:"10px"}}>
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
           
          </div>
        
        </div> */}

        {/* <h1>Domain Ownership Verification</h1> */}
        <div className="">
          <p style={{ textAlign: "center", marginBottom: "35px" ,fontWeight:"700"}}>Verify Your Website Ownership</p>
          <div>
            <div>1) Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)</div>
            <div>2) Copy the TXT record below into the DNS configuration for your website</div>
            <div>3) Press Next button below</div>
            <div className="copy-url-container">
              <input
                type="text"
                className="copy-text-field"
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled
              />
              <button className="copybutton" onClick={handleCopyClick}>
                Copy
              </button>
            </div>
            <div className="domain_input_row_note">
              Note: Usually After Changing TXT Record Will Take Effect Only After 60 Secs
              This Token will Expire In 7 Days.
            </div>
          </div>
          <div className="keyword-dialog-buttons">
            <button
              className="keyword-cancel"
              type="button"
              onClick={skipButtonclick}
            >
              Close
            </button>
            <button
              className="keyword-submit"
              type="button"
              onClick={nextButtonClick}
            >
              Submit
            </button>
          </div>

          {/* <div class="form-group domain_owner">
          <label>1) Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)</label>
        </div>
        <div class="form-group domain_owner domain_ownertext domain_input_row">
            <label>2) Copy the TXT record below into the DNS configuration for your website</label>
            <label>3) Press Next button below</label>
            <input type="text"  value={text} onChange={(e) => setText(e.target.value)} disabled/>
            <button className="copybutton" onClick={handleCopyClick}>
                Copy 
            </button>
        </div>
        <div className="domain_input_row_note">
          Note: Usually After Changing TXT Record Will Take Effect Only After 60 Secs
          This Token will Expire In 7 Days.
        </div>
         <div className="form_next">  
         <button class="nextbutton" type="button" onClick={skipButtonclick}>Cancel</button>    
            <button className="nextbutton" type="button" onClick={nextButtonClick}>Submit</button>
         </div>    */}

        </div>
      </div>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={apisuccessopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apisuccesshandleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Domainownership;


