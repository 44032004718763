import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate, Link } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Breadcrumbs } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
export default function Plagiarisamlist() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [AvailablePoints, SetAvailablePoints] = useState(0)
  const [initialDateval, SetInitialDateval] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [sizedata, setSizedata] = useState(550)
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apiStatusCode, setApiStatusCode] = React.useState(null);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [errmsg, setErrmsg] = useState("")
  const [plagiarismPlan, setplagiarismPlan] = useState({ status: true, error: "" });
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [first, setfirst] = useState({ name: "", path: "", closeName: "Close", statusDomain: false });
  const [checkD, setcheckD] = useState(false);
  const [domainNameUrl, setDomainNameUrl] = useState({domain_name:'',domain_url:''});

  const navigate = useNavigate();

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "#0b2033";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );
  const apihandleClose = () => {
    SetAnalyzeUrl("")
    setapiOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-detail/`;

  const checkUserPlan = async () => {
    const plagiarismP = await Utils("plagiarism", ApiToken);
    if (plagiarismP.status) {
      callUserdataApi();
      faqdatafun();
    }
    setplagiarismPlan(plagiarismP);
  };

  useEffect(() => {
    checkUserPlan();
    GetuserDatavalue();
  }, []);

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages');
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data?.dor_domain_name && datavalue?.data?.dor_domain_url) {
        setDomainNameUrl({ ...domainNameUrl, domain_name: datavalue?.data?.dor_domain_name, domain_url: datavalue?.data?.dor_domain_url });
      }

      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          setfirst({ ...first, name: "Verify Now", path: "/Userdata", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domaindata`;
          setfirst({ ...first, name: "Verify Now", path: "/Impadddomain", statusDomain: true });
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
          setfirst({ ...first, name: "Verify Now", path: "/Domainownership", statusDomain: true });
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',

    },
    {
      field: "dateOfRequest",
      headerName: "Date of Request",
      description: 'The date on which the report has been initiated.',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {
        return moment(cardData.row.date_of_request).format("MMM DD,YYYY ");
      },
    },
    {
      field: "request_url",
      headerName: "URL Checked",
      description: 'The web page URL for which the content is to be checked for plagiarism or duplicates.',
      minWidth: 350,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (cardData) => {
        let firstEightLetters = "";
        let lastThreeLetters = "";
        let wholedata = "";
        // if (cardData.row.request_url.length > 20) {
        //   firstEightLetters = cardData.row.request_url.substring(0, 20);
        //   lastThreeLetters = cardData.row.request_url.slice(-5);
        //   wholedata = firstEightLetters + '...' + lastThreeLetters;
        // }
        // else {
        firstEightLetters = cardData?.row?.request_url;
        wholedata = firstEightLetters;

        let baseUrl = cardData.row.request_url.split("/").slice(0, 3).join("/") + "/"; // This will give the part before the third slash
        let remaining = cardData.row.request_url.split("/").slice(3).join("/");
        // }


        return (
          <>
            <Tooltip title={cardData.row.request_url}>
              <div className="prefered_url_external02">
                <a className="table_links" target="_blank" href={cardData.row.request_url} style={{ display: 'flex', flexDirection: 'column' }} >
                  <span className="spanlink" >
                    <LinkIcon /> {baseUrl}
                  </span>
                  <span className="spanlink">{remaining}</span>
                </a>
              </div>

            </Tooltip>
          </>
        );

      },

    },

    {
      field: "total_words",
      headerName: "Total Words",
      description: 'The total number of word counts is shown.',
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",


    },
    {
      field: "similarity_found",
      headerName: "Total Similarity Words",
      description: 'It displays a total number of similarity words found.',
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "matching_words",
      headerName: "Total Matched Words",
      description: 'A total number of matching words will be found.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "similarity_score",
      headerName: "Similarity Score",
      description: 'The percentage of content similarity for the content used in a page from the internet resources.',
      minWidth: 200,
      flex: 1,

      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        var sr = cardData.row.similarity_score;
        var s = sr.split("%");
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s[0]}
            percent="%"
            textSize={1.2}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: textfillColor,
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      description: 'Click below Button to view detailed report',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {
        if (cardData.row.status !== "completed") return cardData.row.status;

        return (
          <a href={detailurl + `${cardData.row.id}`}>

            <button className="view-report-button">
              View Report
            </button>

          </a>
        );
      },
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);




    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (jobidData == "") {
        const completevalues = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=plagiarism&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        setcompletedate(completevalues.data.jobs);
      }
      const dataval = datavalue?.data?.result;
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

      if (dataval && dataval.length > 0) {
        const newData = dataval.map((obj, index) => {
          // renderCell: (cardData) => {
          //   return moment(cardData.row.date_of_request).format("MMM DD,YYYY ");
          // },
          // return { ...obj, sid: index + 1 }; 
          // obj.date_of_request = moment(date_of_request).format("MMMM DD,YYYY HH:mm")
          return { ...obj, sid: index + 1, dateOfRequest: moment(obj.date_of_request).format("MMMM DD,YYYY HH:mm") }; 
        });

        setCardData(newData);

        SetAutoheightval(false)

      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }



      //available points



      const Pointsdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/get_plagiarism_points/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (Pointsdatavalue.data) {

        SetAvailablePoints(Pointsdatavalue.data.available_points)
      }





      //available points end

    } catch (err) {
      console.log('err', err)
    }




    setLoading(false);
  };

  const reportNow = async () => {

    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "Enter URL";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setLoading(true);
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const [AnalyzeUrl, SetAnalyzeUrl] = useState('')
  const [ErrorMsg, SetErrorMsg] = useState('')

  const AnalyzeReport = async () => {

    setErrmsg("");
    setLoading(true)
    if (AnalyzeUrl !== '') {
      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}content/run_plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&url=${AnalyzeUrl}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

        if (datavalue?.data) {
          if (datavalue?.data?.status_code == 200) {
            setapiOpen(true);
            setApiStatusCode(200);
            setapierrorMsg(datavalue?.data?.message)
          }
        }

      } catch (err) {

        if (err.response.data.error) {
          SetErrorMsg(err.response.data.error)
        }
        else {
          SetErrorMsg('Something went wrong while analysing the report!')
        }

      }

    }
    else {

      setErrmsg("This field is required.")

    }
    setLoading(false)
  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('plagiarism')

  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // link.download = 'Plagarismlist.pdf';
        var filedata = await Commonfun("plagiarism-checker", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });

        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });


      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);


        var filedata = await Commonfun("plagiarism-checker", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        // Trigger a click on the anchor element to start the download
        link.click();

        // Trigger a click on the anchor element to start the download
        // link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });

        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=13`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'urlcheck')
    ) {
      setfaqData(2)
      setToggle(false)
    }
  });


  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (


    <>
      {/* {
      loading===true ?   <Loader/> : null
    } */}


      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plagiarism </title>
        </Helmet>
        <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
        {
          loading === true ? <Loader /> : null
        }

        <div className="cnt_audit_outer_wrap">
          <div className="breadcrumb_outer" >
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Link to="/">
                <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
              </Link>
              <Typography sx={{ color: 'grey' }}>Content</Typography>
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Plagarism Checker</Typography>
            </Breadcrumbs>
            <div className="exportpdf_outer">
              <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
                <QuestionMarkIcon />

              </button>
              {plagiarismPlan.status &&
                <>
                  <button className="exportpdf" alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} title="CSV Download"  ><GetAppIcon />CSV</button>
                  <button className="exportpdf" alt="pdf icon" onClick={isDownloading ? null : pdfdown} title="PDF Download" ><GetAppIcon />PDF</button>
                </>
              }
            </div>
            {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}

          </div>
          <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Plagiarism Checker</h2>
              {/* <p>as of {jobDate}</p> */}
              <p>Available points : {AvailablePoints}</p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">

              {plagiarismPlan.status &&
                <div className="frm_containers plagarisminputbutton">
                  <input type="text" value={AnalyzeUrl} onChange={(e) => { SetAnalyzeUrl(e.target.value) }} placeholder="Enter Domain URL" className="campaign-name" style={{width:250}} />
                  <p style={{ color: "red" }}>{errmsg}</p>
                </div>}
              <div className="button_col">
                <button class="analyzbutton runreportnew" onClick={AnalyzeReport} style={{ padding: "10px 15px" }}>Analyze</button>
              </div>
            </div>
          </div>

          {plagiarismPlan.status ?
            <div>


              {/* <div className="plagarism_analyzer_row no_pad">
                <div className="input_col">
                  <input type="text" value={AnalyzeUrl} onChange={(e) => { SetAnalyzeUrl(e.target.value) }} placeholder="Enter URL" className="urlcheck" />
                  <p style={{ color: "red" }}>{errmsg}</p>
                </div>
                <div className="button_col">
                  <button class="analyzbutton" onClick={AnalyzeReport} style={{ padding: "10px 15px" }}>Analyze</button>
                </div>
              </div> */}
              <div className="error_plagarism_report">
                {
                  ErrorMsg
                }

              </div>
              <div className="data_table plagarism_checker_table" style={{ width: "100%" }}>
                <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                  {/* <DataGrid
                slots={{
                  noRowsOverlay: MyCustomNoRowsOverlay,
                  toolbar: GridToolbar,
                  noResultsOverlay: MyCustomNoRowsOverlay
                }}
                slotProps={{
                  toolbar: {

                    showQuickFilter: true,

                  },
                }}
                rows={rows}
                columns={columns}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  // ".MuiDataGrid-virtualScroller": {
                  //   height: "auto !important",
                  //   overflowY: "auto",
                  // },
                  "& .MuiDataGrid-cell:first-child": {
                    position: "unset",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnHeader:first-child": {
                    position: "sticky",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    border: "none",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnSeparator--sideRight": {
                    display: "none"
                  },

                }}
                getRowHeight={() => 'auto'}
                autoHeight={autoheightval ? true : false}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 30, 50, 75, 100]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                loading={loading}
              /> */}
                  <DataGrid
                    slots={{
                      noRowsOverlay: MyCustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      footer: () => (
                        <Box className="table-footer-container">
                          <Box className="table-footer-pagination">
                            <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                          </Box>
                          <MSelect
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            size="small"
                            className="table-footer-select"
                          >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={30}>30 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={75}>75 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                          </MSelect>
                        </Box>
                      ),
                    }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                    columns={columns}

                    getRowHeight={() => "auto"}
                    autoHeight={autoheightval ? true : false}
                    // initialState={{
                    //   pagination: {
                    //     paginationModel: {
                    //       pageSize: 10,
                    //     },
                    //   },
                    // }}
                    pageSizeOptions={[10, 30, 50, 75, 100]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                  // loading={loading}
                  />
                </div>
              </div>
            </div> : <Freeaudit planStatus={plagiarismPlan} />
          }

          {/* <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="keyword-dialog-header">{"Choose The Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div className="col">
                  <div>
                    <span>Completed Date </span>
                    <Select
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => setSelectval(values)}
                    />
                  </div>
                </div>
  
                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-button">
            <button className="form_submit_btn" onClick={reportNow}>
              Submit
            </button>
            <button onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog> */}


          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="competitor_test_filter job_date_filter"
          >
            <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="cnt_hdr_blw_wrap row">

                  <div>

                    <div className="">
                      <div>
                        <span>Completed Date</span>
                        <Select
                          options={completedate}
                          labelField="completed_at"
                          valueField="id"
                          onChange={(values) => setSelectval(values)}
                        />
                      </div>


                    </div>

                    <div className="col">
                      <span className="text-error">{validation.dateerror}</span>
                    </div>


                  </div>

                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
                Submit
              </button>
              <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
            </DialogActions>
          </Dialog>


          {!openCommonAlerts && <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className="keyword-dialog-header">{"Error Message "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                No Data Found
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>}
          {!openCommonAlerts && <Dialog
            open={apiopen}
            onClose={apihandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                borderRadius: "18px !important",
                borderColor: "#ff601f !important",
                top: "10px !important",
                // width: '100%',
                // boxShadow:"0px 15px 10px 0px #fff !important"
              },
            }}

          >
            <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}>
              {apiStatusCode==200 ? '' : <ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} />}
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <DialogContentText id="alert-dialog-slide-description">
                {apierrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
              <Button onClick={apihandleClose}>Close</Button>
            </DialogActions>
          </Dialog>}
          <Dialog
            open={checkD}
            // onClose={() => setOpenCommonAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="domain-verify-alert"
          >
            <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '15px', lineHeight: '1px', width: "max-content" }}>
                <p>{`Please verify your domain ${domainNameUrl.domain_name} to access these features`}</p>
                <a href={domainNameUrl.domain_url} >{domainNameUrl.domain_url}</a>
              </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-action-button">
              <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
              <Button onClick={() => setcheckD(false)} >{first.closeName}</Button>
            </DialogActions>
          </Dialog>

        </div>
      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </>

  );
}
