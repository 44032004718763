import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
// import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import ErrorService from "./ErrorService";
import { Helmet } from 'react-helmet';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CommonAlert from "../../../Commonalert/commonalert";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";

export default function TechDetails(props) {
  const isMobile = useMediaQuery('(max-width:480px)');

  const [loading, setLoading] = useState(false);
  const [dimensionval, SetDimensionval] = useState("");
  const [selectMetrics, SetSelectMetrics] = useState([]);
  const [Defaultdimensionval, SetDefaultdimensionval] = useState([])
  const [DefaultselectMetrics, SetDefaultselectMetrics] = useState([])
  const [tableval, SetTableval] = useState([])
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [seoindexDatadef, setSeoindexDatadef] = useState(0);
  const [seooccurDatadef, setSeooccurDatadef] = useState(0);
  const [googleCountry, setGoogleCountry] = useState("");
  const [googleSessions, setGoogleSessions] = useState("");
  const [googleimporsion, setGoogleimporsion] = useState("");
  const [googleclickthrough, setGoogleclickthrough] = useState("");
  const [Googleavgpos, setGoogleavgpost] = useState("");
  const [GoogleUsers, setGoogleUsers] = useState("");
  const [GoogleNewusers, setGoogleNewusers] = useState("");
  const [Googleengdsession, setGoogleengdsession] = useState("");
  const [Googleengdrate, setGoogleengdrate] = useState("");
  const [Googleevtcount, setGoogleevtcount] = useState("");
  const [Googleconversion, setGoogleconversion] = useState("");
  const [Googlebouncerate, setGooglebouncerate] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ShowMainAlert, SetShowMainAlert] = useState(false)
  const [ShowsecondMainAlert, SetShowsecondMainAlert] = useState(false)
  const [ShowerrorMainAlert, SetShowerrorMainAlert] = useState(false)
  const [ShowerrorMainMsg, SetShowerrorMainMsg] = useState("")
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate()
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [seocountval, setseocountval] = useState(0)
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const apihandleClose = () => {
    setapiOpen(false);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );


  const barChartData = {
    labels: seoindexData,
    datasets: [{
      axis: 'Y',
      label: `Last Seven Days Report for ${DefaultselectMetrics}`,
      data: seooccurData,
      fill: false,
      backgroundColor: '#FE6020',
      borderWidth: 1
    }]
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "landingPagePlusQueryString",
      headerName: "Type",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        if (cardData.row.browser)
          var dataval = cardData.row.browser;
        else if (cardData.row.deviceCategory)
          var dataval = cardData.row.deviceCategory;
        else if (cardData.row.deviceModel)
          var dataval = cardData.row.deviceModel;
        else if (cardData.row.screenResolution)
          var dataval = cardData.row.screenResolution;
        else if (cardData.row.appVersion)
          var dataval = cardData.row.appVersion;
        else if (cardData.row.platform)
          var dataval = cardData.row.platform;
        else if (cardData.row.operatingSystemVersion)
          var dataval = cardData.row.operatingSystemVersion;
        else if (cardData.row.platformDeviceCategory)
          var dataval = cardData.row.platformDeviceCategory;
        else if (cardData.row.operatingSystem)
          var dataval = cardData.row.operatingSystem;
        else if (cardData.row.operatingSystemWithVersion)
          var dataval = cardData.row.operatingSystemWithVersion;
        return (
          <>
            <span>{dataval}</span>
          </>
        );
      },
    },
    {
      field: "Users",
      headerName: "",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The total number of unique users."} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'Users '}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {GoogleUsers}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              100% of total
            </span></p>
          </div>
        </Tooltip>
      ),
    },
    {
      field: "New_users",
      headerName: "",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"First-time visitors within a specified period"} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'New User'}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {GoogleNewusers}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              100% of total
            </span></p>

          </div>
        </Tooltip>
      ),
    },
    {
      field: "Engaged_sessions",
      headerName: "Engaged Sessions",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The number of sessions where users interacted significantly with the website."} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'Engaged Session '}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {Googleengdsession}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              100% of total
            </span></p>

          </div>
        </Tooltip>
      ),
    },
    {
      field: "Event_count",
      headerName: "Event Count",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The total number of events (like clicks or downloads) recorded during sessions."} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'Event Count '}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {Googleevtcount}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              100% of total
            </span></p>

          </div>
        </Tooltip>
      ),
    },
    {
      field: "Sessions_per_user",
      headerName: "",
      minWidth: 180,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The average number of sessions started by each unique user, indicating return visit frequency."} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'Avg Session Per User '}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {googleclickthrough}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              Avg 0%
            </span></p>

          </div>
        </Tooltip>
      ),
    },
    {
      field: "User_engagement_duration",
      headerName: "",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The average time users spend actively involved during sessions."} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'Avg User Engagement Duration'}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {Googleavgpos}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              Avg 0%
            </span></p>

          </div>
        </Tooltip>
      ),
    },
    {
      field: "Engagement_rate",
      headerName: "Avg Engagement Rate",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The percentage of engaged sessions relative to total sessions, showing overall user involvement."} >

          <div className="dymanic_head_inner">
            <strong>
              <p>{'Avg Engagement Rate '}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {Googleengdrate}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              Avg 0%
            </span></p>

          </div>
        </Tooltip>
      ),

    },
    {
      field: "Conversions",
      headerName: "Conversions",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"Desired user actions, like purchases or sign-ups, linked to specific technical details."} >

          <div className="dymanic_head_inner">
            <strong>
              <p>{'Conversions '}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              {Googleconversion}
            </span></p>
            <p><span className="dymanic_head_inner_span">
              100% of total
            </span></p>

          </div>
        </Tooltip>
      ),
    },
    {
      field: "Total_revenue",
      headerName: "Bounce Rate",
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"TotalRevenue refers to the total amount of revenue generated from conversions or transactions on the website"} >
          <div className="dymanic_head_inner">
            <strong>
              <p>{'Total Revenue'}</p>
            </strong>
            <p><span className="dymanic_head_outer_span">
              ${Googlebouncerate}
            </span></p>
            <p><span className="dymanic_head_inner_span">

            </span></p>

          </div>
        </Tooltip>
      ),
    },
  ];
  const rows = tableval ?? [];
  const OnSetSelectedWcagType = (values) => {
    setSeoindexDatadef(0)
    setSeooccurDatadef(0)
    SetDefaultdimensionval(values.value)
    var campaigndata = values.value;
    var metricdata = DefaultselectMetrics;
    datavalfun(campaigndata, metricdata)

  }
  const OnSetSelectedmetricsType = (values) => {
    setSeoindexDatadef(0)
    setSeooccurDatadef(0)
    SetDefaultselectMetrics(values.value)
    var metricdata = values.value;
    var campaigndata = Defaultdimensionval;

    datavalfun(campaigndata, metricdata)

  }
  const LoadData = async (tableid = '') => {

    setLoading(true)
    try {
      const datavalueKpi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/tech_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      // console.log("datavalueKpi",datavalueKpi)
      if (datavalueKpi?.data?.dimension && datavalueKpi?.data?.selected_metrics) {
        var Sections = [];
        var Selectedmet = [];
        datavalueKpi.data.dimension.forEach((element, index) => {
          Sections.push({ value: element, label: element });
        });
        datavalueKpi.data.selected_metrics.forEach((element) => {
          Selectedmet.push({ label: element, value: element });
        });
        // console.log(Sections)
        Setcampaignoption(Sections);
        SetSelectMetrics(Selectedmet);
        var campaigndata = Sections[0].value;
        var metricdata = Selectedmet[0].value;
        SetDefaultdimensionval(Sections[0].value);
        SetDefaultselectMetrics(Selectedmet[0].value);
        datavalfun(campaigndata, metricdata)
      }
    } catch (err) { console.log(err) }

    setLoading(false)
  }
  const datavalfun = async (campaigndata = "", metricdata = "") => {

    try {

      const datavalueApi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/tech_details/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${campaigndata}&selected_metrics=${metricdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (!datavalueApi?.data?.status_code) {
        SetShowerrorMainAlert(false)
        if (datavalueApi?.data?.result.length > 0) {
          if (datavalueApi?.data?.result) {
            const newData = datavalueApi.data.result.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });
            if (newData.length > 0) {
              SetAutoheightval(false)
              SetTableval(newData);
            }
            else {
              SetAutoheightval(true)
              SetTableval("");
            }
            SetShowsecondMainAlert(true)


            const seoindex = datavalueApi.data.chart_data.slice(0, 5)?.map(function (val, index) {
              return val.Value;
            });
            const seooccurence = datavalueApi.data.chart_data.slice(0, 5)?.map(function (val, index) {
              return val.Percentage;
            });
            setSeoindexData(seoindex);
            setSeooccurData(seooccurence);
            setGoogleCountry(datavalueApi.data.table_field[0].Country)
            setGoogleUsers(datavalueApi.data.table_field[0].Uers)
            setGoogleNewusers(datavalueApi.data.table_field[0].New_users)
            setGoogleSessions(datavalueApi.data.table_field[0].Sessions)
            setGoogleengdsession(datavalueApi.data.table_field[0].Engaged_sessions)
            setGoogleimporsion(datavalueApi.data.table_field[0].Avg_user_engagement_duration)//user engagement 
            setGoogleclickthrough(datavalueApi.data.table_field[0].Avg_sessions_per_user)//avg session per user
            setGoogleavgpost(datavalueApi.data.table_field[0].Avg_user_engagement_duration) //avg events per session
            setGoogleengdrate(datavalueApi.data.table_field[0].Avg_engagement_rate) //avg engagement rate
            setGoogleevtcount(datavalueApi.data.table_field[0].event_count)
            setGoogleconversion(datavalueApi.data.table_field[0].Conversions)
            setGooglebouncerate(datavalueApi.data.table_field[0].Total_revenue)
          }
        }
        else {
          SetShowerrorMainMsg(datavalueApi.data.message)
          SetShowerrorMainAlert(true)

        }
      }
      else {
        SetShowMainAlert(true)
        SetShowsecondMainAlert(false)

      }
    } catch (err) { console.log(err) }
    props.setDimendataval(campaigndata)
    props.setSelmetricval(metricdata)
  }
  const [LabelData, SetLabelData] = useState([])
  const [DataSetsValue, SetDataSetsValue] = useState([])
  const chartData = {
    labels: LabelData,
    datasets: DataSetsValue
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category', // Use 'category' for string x-axis values
        position: 'bottom',
        ticks: {
          color: '#0b2033'
        },

      },
      y: {

        ticks: {
          color: '#0b2033'
        },

      },

    },
    plugins: {
      legend: {
        display: false

      }
    }
  };
  useEffect(() => {
    LoadData()
  }, []);

  // useEffect(()=>{   
  //   console.log("props",props)
  //     LoadData()
  // },[props])
  const prevhandleEventdom = async () => {
    setseocountval(0)
    var vardata = seoindexDatadef - 5;
    setSeoindexDatadef(vardata)
    var seovardata = seooccurDatadef - 5;
    setSeooccurDatadef(seovardata)
    try {
      // 
      const datavalueApi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/tech_details/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${Defaultdimensionval}&selected_metrics=${DefaultselectMetrics}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalueApi?.data?.result) {
        const newData = datavalueApi.data.result.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        if (newData.length > 0) {
          SetAutoheightval(false)
          SetTableval(newData);
        }
        else {
          SetAutoheightval(true)
          SetTableval("");
        }
        console.log(datavalueApi.data.chart_data, "chartdatavaku")
        const seoindex = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
          return val.Value;

        });
        const seooccurence = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
          return val.Percentage;
        });
        setSeoindexData(seoindex);
        setSeooccurData(seooccurence);
      }
    } catch (err) { console.log(err) }
  }
  const handleEventdom = async () => {
    var vardata = seoindexDatadef + 5;
    setSeoindexDatadef(vardata)
    var seovardata = vardata + 5;
    setSeooccurDatadef(seovardata)
    try {
      // 
      const datavalueApi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/tech_details/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${Defaultdimensionval}&selected_metrics=${DefaultselectMetrics}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalueApi?.data?.result) {
        const newData = datavalueApi.data.result.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        if (newData.length > 0) {
          SetAutoheightval(false)
          SetTableval(newData);
        }
        else {
          SetAutoheightval(true)
          SetTableval("");
        }


        if (datavalueApi.data.chart_data.length > vardata) {
          const seoindex = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
            return val.Value;

          });
          const seooccurence = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
            return val.Percentage;
          });
          setSeoindexData(seoindex);
          setSeooccurData(seooccurence);
        }
        else {
          setseocountval(1)
          var vardata = vardata - 5;
          setSeoindexDatadef(vardata)
          var seovardata = seovardata - 5;
          setSeooccurDatadef(seovardata)
        }
      }
    } catch (err) { console.log(err) }
  }

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  const checkingDataTech = () => {
    return (
      <div style={{ width: '100%', color: '#ff601f' }}>
        {/* <div className="cnt_hdr_blw_wrap"> */}
        <div className="listdropdown">
          <div className="inner_listdropdown">

            <span>Dimension</span>
            {campaignoption.length > 0 &&
              <Select
                className="drop-organicsearchs"
                value={campaignoption.value}
                options={campaignoption}
                placeholder="Select..."
                onChange={OnSetSelectedWcagType}
                defaultValue={campaignoption[0]}
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
              />
            }
          </div>
          <div className="inner_listdropdown">
            <span>Selected Metrics</span>
            {selectMetrics.length > 0 &&
              <Select
                className="drop-organicsearchs"
                value={selectMetrics.value}
                options={selectMetrics}
                placeholder="Select..."
                defaultValue={selectMetrics[0]}
                onChange={OnSetSelectedmetricsType}
                styles={customDropdownStyles()}
                menuPortalTarget={document.body}
              />
            }
          </div>
        </div>
        {/* </div> */}
      </div>
    )
  }

  useEffect(() => {
    props.setcheckingDiv3(checkingDataTech());
  }, [campaignoption, selectMetrics])



  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tech Details</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {
        ShowsecondMainAlert === true ?
          <>
            {/* <div className="site_blw_wrap ">
              <div className="cnt_hdr_blw_wrap">
                <div className="listdropdown">
                  <div className="inner_listdropdown">

                    <span>Dimension</span>
                    {campaignoption.length > 0 &&
                      <Select
                        className="drop-organicsearch"
                        value={campaignoption.value}
                        options={campaignoption}
                        onChange={OnSetSelectedWcagType}
                        defaultValue={campaignoption[0]}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                    }
                  </div>
                  <div className="inner_listdropdown">
                    <span>Selected Metrics</span>
                    {selectMetrics.length > 0 &&
                      <Select
                        className="drop-organicsearch"
                        value={selectMetrics.value}
                        options={selectMetrics}
                        defaultValue={selectMetrics[0]}
                        onChange={OnSetSelectedmetricsType}
                        styles={customDropdownStyles()}
                        menuPortalTarget={document.body}
                      />
                    }
                  </div>
                </div>
              </div>
            </div> */}
            {ShowerrorMainAlert === true ? <div className="error_org_msg" style={{ color: "red" }}>{ShowerrorMainMsg}</div> :
              <>
                <div className="site_regress_chart_info organic_search_data organic_class_inner" style={{ marginTop: '50px', display: "grid" }}>
                  <div>
                    <div className="button_organic_div">
                      {seoindexDatadef != 0 ?
                        <button className="button_organic_button" onClick={prevhandleEventdom}>Prev </button> : ""}
                      {seocountval == 0 ?
                        <button className="button_organic_button" style={{marginLeft: "10px"}}  onClick={handleEventdom}>Next</button> : ""}
                    </div>

                    <div className="site_Chart_info_inner">
                      <Bar
                        type="bar"
                        width={130}
                        height={isMobile ? 150 : 50}
                        data={barChartData}
                        options={{
                          indexAxis: 'y',
                          responsive: true,
                          plugins: {
                            // 'legend' now within object 'plugins {}'
                            legend: {
                              display: false,
                            },
                            title: {
                              display: true,
                              text: `${DefaultselectMetrics} by ${Defaultdimensionval}`,
                              color: "#0b2033",
                              font: {
                                size: 18,
                              },
                            },
                          },
                          scales: {
                            y: {
                              // not 'yAxes: [{' anymore (not an array anymore)
                              ticks: {
                                callback: function (index) {
                                  const maxLength = 15; // Set max length of label
                                  return barChartData.labels[index].length > maxLength ? barChartData.labels[index].substring(0, maxLength) + "..." : barChartData.labels[index];
                                },
                                suggestedMin: 0,
                                stacked: true,
                                color: "#0b2033", // not 'fontColor:' anymore
                                // fontSize: 18,
                                font: {
                                  size: 14, // 'size' now within object 'font {}'
                                },
                                beginAtZero: true,
                              },
                            },
                            x: {
                              // not 'xAxes: [{' anymore (not an array anymore)
                              ticks: {
                                color: "#0b2033", // not 'fontColor:' anymore
                                //fontSize: 14,
                                font: {
                                  size: 12, // 'size' now within object 'font {}'
                                },
                                beginAtZero: true,
                              },
                            },
                          },
                        }}
                      />
                      <p>Demographic Details</p>
                    </div>
                  </div>
                </div>
                <div
                  className="data_table internal_link_checker_table google_analytics_err"
                  style={{ width: "100%" }}
                >
                  <div style={{ height: 650, width: "100%" }} className="organic_table_inner">
                    <DataGrid
                      onColumnHeaderClick={handleEventdom}
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        noResultsOverlay: MyCustomNoRowsOverlay,
                        footer: () => (
                          <Box className="table-footer-container">
                            <Box className="table-footer-pagination">
                              <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                            </Box>
                            <MSelect
                              value={rowsPerPage}
                              onChange={handleRowsPerPageChange}
                              size="small"
                              className="table-footer-select"
                            >
                              <MenuItem value={10}>10 per page</MenuItem>
                              <MenuItem value={30}>30 per page</MenuItem>
                              <MenuItem value={50}>50 per page</MenuItem>
                              <MenuItem value={75}>75 per page</MenuItem>
                              <MenuItem value={100}>100 per page</MenuItem>
                            </MSelect>
                          </Box>
                        ),
                      }}
                      rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                      columns={columns}
                      pagination
                      // pageSizeOptions={[5, 10, 20]}
                      disableVirtualization
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      getRowId={(row) => row.sid}
                      getRowHeight={() => "auto"}
                      autoHeight={autoheightval ? true : false}
                      // initialState={{
                      //   pagination: {
                      //     paginationModel: {
                      //       pageSize: 10,
                      //     },
                      //   },
                      // }}
                      slotProps={{
                        toolbar: {

                          showQuickFilter: true,

                        },
                      }}
                    // pageSizeOptions={[10, 30, 50, 75, 100]}
                    // loading={loading}
                    />
                    <div>
                      <span className="orang_txt">Note : The report above represents data from the last seven days in Google Analytics.</span>
                    </div>
                  </div>
                </div></>}
          </> : null
      }
      {
        ShowMainAlert === true ?

          <ErrorService />
          : null
      }
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
    </>
  );
}
