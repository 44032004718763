import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "chartjs-adapter-moment";
import { AppContext } from "../../../Context/Context";
import Loader from "../CommonLoader";
import { Bar } from "react-chartjs-2";
import CommonAlert from "../../../Commonalert/commonalert";
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Keywordbar from "../Rightsidebar/Keywordbar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";



const ErrorReport = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [ReportSectionOptions, SetReportSectionOptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState();
  const [earlierdomainsectiondata, Setearlierdomainsectiondata] = useState();
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [apiopen, setapiOpen] = useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [errors, setErrors] = useState({});
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [jobidval, Setjobidval] = useState("");
  const [earlierjobidval, Setearlierjobidvall] = useState("");
  const [consterrreport, Setconsterrreport] = useState([])
  const [datavalError, SetdatavalError] = useState([])
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [addEditDomainPopup, setAddEditDomainPopup] = useState(true);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [faqData, setfaqData] = useState("2");
  const [toggle, setToggle] = useState(false);

  const [dEngineoption, SetdEngineoption] = useState({
    job_date: "Select Date",
    id: "Select Date",
  });
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };


  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0], "<<<<<<<<<<<<<<<<===================>>>>>>>>>>>>>>>>>>>")
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input') || (event.target.classList[0] === 'over_all_inner') || (event.target.classList[0] === 'cnt_hdr_top_wraps') || (event.target.classList[0] === 'breadcrumb_outer') || (event.target.classList[0] === 'card_custom')) {
      setfaqData(2)
      setToggle(false)
    }
  });



  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=63`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }



  useEffect(() => {
    customreportdatedata();
    reportError();
    faqdatafun();
    // dropDownlist();
  }, []);
  // const dropDownlist =async () =>{
  //   const completevalues = await axios
  //   .get(
  //     `${process.env.REACT_APP_API_URL}customized_report/consolidate_report_job_types/?user_domain_id=${esodata.domaindata.value.header_selected}`,
  //     {
  //       headers: {
  //         Authorization: `${ApiToken}`,
  //       },
  //     }
  //   )
  //   .catch((error) => {
  //     if (
  //       error?.response?.status == 599 ||
  //       error?.response?.status == 400 ||
  //       error?.response?.status == 500
  //     ) {
  //       setapiOpen(true);
  //       setapierrorMsg(error?.response?.data?.error);
  //     } else if (
  //       error?.code == "ERR_NETWORK" ||
  //       error?.response?.status == 404
  //     ) {
  //       navigate("/errorpages");
  //     }
  //   });


  // var Selectedmets = [];
  // completevalues?.data?.result?.forEach((element) => {
  //   Selectedmets.push({
  //     id: element.name,
  //     job_date: element.type,
  //   });
  // });
  // SetReportSectionOptions(Selectedmets);
  // var selevtval=[];
  // selevtval.push({
  //   value:Selectedmets[0]?.id,
  //   label:Selectedmets[0]?.job_date,
  // })
  // console.log("erorreport",Selectedmets) 
  //  SetSectionReportHandle(selevtval[0])
  // }
  const customreportdatedata = async () => {
    const completevalues = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_all_type_jobs/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if (
          error?.response?.status == 599 ||
          error?.response?.status == 400 ||
          error?.response?.status == 500
        ) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (
          error?.code == "ERR_NETWORK" ||
          error?.response?.status == 404
        ) {
          navigate("/errorpages");
        }
      });
    var Selectedmets = [];
    completevalues?.data?.forEach((element) => {
      Selectedmets.push({
        id: element.id,
        job_date: element.job_date,
      });
    });
    SetSectionOptions(Selectedmets);
  };
  const SetSectionNameHandles = async (passvalue) => {
    Setearlierdomainsectiondata({ id: passvalue.id, job_date: passvalue.label })
    Setearlierjobidvall(passvalue.value);
  };
  const SetSectionNameHandle = async (passvalue) => {
    SetDomainsectiondata({ id: passvalue.id, job_date: passvalue.label })
    Setjobidval(passvalue.value);
  };
  // const SetSectionReportHandle = async (passvalue) =>{
  //   console.log("passvalue",passvalue)
  //   setLoading(true);
  //   if(passvalue?.label.length>0)
  //   {
  //   const completevalues = await axios
  //   .get(
  //     `${process.env.REACT_APP_API_URL}customized_report/consolidate_error_table/?user_domain_id=${esodata.domaindata.value.header_selected}&latest_job_id=${jobidval}&earlier_job_id=${earlierjobidval}&chart_data=true&chart_data_type=${passvalue?.label}`,
  //     {
  //       headers: {
  //         Authorization: `${ApiToken}`,
  //       },
  //     }
  //   )
  //   .catch((error) => {
  //     if (
  //       error?.response?.status == 599 ||
  //       error?.response?.status == 400 ||
  //       error?.response?.status == 500
  //     ) {
  //       setapiOpen(true);
  //       setapierrorMsg(error?.response?.data?.error);
  //     } else if (
  //       error?.code == "ERR_NETWORK" ||
  //       error?.response?.status == 404
  //     ) {
  //       navigate("/errorpages");
  //     }
  //   })
  //   console.log("completevalues",completevalues?.data?.result)
  //   SetdatavalError(completevalues?.data?.result)
  // }
  // else{
  //   SetdatavalError([])
  // }
  // setLoading(false);
  // };
  const validateFields = () => {
    const newErrors = {};
    if (!jobidval) newErrors.section = "Select Current Completed Date.";
    if (!earlierjobidval) newErrors.columns = "Select Previous Completed Date.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async () => {
    setLoading(true);
    if (!validateFields()) {
      setLoading(false);
      return; // Exit if validation fails

    }
    reportError();
  };
  const reportError = async () => {
    try {
      setLoading(true);
      setIsCardVisible(false);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/consolidate_error_table/?user_domain_id=${esodata.domaindata.value.header_selected}&latest_job_id=${jobidval}&earlier_job_id=${earlierjobidval}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      console.log(response?.data?.result?.rankings_summary?.not_ranked_keywords?.current_occurance)
      Setconsterrreport(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error occurred:", error); // Log full error for debugging
      if (
        error?.response?.status === 599 ||
        error?.response?.status === 400 ||
        error?.response?.status === 500
      ) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error);
      } else if (
        error?.code === "ERR_NETWORK" ||
        error?.response?.status === 404
      ) {
        navigate("/errorpages");
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setLoading(false); // Hide loading state
    }
  }

  const labels = datavalError
    ? Object.keys(datavalError).map(key => key.replace(/_/g, ' '))
    : [];

  const currentData = datavalError
    ? Object.values(datavalError).map(item => item.current_occurance)
    : [];

  const previousData = datavalError
    ? Object.values(datavalError).map(item => item.previous_occurance)
    : [];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Previous",
        backgroundColor: "#103658",
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 1,
        data: previousData,
      },
      {
        label: "Current",
        backgroundColor: "#ff601f",
        borderColor: "rgb(255, 255, 255)",
        borderWidth: 1,
        data: currentData,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Audit Metrics Comparison",
      },
    },
  };
  return (
    <div
      className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""
        }`}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Consolidated Error Report</title>
      </Helmet>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <div className="over_all_inner">
        <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
            <Link to="/">
              <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Report</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Consolidated Error Report
            </Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />
            </button>
          </div>
          {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}
        </div>
        <div className="cnt_hdr_top_wraps">
          <div className="cnt_hdr_top_lft_wrap" >
            <h2 className="heading_font" style={{ color: "#000" }}>Consolidated Error Report</h2>
          </div>

          <Helmet>
            <meta charSet="utf-8" />
            <title>     Consolidated Error Report                </title>
          </Helmet>
        </div>
        {/* <div className="cnt_hdr_top_wrap" style={{padding: "15px" , background: "#ff601f", marginBottom:"20px"}}>
          <div className="cnt_hdr_top_lft_wrap" >
            <h2 className="heading_font" style={{color: "#000"}}>Consolidated Error Report</h2>
          </div>
    
        </div> */}
        {/* <div className="cnt_hdr_top_wrap">
          <h1 className="heading_font">Consolidated Error Report</h1>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Consolidated Error Report </title>
          </Helmet>
        </div> */}
        {isCardVisible ? (
          <Button
            variant="contained"
            style={{ backgroundColor: "#ff601f" }}
            onClick={toggleCardVisibility}
          >
            Hide Filters
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ backgroundColor: "#ff601f" }}
            onClick={toggleCardVisibility}
          >
            Show Filters
          </Button>
        )}
        {loading === true ? <Loader /> : null}
        {isCardVisible && (
          <div className="card_custom">
            <div className="filter-section">
              <div className="filter-item">
                <span className="listdropdown_titl">Current Completed Date</span>
                <Select
                  className="dropdown-style"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  id="section-dropdown"
                  options={SectionOptions.map((option) => ({
                    value: option.id,
                    label: option.job_date,
                  }))}
                  placeholder={Domainsectiondata ? "" : "Current Completed Date"}
                  onChange={SetSectionNameHandle}
                  defaultValue={
                    Domainsectiondata
                      ? { value: Domainsectiondata.id, label: Domainsectiondata.job_date }
                      : null
                  }
                />
                {errors.section && (
                  <div className="customerrormsg">{errors.section}</div>
                )}
              </div>
              <div className="filter-item">
                <span className="listdropdown_titl">Previous Completed Date</span>
                <Select
                  className="dropdown-style"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                  id="section-dropdown"
                  options={SectionOptions.map((option) => ({
                    value: option.id,
                    label: option.job_date,
                  }))}
                  placeholder={Domainsectiondata ? "" : "Previous Completed Date"}
                  onChange={SetSectionNameHandles}
                  defaultValue={
                    earlierdomainsectiondata
                      ? { value: earlierdomainsectiondata.id, label: earlierdomainsectiondata.job_date }
                      : null
                  }
                />
                {errors.columns && (
                  <div className="customerrormsg">{errors.columns}</div>
                )}
              </div>
              <Button
                // variant="contained"
                // style={{ backgroundColor: "#ff601f" }}
                onClick={handleSubmit}
                className="run_rpt_btn rn_rpt_btn over_all_accessibility runreportnew"
                style={{ marginTop: 30 }}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>

      <div id="webcrumbs">
        {/* <div className="listdropdown_drop_site_onpage" style={{"display":"flex","justify-content": "center"}}>
            <div>
                  <span className="listdropdown_titl">Report Type</span>
                      {ReportSectionOptions?.length > 0 &&
                     <Select
                  className="drop-organicsearch"
                  name="name"
                  onChange={SetSectionReportHandle}
                  options={ReportSectionOptions.map((option) => ({
                    value: option.id,
                    label: option.job_date,
                  }))}
                  // options={ReportSectionOptions}
                  isSearchable="true"
                  styles={customDropdownStyles()}
                  menuPortalTarget={document.body}
                   placeholder="Select Report Type"
                   defaultValue={
                    ReportSectionOptions
                      ? { value: ReportSectionOptions[0]?.id, label: ReportSectionOptions[0]?.job_date }
                      : null
                  } 
                   />
                      }
                </div>
              </div> */}
        {/* <div style={{
    "background": "#fff","padding": "30px"}}>
        <Bar data={chartData} options={options} />
        </div> */}
        <div className="bg-white rounded-lg shadow p-4">
          <table className="border border-neutral-300 errorreport">
            <thead>
              <tr>
                <th className="border border-neutral-300 p-2 text-left">
                  Report Name
                </th>
                <th className="border border-neutral-300 p-2 text-left">
                  Error Category
                </th>
                <th className="border border-neutral-300 p-2 text-left" title="Current Completed Date">
                  {consterrreport?.headers?.[0] ? consterrreport?.headers?.[0] : "Current report error occurrence"}
                </th>
                <th className="border border-neutral-300 p-2 text-left" title="Previous Completed Date">
                  {consterrreport?.headers?.[1] ? consterrreport?.headers?.[1] : " Previous report error occurrence"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="2">
                  Ranking Summary
                </td>
                <td className="border border-neutral-300 p-2">
                  Not ranked keywords
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.not_ranked_keywords?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.not_ranked_keywords?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missed Opportunities
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.total_missed_value?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.rankings_summary?.total_missed_value?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="11">
                  Site analysis
                </td>
                <td className="border border-neutral-300 p-2">
                  Missing ALT Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_alt_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_alt_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Duplicate Titles
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_title_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_title_count?.previous_occurance}</td>
              </tr>

              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing Canonical
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_canonical_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_canonical_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing Descriptions
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_description_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_description_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Duplicate Descriptions
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_description_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_description_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Multiple H1 Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.multiple_h1_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.multiple_h1_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Duplicate H1 Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_h1_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.duplicate_h1_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing H1 Tags
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_h1_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_h1_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Missing Titles
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_title_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.missing_title_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Hreflang</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.hreflang_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.hreflang_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Mismatch Canonical</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.mismatch_canonical_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.site_audit?.mismatch_canonical_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="2">
                  Schema analysis
                </td>
                <td className="border border-neutral-300 p-2">Warning count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Error Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.error_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.schema?.error_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="3">
                  Source code analysis
                </td>
                <td className="border border-neutral-300 p-2">
                  Fatal error count
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.fatal_error_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.fatal_error_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Error Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.error_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.error_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Warning count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.source_code_analysis?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="3">
                  Accessibility Report
                </td>
                <td className="border border-neutral-300 p-2">Error count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Warning Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.warning_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">Notice Count</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.notice_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.accessibility?.notice_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="3">
                  Link checker
                </td>
                <td className="border border-neutral-300 p-2">
                  Internal Links - 4xx links
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fourxx_links_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fourxx_links_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  Internal Links - 5xx links
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fivexx_links_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.fivexx_links_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2">
                  External Links - Invalid Links
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.external_links_invalid_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.link_checker?.external_links_invalid_count?.previous_occurance}</td>
              </tr>
              <tr>
                <td className="border border-neutral-300 p-2" rowSpan="1">
                  Spell checker
                </td>
                <td className="border border-neutral-300 p-2">
                  Total misspelled words
                </td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.spell_check?.misspelled_word_count?.current_occurance}</td>
                <td className="border border-neutral-300 p-2">{consterrreport?.result?.spell_check?.misspelled_word_count?.previous_occurance}</td>
              </tr>
              {/* <tr>
                <td className="border border-neutral-300 p-2" rowSpan="1">
                  Brandmentions
                </td>
                <td className="border border-neutral-300 p-2">
                  Negative review count
                </td>
                <td className="border border-neutral-300 p-2">87</td>
                <td className="border border-neutral-300 p-2">67</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>


    </div>
  );
};
export default ErrorReport;
