import React, { useState, useEffect, useRef, useContext } from "react";
import { styled, useTheme, createTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";
import CommonAlert from "../../../Commonalert/commonalert";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import DomainAddForm from "./DomainAddForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Breadcrumbs, Typography } from "@mui/material";


export default function UserDomainsecList() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const theme = createTheme();
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [spellCheckSettingsPlan, setspellCheckSettingsPlan] = useState({ status: true, error: "" });
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [pagename, setPagename] = useState("Spell Checker Settings")
  const [deleteId, SetDeleteId] = useState("")
      const [formOpen, setformOpen] = useState(false);

  const esodata = useSelector((state) => state);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const ApiToken = esodata.apiauthkey.value
  const [file, setFile] = useState("Choose File");
  const [error, setError] = useState('');
  const [autoheightval, SetAutoheightval] = useState(true)

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [onPageError,SetonPageError]=useState("")
  const [apisuccessopen,Setapisuccessopen] = useState(false)
    const [CamPopTitle, SetCamPopTitle] = useState("");


  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
    Setapisuccessopen(false);
    setsuccessapiopen(false);
  };
  const onwebName = e =>{
    SetWebsiteName(e.target.value)
  }
  const [successapiopen, setsuccessapiopen] = React.useState(false);

  const [apisuccessMsg, setapisuccessMsg] = useState();
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const finalreportclose = ()=>{
    setformOpen(false)
  }
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = async (e) => {
    SetDeleteId(e.target.id)
    setIsOpen(true);
  };



  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}" + `edit-domainsec-list/`;

  const checkUserPlan = async () => {
    const spellCheckSettingsP = await Utils("spell_settings", ApiToken);
    if (spellCheckSettingsP.status) {
      callUserdataApi();
      faqdatafun()
    }
    setspellCheckSettingsPlan(spellCheckSettingsP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);

  const onFileChange = (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    const validExtensions = ['.csv'];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (validExtensions.includes(`.${fileExtension}`)) {
        setFile(selectedFile);
        setError('');
        setLoading(false);
      } else {
        setFile(selectedFile);
        setError('Please upload a CSV file');
        setLoading(false);
      }
    }
  };
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/spell_check_settings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        console.log(error)
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.spell_check_settings && datavalue?.data?.spell_check_settings.length > 0) {

        if (datavalue.data.spell_check_settings) {
          const newData = datavalue.data.spell_check_settings.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
        }
      }
      else {
        SetAutoheightval(true)
        setCardData("");
        setapiOpen(true);
        setapierrorMsg("Please Add Ignore Words")
      }

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    // navigate("/add-spellchecker-list")
    SetCamPopTitle('Add Ignore Words')
    setformOpen(true)
  }
  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {
    DeleteCampaignSettings(deleteId);
  };
  const DeleteCampaignSettings = async (id) => {
    setLoading(true);
    setCardData([])
    setIsOpen(false);
    try {
      var delId = id;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}settings/delete_ignore_words/`,
        {
          user_domain_id: esodata.domaindata.value.header_selected,
          ignored_word: delId,
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
    } catch (error) {
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error)
    }
    setLoading(false);
    callUserdataApi();
  };

  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "ignored_word",
      headerName: "Ignored Words",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Ignored words are deliberately excluded from a spell checker to prevent them from being flagged as errors.',
    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <button
            className="buttonView trash_icon"
            // onClick={handleClickOpen}
            onClick={() => handleDeleteClick(cardData.row.ignored_word)}
            id={cardData.row.ignored_word}
          >
            <DeleteIcon />
          </button>
        );
      },

    },
  ];
  const finalreportNow = async () =>{
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
  
    setLoading(true);
    var data_val=1;
    if(websiteName===""){
      SetWebnameError("Enter Ignore Words")
      data_val=2;
    }
    if(data_val===2){
    setLoading(false);
      return false;
    }
    else{
      try{
    setLoading(true);
    var user_domain_id=esodata.domaindata.value.header_selected;
        const response=await axios.post(
       `${process.env.REACT_APP_API_URL}settings/add_new_ignored_words/`,
         {
          user_domain_id:   user_domain_id,
          ignored_word:  websiteName,
         },
          {
                headers: {
                  Authorization: `${ApiToken}`,
                },
              }       
     ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
    });    
     if(response?.data?.message=="Ignore words added successfully")
     {
      Setapisuccessopen(true)
      setapisuccessMsg(response?.data?.message)
      setformOpen(false)
      callUserdataApi();
      //  window.location.href=`${process.env.REACT_APP_DOMAIN}spell-checker-settings`
     }
   }catch(err){
    setLoading(false);
    SetonPageError(err.response.data.error)
   }
    }

  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=48`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const onFileUpload = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (error == '') {
      if (file == "Choose File") {
        // setapiOpen(true);
        setLoading(false);
        setError('Please select a file to upload')
        return;
      }
      e.preventDefault();
      const formData = new FormData();
      formData.append('user_domain_id', esodata.domaindata.value.header_selected);
      formData.append('file', file, file.name);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}settings/ignore_words_bulk_upload/`,
          formData,
          {
            headers: {
              Authorization: `${ApiToken}`,
              'Content-Type': 'multipart/form-data',
            },

          }).catch((error) => {
            setLoading(false);
            if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
            }
            else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate('/errorpages')
            }
            // console.log("kannan",error)
          });
        if (res?.data?.message) {
          // window.location.reload();
          setLoading(false);
          setsuccessapiopen(true);
          setapisuccessMsg(res?.data?.message)
          setFile(null);
          setFile("Choose File");
        }

      } catch (error) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }

    }
    else {
      setLoading(false);
      if (file == "Choose File") {
        // setapiOpen(true);
        setError('Please select a file to upload')
        return;
      } else {
        setError('Please upload a CSV file');
        return false;
      }
    }

  };
  const fileInputRef = useRef(null);
  const handleDivClick = (e) => {
    e.preventDefault();
    // Programmatically click the file input
    fileInputRef.current.click();
  };

  const apisuccesshandleClose = () => {
    window.location.reload();
    setsuccessapiopen(false);
  };

  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spell Checker Settings </title>
      </Helmet>
      <div className="cnt_audit_outer_wrap">
      <div className="breadcrumb_outer" >
          <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Link to="/">
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Dashboard</Typography>
            </Link>
            <Typography sx={{ color: 'grey' }}>Settings</Typography>
            <Typography sx={{ color: '#ff601f', textDecoration: 'none', cursor: 'pointer' }}>Spell Checker Settings</Typography>
          </Breadcrumbs>
          <div className="exportpdf_outer">
            <button onClick={handleToggle} className="faq_class exportpdf" title="Frequently Asked Question" >
              <QuestionMarkIcon />

            </button>
       
          </div>
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

        </div>
        <div className="cnt_hdr_top_wraps">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font"> Spell Checker Settings</h2>
              {/* <p>as of {jobDate}</p> */}
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
           
            {spellCheckSettingsPlan.status &&
            <button
              title="Add Ignore Word"
              className="runreportnew"
              onClick={() => { openModal() }}
             

            >
               Add Ignore Word
            </button>
              }
          </div>
          </div>
          {spellCheckSettingsPlan.status ?
          <div>
         
            <div className="plagarism_analyzer_row no_pad accessfile">

              <div className="input_col ignore_words_settings" style={{margin:"0 auto",width:"42%"}}>
                <div className="genrate_input_col" >
                  <form className="fileform" onSubmit={onFileUpload}>
                    <div className='upload-container' >
                      <label htmlFor="file-input" className="upload-label" onClick={handleDivClick}>Select File</label>
                      <input id="file-input"
                        type="file"
                        className="field-style"
                        ref={fileInputRef}
                        accept=".csv" onChange={onFileChange} />

                      {file && <p className="file-info">{file.name}</p>}
                    </div>
                    <button type="submit" className="blubutton">Upload File</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
              </div>
            </div>
           
            <div className="data_table brand_list_table campapgin_settings_list_table" style={{ width: "100%" }}>
            <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">

                <DataGrid

                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                    footer: () => (
                      <Box className="table-footer-container">
                        <Box className="table-footer-pagination">
                          <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                        </Box>
                        <MSelect 
                          value={rowsPerPage} 
                          onChange={handleRowsPerPageChange} 
                          size="small"
                          className="table-footer-select"
                        >
                          <MenuItem value={10}>10 per page</MenuItem>
                          <MenuItem value={30}>30 per page</MenuItem>
                          <MenuItem value={50}>50 per page</MenuItem>
                          <MenuItem value={75}>75 per page</MenuItem>
                          <MenuItem value={100}>100 per page</MenuItem>
                        </MSelect>
                      </Box>
                    ),
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                  columns={columns}
                  pagination
                  // pageSizeOptions={[5, 10, 20]}
                  disableVirtualization
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                      display: "none"
                    },
                  }}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                  // initialState={{
                  //   pagination: {
                  //     paginationModel: {
                  //       pageSize: 10,
                  //     },
                  //   },
                  // }}
                  // loading={loading}
                  // pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </div> : <Freeaudit planStatus={spellCheckSettingsPlan} />
        } 
      </div>
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle className="keyword-dialog-header">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              Are you sure you want to delete this Ignore Word?
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={handleConfirmDelete} style={{ cursor: 'pointer' }} >
            OK
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      <Dialog
        open={successapiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apisuccessMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apisuccessopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}
      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><CheckCircleOutlineIcon  fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apisuccessMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {CamPopTitle && formOpen && <Dialog
        open={formOpen}
        onClose={finalreportclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter keyword_edit_form"
      >
        <DialogTitle className="keyword-dialog-header"
        >{CamPopTitle}</DialogTitle>
        <hr/>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <form className="frm_container" id="reset_form">
                <div className="campaigns-addedit-row">
                  <div className="keyword-col1-fields">
                    <div>
                      <label for="city">
                      Ignore Words:
                      </label>
                      <input title="This name is used as a alias to mange your domain." type="text" name="websitename" onChange={onwebName} placeholder="Ignore Words" className="campaign-name" defaultValue={websiteName}/>
                    </div>
                    <div>
                      <span className="error-value">{webnameError}</span>
                    </div>
                  </div>
                     
                </div>                      
              </form>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
         <button className="form_submit_btn" onClick={finalreportNow} style={{ cursor: 'pointer' }} >
          Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={finalreportclose}>close</button>
        </DialogActions>
      </Dialog>}
    </div>


  );
}
