import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Link, useParams } from "react-router-dom";
import CommonAlert from "../../../Commonalert/commonalert";
import { flexbox } from "@mui/system";

function GoogleAnalytics(props) {


  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const apiToken = esodata.apiauthkey.value;
  const [text, setText] = useState("");
  const [googleId, setGoogleId] = useState("");
  const [googleIdError, setGoogleIdError] = useState("");
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  let querystring = useParams();
  let domain_id = ""
  if (querystring.id != "")
    domain_id = querystring.id;
  else
    domain_id = esodata.domaindata.value.header_selected;

  useEffect(() => {
    callUserDataApi();
  }, []);

  const onGoogleIdChange = (e) => {
    setGoogleId(e.target.value);
  };

  const handleCopyClick = () => {
    copy(text);
    toast.success('Successfully copied to clipboard', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const skipButtonClick = () => {
    // navigate('/domain-list');
    props.setAddEditDomainPopup(false);
  };

  const apiHandleClose = () => {
    setApiOpen(false);
  };

  const nextButtonClick = async () => {
    setLoading(true);

    setGoogleIdError("");
    let isValid = true;
    // if (googleId === "") {
    //   setGoogleIdError("Google ID is required");
    //   isValid = false;
    // } else
    if (isNaN(googleId) || googleId.length > 10) {
      setGoogleIdError("Enter a valid Google ID");
      isValid = false;
    }

    if (isValid) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}googleanalytics/save_google_analytics_property_id/`,
          {
            google_analytics_property_id: googleId,
            user_domain_id: domain_id,
          },
          {
            headers: {
              Authorization: `${apiToken}`,
            },
          }
        );

        if (response?.data?.status_code === 200) {
          setLoading(false);
          // navigate("/domain-list");
          props.setAddEditDomainPopup(false);
        }
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    } else {
      setLoading(false);
    }
  };

  const handleApiError = (error) => {
    if (error?.response?.status === 599 || error?.response?.status === 400 || error?.response?.status === 500) {
      setApiOpen(true);
      setApiErrorMsg(error?.response?.data?.error);
    } else if (error?.code === "ERR_NETWORK") {
      navigate('/errorpages');
    }
  };

  const callUserDataApi = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/get_service_account_email/`,
        {
          headers: {
            Authorization: `${apiToken}`,
          },
        }
      );

      setText(response?.data?.service_email_id);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="userdatas">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />


        <div className="">
          <div className="">
            {/* <p style={{textAlign: "center"}}>Google Analytics</p> */}
            <div >
              <p style={{ textAlign: "left", marginTop: "8%", fontWeight: '600' }}>1) Please enter the following service account as a user in your analytics account:</p>
              <div style={{ display: "flex",alignItems: "flex-end",gap: "13px"}}>
                <input className="google_analytics_input" style={{width: "80%"}} type="text" value={text} onChange={(e) => setText(e.target.value)} disabled />
                <button className="google_analytics_btn" onClick={handleCopyClick}>
                  Copy
                </button>
              </div>
            </div>
            <div>
              <p style={{ textAlign: "left", marginTop: "8%", fontWeight: '600' }}>2) Provide Viewer access only:</p>
              <div>
                <label style={{ fontWeight: '600' }}>Google ID:</label>
                <input className="google_analytics_input" style={{width: "89%"}} type="text" name="websitename" onChange={onGoogleIdChange} />
                <span className="error-value">{googleIdError}</span>
              </div>
            </div>
            <div className="keyword-dialog-buttons">
              <button
                className="keyword-cancel"
                type="button"
                onClick={skipButtonClick}
              >
                Close
              </button>
              <button
                className="keyword-submit"
                type="button"
                onClick={nextButtonClick}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {!openCommonAlerts && <Dialog
        open={apiOpen}
        onClose={apiHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {apiErrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apiHandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </div>
  );
}

export default GoogleAnalytics;
