import { IoMdCloseCircle } from 'react-icons/io'
import Faq from 'react-faq-component';
import { width } from '@mui/system';
import React, { useState, useEffect, useRef } from "react";





const Keywordbar = (props) => {
  const [keywordtest, setKeywordtest] = useState([])
  const [showFaq, setShowFaq] = useState(true);

  const arrayData = props.initialDateval ? props.initialDateval : [];


  useEffect(() => {
    setKeywordtest(arrayData)
    console.log("props.initialDateval", arrayData)

  }, []);


  // const data = {
  //   title: "FAQ",
  //   rows: props.initialDateval
  // }


  const escapeHtml = (str) => {
    return str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const data = {
    title: "FAQ",
    rows: arrayData.map((item) => ({
      ...item,
      // Escape the content before rendering
      content: escapeHtml(item.content),
    }))
  };

  console.log("datdas", data);
  const styles = {
    // bgColor: 'white',
    titleTextColor: "#103658",
    rowTitleColor: "#103658",
    // rowContentColor: 'grey',
    arrowColor: "red",
    width: "400px",
  };
  if (!showFaq) {
    return null;
  }
  return <aside className='sidebar' >
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '30px' }}>
      <IoMdCloseCircle
        style={{ cursor: 'pointer', color: 'black', position: 'absolute', fontSize: '24px', left: "60px" }}
        onClick={() => setShowFaq(false)} // Hide the FAQ section
      />
    </div>
    <div >
      <Faq data={data}
        styles={styles} />
      {data.rows.length === 0 && <p style={{ textAlign: 'center', color: '#ff601f' }} >No FAQs available</p>}
    </div>

  </aside>
}

export default Keywordbar;