import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import CommonAlert from "../../../Commonalert/commonalert";
function DomainAddForm() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)
  const [websiteName, SetWebsiteName] = useState("")
  const [webLink, SetwebLink] = useState("")
  const [onUsername, SetonUsername] = useState("")
  const [onPassword, SetonPassword] = useState("")
  const [webnameError, SetWebnameError] = useState("")
  const [weblinkError, SetweblinkError] = useState("")
  const [onUserError, SetonUserError] = useState("")
  const [onPasswordError, SetonPasswordError] = useState("")
  const [onPageError, SetonPageError] = useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);



  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value === "Yes" ? setShowResults(true) : setShowResults(false)
    e.target.value === "No" && SetonUsername(""); SetonPassword("");
  }
  const onwebName = e => {
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e => {
    SetwebLink(e.target.value)
  }
  const onWebsiteChange = e => {
    Setwebtype(e.target.value)
  }
  const onWebnameChange = e => {
    SetonUsername(e.target.value)
  }
  const onpasswordChange = e => {
    SetonPassword(e.target.value)
  }
  const finalreportCancel = async () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}spell-checker-settings`

  }
  const finalreportNow = async () => {
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")

    setLoading(true);
    var data_val = 1;
    if (websiteName === "") {
      SetWebnameError("Enter Ignore Words")
      data_val = 2;
    }
    if (data_val === 2) {
      setLoading(false);
      return false;
    }
    else {
      try {
        setLoading(true);
        var user_domain_id = esodata.domaindata.value.header_selected;
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}settings/add_new_ignored_words/`,
          {
            user_domain_id: user_domain_id,
            ignored_word: websiteName,
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            return false;
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        if (response?.data?.message == "Ignore words added successfully") {
          window.location.href = `${process.env.REACT_APP_DOMAIN}spell-checker-settings`
        }
      } catch (err) {
        setLoading(false);
        SetonPageError(err.response.data.error)
      }
    }

  }
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="userdatas">
        {/* <h1>Domain Data</h1> */}
        <div className="userdatas_inner">
          <p>Add Ignore Words</p>
          <span className="error-value">{onPageError}</span>

          <form className='frm_container'>
            <div className="form-group user_country websitename">
              <label>Ignore Words:</label>
              <div>
                <input style={{ margin: "10px 0px 10px 0px" }} title="This name is used as a alias to mange your domains." placeholder="Enter Ignored Keywords" type="text" name="websitename" onChange={onwebName} />
              </div>

              <span className="error-value">{webnameError}</span>

            </div>
            <div className="form_next">
              <button className="nextbutton" type="button" onClick={finalreportCancel}>Cancel</button>
              <button className="nextbutton" type="button" onClick={finalreportNow}>Submit</button>
            </div>
          </form>
        </div>
      </div>
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} />
    </div>

  );
}

export default DomainAddForm;


