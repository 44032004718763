import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { GetCompetitorJobIds } from "../../../Store/CompetitiorJobId";
import { GetCompetitorJobIds as GetCompetitorDomainIds } from "../../../Store/CompetitiorDomainId";
import { GetCompetitorJobIds as GetCompetitorInJobIds } from "../../../Store/CompetitorInJobId";
import Loader from "../CommonLoader";
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import TuneIcon from '@mui/icons-material/Tune';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { useNavigate } from "react-router-dom";
import { Commonfun, datezonechange } from './../Commonfun';
import { useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { customDropdownStyles } from "../../../Commonalert/commonDropdown";
import Select from 'react-select';


export default function CompetitorTest(props) {
  const isMobile = useMediaQuery('(max-width:480px)');


  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [competitorjobDate, setcompetitorjobDate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [competitorcardData, setCompetitorcardData] = useState([]);
  const [competitorsitecardData, setCompetitorSitecardData] = useState([]);
  const [competitorinternalcardData, setCompetitorInternalcardData] = useState([]);
  const [value, setValue] = useState("1");
  const [domainval, Setdomainval] = useState('')
  const [arrheaderdata, setArrheaderdata] = useState([]);
  const [earlierdataarr, setEarlierdataarr] = useState([]);
  const [latestdataarr, setLatestdataarr] = useState([]);
  const [serveritydata, setServeritydata] = useState([]);
  const [latestoccurData, setLatestoccurData] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [openCommonAlerts, setOpenCommonAlerts] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [prevdEngineoption, setprevdEngineoption] = useState({
    completed_at:
      'Select Date',
    id:
      'Select Date',
  });

  const [autoheightval, SetAutoheightval] = useState(true);
  const [ourDomainName, setOurDomainName] = useState('');
  const [compDomainName, setCompDomainName] = useState('');

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#fe874e";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  useEffect(() => {
    CloseModalTrigger()


    dispatch(GetCompetitorJobIds(esodata.domaindata.value.header_selected));
    dispatch(GetCompetitorDomainIds(esodata.domaindata.value.header_selected));
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));

    const checkDomainVerify = localStorage.getItem('need_domain_verification');
    setOpenCommonAlerts(checkDomainVerify === "true" ? true : false);

  }, []);
  const columns = [

    {
      field: "id",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seo_metrics",
      headerName: "Seo Metrics",
      description: 'On-page SEO elements are present on the website.',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'Priority Status from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      // headerName: "Our Occurrence",
      headerName: ourDomainName,
      description: 'Our Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,

      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      // headerName: "Competitor Occurrence44",
      headerName: compDomainName,
      description: 'Competitor Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',


    },
  ];
  const column1 = [
    {
      field: "id",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "site_metrics",
      headerName: "Site Metrics",
      description: 'Website metrics present on the website',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

    },
    // {
    //   field: "priority",
    //   headerName: "Priority",
    //   description: 'SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.',
    //   width: 250,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: true,

    //   renderCell: (cardData) => {
    //     return (
    //       <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
    //     );
    //   },
    // },
    {
      field: "our_occurences",
      // headerName: "Our Occurrence",
      headerName: ourDomainName,
      minWidth: 150,
      flex: 1,
      description: 'Our Occurrence count from competitor test',
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      // headerName: "Competitor Occurrence",
      headerName: compDomainName,
      description: 'Competitor Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',


    },
  ];
  const column2 = [

    {
      field: "id",
      headerName: "#",
      description: 'Index of the row',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description: 'It displays server error links (5xx) and broken links (4xx) on the website.',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,


    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.',
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      // headerName: "Our Occurrence",
      headerName: ourDomainName,
      minWidth: 150,
      flex: 1,
      description: 'Our Occurrence count from competitor test',
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      // headerName: "Competitor Occurrence",
      headerName: compDomainName,
      description: 'Competitor Occurrence count from competitor test',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',


    },
  ];
  const rows = cardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];
  const cardrow = competitorcardData ?? [];
  const LoadAddFormFilterOptions = async () => {

    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
      if (completevalues?.data?.jobs.length > 0) {
        var jobSections = [];
        completevalues?.data?.jobs.forEach((element) => {
          jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
        });
        setcompletedate(jobSections);
        SetJobId(jobSections[0].value);
        SetdEngineoption(jobSections[0]);
        setprevdEngineoption(jobSections[0]);

      }


    }
  }
  const callUserdataApi = async (firstCall) => {
    setLoading(true);



    var datavalue;
    if (firstCall) {

      datavalue = await axios
        .get(
          // `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&comp_job_id=${compjobid}&competitor_domain_id=${compdomain}`,
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${props.compId1}&user_domain_id=${esodata.domaindata.value.header_selected}&comp_job_id=${props.compId2}&competitor_domain_id=${props.compId3}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

    }
    else {




      datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${JobId}&user_domain_id=${esodata.domaindata.value.header_selected}&competitor_domain_id=${CompetitorDomainId.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

    }


    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
      var jobSections = [];
      completevalues?.data?.jobs.forEach((element) => {
        jobSections.push({ label: datezonechange(element.completed_at), value: element.id });
      });
      setcompletedate(jobSections);
    }



    const dataval = datavalue?.data?.domain?.seo_metrics_list;
    const dataval1 = datavalue?.data?.domain?.site_metrics_list;
    const dataval2 = datavalue?.data?.domain?.internal_links_list; 
    const compDataval = datavalue?.data?.competitor?.seo_metrics_list;
    const compDataval1 = datavalue?.data?.competitor?.site_metrics_list;
    const compDataval2 = datavalue?.data?.competitor?.internal_links_list;


    console.log("datavalue", datavalue);

    setjobDate(moment(datavalue?.data?.domain?.job_date).format("MMMM DD,YYYY HH:mm"));
    props.setJobdate(moment(datavalue?.data?.domain?.job_date).format("MMMM DD,YYYY HH:mm"));




    if (dataval && dataval.length > 0) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }

    if (dataval1) {
      const newData = dataval1.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setSitecardData(newData);
    }


    if (dataval2) {
      const newData = dataval2.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setInternalcardData(newData);
    }





    setCompetitorcardData(compDataval)
    setCompetitorSitecardData(compDataval1)
    setCompetitorInternalcardData(compDataval2)

    var datavalueChart;

    if (firstCall) {
      // `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${esodata?.competitorinjobids?.value[0]?.value}&user_domain_id=${esodata.domaindata.value.header_selected}&comp_job_id=${esodata?.competitorjobids?.value[0]?.value}&competitor_domain_id=${esodata?.competitordomainids?.value[0]?.value}`,

      datavalueChart = await axios
        .get(
          // `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&competitor_domain_id=${compdomain}&job_id=${jobid}&comp_job_id=${compjobid}`,
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&competitor_domain_id=${props.compId3}&job_id=${props.compId1}&comp_job_id=${props.compId2}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

    } else {

      datavalueChart = await axios
        .get(
          `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata?.domaindata?.value?.header_selected}&competitor_domain_id=${CompetitorDomainId.value}&job_id=${JobId}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

    }





    const chartval = datavalueChart?.data?.graph?.issue_graph;

    const seoindex = chartval?.map(function (val, index) {
      return val.seo_metrics;
    });
    const seooccurence = chartval?.map(function (val, index) {
      return val.comp_occurences;
    });
    const latestoccurence = chartval?.map(function (val, index) {
      return val.our_occurences;
    });

    setSeoindexData(seoindex);
    setSeooccurData(seooccurence);
    setLatestoccurData(latestoccurence);
    const datahearvals = ['High impact Issue Occurrence', 'Medium Impact Issue Occurrence', 'Low Impact Issue Occurrence']
    setArrheaderdata(datahearvals)

    const earlier_dataarrvals = [];
    const latest_dataarrvals = [];

    latest_dataarrvals[0] = datavalueChart?.data?.graph?.our_high_count;
    earlier_dataarrvals[0] = datavalueChart?.data?.graph?.comp_high_count;
    latest_dataarrvals[1] = datavalueChart?.data?.graph?.our_medium_count;
    earlier_dataarrvals[1] = datavalueChart?.data?.graph?.comp_medium_count;
    latest_dataarrvals[2] = datavalueChart?.data?.graph?.our_low_count;
    earlier_dataarrvals[2] = datavalueChart?.data?.graph?.comp_low_count;




    if (CompetitorDomainId) {
      let compDomains = esodata?.competitordomainids?.value;
      const selectedCompDomainName = compDomains?.find(d => d?.value == CompetitorDomainId);
      setCompDomainName(selectedCompDomainName?.label);
    }

    setLatestdataarr(latest_dataarrvals)
    setEarlierdataarr(earlier_dataarrvals)

    var severity_dataarrvals = [];

    severity_dataarrvals[0] = datavalueChart?.data?.graph?.our_high_serverity_graph;
    severity_dataarrvals[1] = datavalueChart?.data?.graph?.comp_high_serverity_graph;
    severity_dataarrvals[2] = datavalueChart?.data?.graph?.our_medium_severity_graph;
    severity_dataarrvals[3] = datavalueChart?.data?.graph?.comp_medium_severity_graph;
    severity_dataarrvals[4] = datavalueChart?.data?.graph?.our_low_severity_graph;
    severity_dataarrvals[5] = datavalueChart?.data?.graph?.comp_low_severity_graph;
    severity_dataarrvals[6] = datavalueChart?.data?.domain_name;
    severity_dataarrvals[7] = datavalueChart?.data?.comp_domain_name;
    severity_dataarrvals[8] = (moment(datavalueChart?.data?.job_date).format("MMM DD,YYYY "));
    severity_dataarrvals[9] = (moment(datavalueChart?.data?.comp_job_date).format("MMM DD,YYYY "));
    setServeritydata(severity_dataarrvals)

    setLoading(false)


  };
  const barChartData = {
    labels: seoindexData,
    datasets: [
      {
        label: 'Competitor Domain',
        data: seooccurData,
        backgroundColor: ["#103658"],
      },
      {
        label: 'Our Domain',
        data: latestoccurData,
        backgroundColor: ["#fe874e"],
      },

    ],
  };
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};

    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  // competitor test filter

  const dispatch = useDispatch();



  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value


  const [checkstatus, setcheckstatus] = useState(0)



  const [CompetitorJobId, SetCompetitorJobId] = useState('')
  const [CompetitorDomainId, SetCompetitorDomainId] = useState({
    label:
      '',
    value:
      '',
  })
  const [JobId, SetJobId] = useState('')


  const CjdomainvaldataChange = (value) => {
    SetCompetitorJobId(value[0].value)

  }

  const CddomainvaldataChange = (value) => {
    console.log('CddomainvaldataChange value', value, CompetitorDomainId)
    var errs = 1;
    let errors = {};
    // SetCompetitorDomainId(value[0])
    SetCompetitorDomainId({
      label: value.label,
      value: value.value
    })
    if (value.value) {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }

  }
  const JdomainvaldataChange = (value) => {
    SetJobId(value[0].value)

  }




  const CloseModalTrigger = () => {

    setValidation({ ...validation, dateerror: '' })
    // SetCompetitorJobId('')
    // SetCompetitorDomainId('')
    // SetJobId('')
    SetdEngineoption(prevdEngineoption);
    props.setIsOpen(false)
  }

  const FetchCompetitorList = () => {



    var errs = 1;
    let errors = {};

    console.log("CompetitorJobId", CompetitorJobId, "CompetitorDomainId", CompetitorDomainId, "JobId", JobId);

    if (CompetitorDomainId.value === '' || JobId === '') {
      errors.dateerror = "All fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }


    if (CompetitorDomainId.value !== '' && dEngineoption.id !== '') {
      callBeforeuserdataapi()
      props.SetCompetitorDomainId(CompetitorDomainId.value);
      props.setJobIdvalue(dEngineoption.id)

      if (checkstatus === 4) {
        callUserdataApi()
        props.setIsOpen(false)
        setprevdEngineoption(dEngineoption);
        // SetCompetitorJobId('')
        // SetCompetitorDomainId('')
        // SetJobId('')
        setValidation({ ...validation, dateerror: '' })
      }

    }
  }

  // competitor test filter End

  //competitor chart analysis  start

  const [chartvalue, setChartvalue] = useState("4");
  const handlechartChange = (event, newValue) => {
    setChartvalue(newValue);
  };

  const barChartData1 = {
    labels: arrheaderdata,
    datasets: [
      {
        label: 'Competitor Domain',
        data: earlierdataarr,
        backgroundColor: ["#103658"],
      },
      {
        label: 'Our Domain',
        data: latestdataarr,
        backgroundColor: ["#fe874e"],
      },

    ],
  };


  const callBeforeuserdataapi = async () => {
    let aa = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/competitor_analysis_status/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_domain_id=${CompetitorDomainId.value}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

    let changedStatus = aa?.data?.status;
    if(aa?.data?.status === 4) {
      if(props.compId1 && props.compId2 && props.compId3) {
        callUserdataApi(true);
      }
    }
    setcheckstatus(changedStatus);
  }


  useEffect(() => {

    // console.log('esodata?.competitorinjobids?.value[0].value && esodata?.competitorjobids?.value[0]?.value && esodata?.competitordomainids?.value[0]?.value', 
    //   esodata?.competitorinjobids?.value[0].value && esodata?.competitorjobids?.value[0]?.value && esodata?.competitordomainids?.value[0]?.value
    // )
    console.log('comp props', props.compId1, props.compId2, props.compId3);

      callBeforeuserdataapi()


    if (checkstatus === 4) {

      if (esodata?.competitorinjobids?.value[0] && esodata?.competitorjobids?.value[0] && esodata?.competitordomainids?.value[0]) {
        console.log('esodata?.competitorjobids?.value', esodata?.competitorjobids?.value);
        var CompetitorjobDate = [];
        esodata?.competitorjobids?.value.forEach((element) => {
          CompetitorjobDate.push({ label: datezonechange(element.label), value: element.value });
        });
        setcompetitorjobDate(CompetitorjobDate);


      }

      setCompDomainName(esodata?.competitordomainids?.value[0]?.label);
      const selectedDomainId = esodata?.domaindata?.value?.header_selected;
      if (selectedDomainId) {
        let ourDomains = esodata?.domainlist?.value;
        const selectedDomainName = ourDomains?.find(d => d?.id == selectedDomainId);
        setOurDomainName(selectedDomainName?.domain__name);
      }



    }

    LoadAddFormFilterOptions()


  }, [esodata.competitorinjobids, esodata.competitorjobids, esodata.competitordomainids])



  //competitor chart analysis  end

  const totalPages = Math.ceil(rows.length / rowsPerPage);
  const totalPages1 = Math.ceil(rows1.length / rowsPerPage);
  const totalPages2 = Math.ceil(rows2.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to first page when rows per page changes
  };

  return (
    <>

      {/* {
      loading===true ?   <Loader/> : null
    } */}

      {checkstatus === 4 ?
        (<><div className="tabs_graph_outer_wrap">
          <TabContext value={chartvalue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handlechartChange} aria-label="lab API tabs example" >
                <Tab className="tabbutton" label="By Issue" value="4" />
                <Tab className="tabbutton" label="By Severity" value="5" />
              </TabList>
            </Box>
            <TabPanel value="4">
              <div className="site_regress_chart_info">
                {/* <p>Site Audit Summary by Issues</p> */}
                <div className="site_Chart_info_inner">
                  <Bar
                    type="bar"
                    width={150}
                    height={isMobile ? 180 : 80}
                    data={barChartData}
                    options={{
                      responsive: true,
                      plugins: {

                        // 'legend' now within object 'plugins {}'
                        legend: {
                          position: 'top',
                          display: true,
                          labels: {
                            color: '#0b2033',
                            font: {
                              size: 15,
                              family: 'Poppins',
                            }
                          }

                        },
                        title: {
                          display: true,
                          text: 'Competitor Analysis Summary By Issue',
                          color: '#0b2033',
                          font: {
                            size: 18,
                            family: 'Poppins',
                          }

                        },
                      },
                      scales: {
                        y: {
                          // not 'yAxes: [{' anymore (not an array anymore)
                          ticks: {
                            suggestedMin: 0,
                            stacked: true,
                            color: "#0b2033", // not 'fontColor:' anymore
                            // fontSize: 18,
                            font: {
                              size: 14, // 'size' now within object 'font {}'
                              family: 'Poppins',
                            },
                            beginAtZero: true,
                          },
                        },
                        x: {
                          // not 'xAxes: [{' anymore (not an array anymore)
                          ticks: {
                            color: "#0b2033", // not 'fontColor:' anymore
                            //fontSize: 14,
                            font: {
                              size: 12, // 'size' now within object 'font {}'
                              family: 'Poppins',
                            },
                            beginAtZero: true,
                          },
                        },
                      },
                    }}
                  />
                </div>

              </div>
            </TabPanel>
            <TabPanel value="5">
              <div className="site_regress_chart_info">
                {/* <p>Site Audit Summary by Issues</p> */}
                <div className="site_Chart_info_inner">
                  <Bar
                    type="bar"
                    width={130}
                    height={isMobile ? 150 : 80}
                    data={barChartData1}
                    options={{
                      responsive: true,
                      plugins: {

                        // 'legend' now within object 'plugins {}'
                        legend: {
                          position: 'top',
                          display: true,
                          labels: {
                            color: '#103658',
                            font: {
                              size: 15,
                              family: 'Poppins',
                            }
                          }

                        },
                        title: {
                          display: true,
                          text: 'Competitor Analysis Summary By Severity',
                          color: '#0b2033',
                          font: {
                            size: 18,
                            family: 'Poppins',
                          }

                        },
                      },
                      scales: {
                        y: {
                          // not 'yAxes: [{' anymore (not an array anymore)
                          ticks: {
                            suggestedMin: 0,
                            stacked: true,
                            color: "#0b2033", // not 'fontColor:' anymore
                            // fontSize: 18,
                            font: {
                              size: 14, // 'size' now within object 'font {}'
                              family: 'Poppins',
                            },
                            beginAtZero: true,

                          },
                        },
                        x: {
                          // not 'xAxes: [{' anymore (not an array anymore)
                          ticks: {
                            color: "#0b2033", // not 'fontColor:' anymore
                            //fontSize: 14,
                            font: {
                              size: 12, // 'size' now within object 'font {}'
                              family: 'Poppins',
                            },
                            beginAtZero: true,
                          },
                        },
                      },
                    }}
                  />
                </div>

                <table>
                  <tr className="table_regression">
                    <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[0]}%</td>
                    <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[2]}%</td>
                    <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]})- {serveritydata[4]}%</td>
                  </tr>
                  <tr className="table_regression">
                    <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[1]}%</td>
                    <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[3]}%</td>
                    <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[5]}%</td>
                  </tr>
                </table>

              </div>
            </TabPanel>
          </TabContext>
        </div>

          <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="SEO Metrics" value="1" />
                  <Tab label="Site Metrics" value="2" />
                  <Tab label="Internal Links" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
                  <div style={{ height: rows.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                    <DataGrid

                      autoHeight={autoheightval ? true : false}
                      getRowHeight={() => 'auto'}
                      getRowId={(row) => row.seo_metrics}
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        noResultsOverlay: MyCustomNoRowsOverlay,
                        footer: () => (
                          <Box className="table-footer-container">
                            <Box className="table-footer-pagination">
                              <Pagination className="table-pagination" count={totalPages} page={page} onChange={handlePageChange} />
                            </Box>
                            <MSelect
                              value={rowsPerPage}
                              onChange={handleRowsPerPageChange}
                              size="small"
                              className="table-footer-select"
                            >
                              <MenuItem value={10}>10 per page</MenuItem>
                              <MenuItem value={30}>30 per page</MenuItem>
                              <MenuItem value={50}>50 per page</MenuItem>
                              <MenuItem value={75}>75 per page</MenuItem>
                              <MenuItem value={100}>100 per page</MenuItem>
                            </MSelect>
                          </Box>
                        ),
                      }}
                      slotProps={{
                        toolbar: {

                          showQuickFilter: true,

                        },
                      }}
                      rows={rows.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                      columns={columns}
                      pagination
                      // pageSizeOptions={[5, 10, 20]}
                      disableVirtualization
                      // initialState={{
                      //   pagination: {
                      //     paginationModel: {
                      //       pageSize: 10,
                      //     },
                      //   },
                      // }}
                      // pageSizeOptions={[10, 30, 50, 75, 100]}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
                  <div style={{ height: rows1.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                    <DataGrid

                      autoHeight
                      getRowHeight={() => 'auto'}
                      getRowId={(row) => row.site_metrics}
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        noResultsOverlay: MyCustomNoRowsOverlay,
                        footer: () => (
                          <Box className="table-footer-container">
                            <Box className="table-footer-pagination">
                              <Pagination className="table-pagination" count={totalPages1} page={page} onChange={handlePageChange} />
                            </Box>
                            <MSelect
                              value={rowsPerPage}
                              onChange={handleRowsPerPageChange}
                              size="small"
                              className="table-footer-select"
                            >
                              <MenuItem value={10}>10 per page</MenuItem>
                              <MenuItem value={30}>30 per page</MenuItem>
                              <MenuItem value={50}>50 per page</MenuItem>
                              <MenuItem value={75}>75 per page</MenuItem>
                              <MenuItem value={100}>100 per page</MenuItem>
                            </MSelect>
                          </Box>
                        ),
                      }}
                      slotProps={{
                        toolbar: {

                          showQuickFilter: true,

                        },
                      }}
                      rows={rows1.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                      columns={column1}
                      pagination
                      // pageSizeOptions={[5, 10, 20]}
                      disableVirtualization
                      // initialState={{
                      //   pagination: {
                      //     paginationModel: {
                      //       pageSize: 5,
                      //     },
                      //   },
                      // }}
                      // pageSizeOptions={[5, 10, 25]}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                <div className="data_table internal_source_table internal_outsource_table" style={{ width: "100%" }}>
                  <div style={{ height: rows2.length > 5 ? 550 : 'unset', width: "100%" }} className="over_all_accessibility analysissummary_table">
                    <DataGrid

                      autoHeight
                      getRowHeight={() => 'auto'}
                      getRowId={(row) => row.internal_links}
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        noResultsOverlay: MyCustomNoRowsOverlay,
                        footer: () => (
                          <Box className="table-footer-container">
                            <Box className="table-footer-pagination">
                              <Pagination className="table-pagination" count={totalPages2} page={page} onChange={handlePageChange} />
                            </Box>
                            <MSelect
                              value={rowsPerPage}
                              onChange={handleRowsPerPageChange}
                              size="small"
                              className="table-footer-select"
                            >
                              <MenuItem value={10}>10 per page</MenuItem>
                              <MenuItem value={30}>30 per page</MenuItem>
                              <MenuItem value={50}>50 per page</MenuItem>
                              <MenuItem value={75}>75 per page</MenuItem>
                              <MenuItem value={100}>100 per page</MenuItem>
                            </MSelect>
                          </Box>
                        ),
                      }}
                      slotProps={{
                        toolbar: {

                          showQuickFilter: true,

                        },
                      }}
                      rows={rows2.slice((page - 1) * rowsPerPage, page * rowsPerPage)}
                      columns={column2}
                      pagination
                      // pageSizeOptions={[5, 10, 20]}
                      disableVirtualization
                      // initialState={{
                      //   pagination: {
                      //     paginationModel: {
                      //       pageSize: 5,
                      //     },
                      //   },
                      // }}
                      // pageSizeOptions={[5, 10, 25]}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                    />
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </div>

        </>) : checkstatus === 1 ? (
          <div className="error_gwt_notice_Outer" style={{ marginTop: '35px' }}>
            <div className="error_gwt_notice">
              <div className="row">
                {/* <div className="icon">
          <InfoIcon/>
          </div> */}
                <div className="msg">
                  Note: Please ensure that competitor details are added accurately. For clarification, follow these instructions to add a competitor:
                </div>
              </div>

            </div>

            <div className="error_gwt">
              <main>
                <ul class="infoGraphic">
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor1">1</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><SettingsIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 1</h2>
                      <p className="heading_p">Go to Settings.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor2">2</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><BuildCircleIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 2</h2>
                      <p className="heading_p">Select Competitor Settings.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor3">3</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><AutoFixHighOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 3</h2>
                      <p className="heading_p">Click on the "Add Competitor" button.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor4">4</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><ManageHistoryIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 4</h2>
                      <p className="heading_p">Enter the competitor's name and URL, then click "Save".</p>
                    </div>
                  </li>
                </ul>
              </main>
            </div>
          </div>
        ) : checkstatus === 2 ? (
          <div className="error_gwt_notice_Outer" style={{ marginTop: '35px' }}>
            <div className="error_gwt_notice">
              <div className="row">
                {/* <div className="icon">
          <InfoIcon/>
          </div> */}
                <div className="msg">
                  Note: Please ensure the following instructions are followed to check the Site Audit Reports.
                </div>
              </div>

            </div>

            <div className="error_gwt">
              <main>
                <ul class="infoGraphic">
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor1">1</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><SettingsIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 1</h2>
                      <p className="heading_p">Go to the Technical</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor2">2</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><BuildCircleIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 2</h2>
                      <p className="heading_p">Select Site Analysis &gt; Competitor Analysis.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor3">3</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><AutoFixHighOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 3</h2>
                      <p className="heading_p">Click on "Run Report."</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor4">4</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><ManageHistoryIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 4</h2>
                      <p className="heading_p">Choose the method</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor5">5</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><TuneIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Soft Run</h2>
                      <p className="heading_p">Generates a quick report for known competitors.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">6</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><WidgetsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Hard Run</h2>
                      <p className="heading_p">Runs a complete check for all competitors.</p>
                    </div>
                  </li>

                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">7</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><DisplaySettingsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 5</h2>
                      <p className="heading_p">Click Submit.</p>
                    </div>
                  </li>
                </ul>

              </main>


            </div>
          </div>
        ) : checkstatus === 3 ? (
          <div className="error_gwt_notice_Outer" style={{ marginTop: '35px' }}>
            <div className="error_gwt_notice">
              <div className="row">
                {/* <div className="icon">
          <InfoIcon/>
          </div> */}
                <div className="msg">
                  Note: Please ensure the following instructions are followed to check the Competitor Analysis Reports.
                </div>
              </div>

            </div>

            <div className="error_gwt">
              <main>
                <ul class="infoGraphic">
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor1">1</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><SettingsIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 1</h2>
                      <p className="heading_p">Go to the Technical</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number fontColor2">2</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><BuildCircleIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 2</h2>
                      <p className="heading_p">Select Site Analysis &gt; Competitor Analysis.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor3">3</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><AutoFixHighOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 3</h2>
                      <p className="heading_p">Click on "Run Report."</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor4">4</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><ManageHistoryIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 4</h2>
                      <p className="heading_p">Choose the method</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor5">5</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><TuneIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Soft Run</h2>
                      <p className="heading_p">Generates a quick report for known competitors.</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">6</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><WidgetsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Hard Run</h2>
                      <p className="heading_p">Runs a complete check for all competitors.</p>
                    </div>
                  </li>

                  <li>
                    <div class="numberWrap">
                      <div class="number  fontColor6">7</div>
                      <div class="coverWrap">
                        <div class="numberCover"></div>
                      </div>
                    </div>
                    <div class="content">
                      <div class="icon"><DisplaySettingsOutlinedIcon style={{ color: '#fe874e', fontSize: '40px' }} /></div>
                      <h2>Step 5</h2>
                      <p className="heading_p">Click Submit.</p>
                    </div>
                  </li>
                </ul>

              </main>


            </div>
          </div>
        ) : ""}

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle className="keyword-dialog-header">{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              <div>

                <div className="">
                  <div>
                    <span>Completed Date</span>
                    {/* <Select
                    options={esodata.competitorinjobids.value}
                    onChange={JdomainvaldataChange}
                  /> */}
                    <Select
                      value={dEngineoption}
                      placeholder="Select Date"
                      options={completedate && completedate.length>0 ? completedate : []}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => {
                        SetJobId(values?.value)
                        SetdEngineoption(values);
                      }}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>


                {/* <div className="">
                  <div>
                    <span>Competitor Job Date</span>
                    <Select
                      placeholder="Select Date"
                      options={competitorjobDate}
                      onChange={CjdomainvaldataChange}
                    />
                  </div>


                </div> */}
                <div className="">
                  <div>
                    <span>Competitor Domain</span>
                    <Select
                      value={CompetitorDomainId}
                      placeholder="Select Domain"
                      options={esodata?.competitordomainids?.value.length>0 ? esodata?.competitordomainids?.value : []}
                      onChange={CddomainvaldataChange}
                      styles={customDropdownStyles()}
                      menuPortalTarget={document.body}
                    />
                  </div>


                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>


              </div>

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <button className="form_submit_btn" onClick={FetchCompetitorList} style={{ cursor: 'pointer' }} >
            Submit
          </button>
          <button style={{ cursor: 'pointer' }} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      {!openCommonAlerts && <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "18px !important",
            borderColor: "#ff601f !important",
            top: "10px !important",
            // width: '100%',
            // boxShadow:"0px 15px 10px 0px #fff !important"
          },
        }}

      >
        <DialogTitle className="keyword-dialog-header" sx={{ display: 'flex', justifyContent: 'center' }}><ReportProblemIcon fontSize="large" sx={{ color: '#ff601f' }} /></DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button" sx={{ justifyContent: 'center' }}>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>}
      {/* <CommonAlert setOpenCommonAlerts={setOpenCommonAlerts} /> */}
    </>
  );
}
