import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import Stepperformhead from "./Stepperformhead";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from "react-redux";
import { SetUserToken } from "../../../Store/UserTokenSlice";
import { SetAuthToken } from "../../../Store/ApiAuthKey";
import logoimage from '../Asset/New_Assert/ESO_Logo.png'
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";



function Googleanalytics() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [text, setText] = useState("");
  const [Googleid, SetGoogleid] = useState("");
  const [GoogleidError, SetGoogleidError] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const location = useLocation();

  const dispatch = useDispatch();


  const apihandleClose = () => {
    setapiOpen(false);
  };

  useEffect(() => {
    callUserdataApi();
  }, []);
  const ongoogleId = e => {
    SetGoogleid(e.target.value)
  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const handleCopyClick = () => {
    copy(text);
    toast.success('Successfully copied to clipboard', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  };
  const skipButtonclick = () => {
    navigate('/')
  }
  const nextButtonclick = async () => {
    var constval = 1;
    SetGoogleidError("")
    if (Googleid === "") {
      SetGoogleidError("Google ID is Required")
      constval = 2
    }
    if (Googleid !== "") {
      if (isNaN(Googleid) || Googleid.length > 10) {
        SetGoogleidError("Enter Valid Google ID")
        constval = 2
      }
    }
    if (constval != 2) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}googleanalytics/save_google_analytics_property_id/`,
        {
          google_analytics_propery_id: Googleid,
          user_domain_id: esodata.domaindata.value.header_selected,

        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (response?.data?.status_code == 200) {
        navigate("/")
      }
    }

  }

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const domain_owber = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/get_service_account_email/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setText(domain_owber.data.service_email_id)

    } catch (err) { }
    setLoading(false);
  };


  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {

        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };

  const backtoPage = () => {
    if (location.state?.referrer === "/Impadddomain") {
      navigate("/Impadddomain")
    } else {
      navigate("/Domainownership")
    }
  }



  return (
    <div className="userdata">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_hdr_top_rgt_wrap" style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px", background: "#fff", position: "fixed", zIndex: 999 }}>
        {/* Logo at the start */}
        <img
          className="Eso_logo"
          src={logoimage}
          alt="logo"
          style={{ width: "14%", background: "#fff" }}
        />

        {/* Group for the two buttons */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px", paddingRight: "20px" }}>
          <button
            onClick={handleToggle}
            className="faq_class"
            title="Frequently Asked Question"
            style={{ padding: "8px 13px 8px 7px" }}
          >
            <QuestionMarkIcon />
          </button>

          {/* FAQ Sidebar conditionally rendered */}
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

          <button onClick={TriggerLogout}>
            <span
              className="material-icons"
              title="Logout"
              style={{ marginLeft: '5px', padding: "10px 8px 10px 0px" }}
            >
              <LogoutIcon />
            </span>
          </button>
        </div>
      </div>

      <Stepperformhead setActiveStep={location.state?.referrer === "/Impadddomain" ? 2 : 4} setfirst={location.state?.referrer === "/Impadddomain" ? true : false} />
      <div className="outer-container"
        style={{
          width: "calc(100% - 60px)",
          marginRight: "60px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="userdata_inner">
          <h1>Google Analytics</h1>
          <div class="form-group domain_owner domain_ownertext">
            <label><b>1.</b> Use the following service account as a user in your analytics account</label>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px', width: '97%' }} >
              <input type="text" value={text} style={{ width: '100%', border: "1px solid #ff601f", paddingRight: "10px" }} onChange={(e) => setText(e.target.value)} disabled />
              <button className="copybutton" onClick={handleCopyClick}>
                Copy
                <span className="material-icons" style={{ marginLeft: '5px' }}>{<ContentCopyIcon />}</span>
              </button>
            </div>
          </div>
          {/* <div class="form-group domain_owner">
            <label>2) Provide Viewer access only</label>
          </div> */}
          <div class="form-group user_country websitenamess">
            <label><b>2.</b> Google ID:</label>
            <input type="text" name="websitename" style={{ width: "95%" }} onChange={ongoogleId} />
            <span className="error-value">{GoogleidError}</span>
          </div>
          <div style={{ display: 'flex', margin: '20px 50px', color: "#ff601f" }} >
            Note: Provide Viewer Access Only.
          </div>
          {/* <div className="form_next analytics_button">
            <button className="nextbutton" type="button" onClick={() => navigate("/Domainownership")}><FastRewindIcon/>Back</button>
            <button className="nextbutton" type="button" onClick={nextButtonclick}>Next</button>
            <button className="skip" type="button" onClick={skipButtonclick}>Skip</button>
          </div> */}

          <Box sx={{ mt: 3, display: "flex", justifyContent: "center", gap: "50px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={backtoPage}
              sx={{
                padding: "10px 10px",
                borderRadius: "10px",
                // fontWeight: "900",
                fontSize: "16px",
                backgroundColor: "#ff601f",
                color: "white",
                "&:hover": { backgroundColor: "#ff7d45" },
              }}
            >
              <FastRewindIcon />Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={nextButtonclick}
              sx={{
                padding: "10px 15px",
                borderRadius: "10px",
                // fontWeight: "900",
                fontSize: "16px",
                backgroundColor: "#ff601f",
                color: "white",
                "&:hover": { backgroundColor: "#ff7d45" },
              }}
            >
              Next<FastForwardIcon style={{ marginLeft: "-10px" }} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={skipButtonclick}
              sx={{
                padding: "10px 30px",
                borderRadius: "10px",
                // fontWeight: "900",
                fontSize: "16px",
                backgroundColor: "#ff601f",
                color: "white",
                "&:hover": { backgroundColor: "#ff7d45" },
              }}
            >
              Skip
            </Button>
          </Box>

        </div>
      </div>





      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="keyword-dialog-header">{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-action-button">
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}

export default Googleanalytics;


